var data
if (location.href.indexOf('wesite.itangyi.com') != -1) {//生产！！！
    data = {
        shareImgUrl: "https://wesite.itangyi.com/newsite/", //分享图片地址//生产
    }
} else {//测试！！！
    data = {
        shareImgUrl: "https://tangyiweb.roztop.com/", //分享图片地址//测试
    }
}
const state = {
    active: "index", //当前tab名称
    imgUrl: "https://cmevod.itangyi.com/vod/", //静态图片地址

    huayiwangJumpUrl:"https://itangyi.91huayi.com/",//华医网跳转域名
    huayiwangJumpUrlKC:"https://itangyijfdh.91huayi.com/",
    
    huayiwangOrderJumpUrl:"https://cdn.91huayi.com/",//华医网订单那跳转域名
    showBadge: false,
    ...data
}
// }

const mutations = {
    changeTabActive(state, active) {
        state.active = active
    },
    showBadgeFn(state, show) {
        state.showBadge = show;
    }
}

const actions = {

}

const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}