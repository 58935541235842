<!--
* @date 2023-08-04 14:52:07
* @version V1.0.0
!-->
<template>
  <div id='myCourse' class='myCourse'>
        <Header>
            <div class="header-content">
                <div class="header-title">我的课程</div>
                <div class="header-back">
                <div class="back" @click="handleTitleBack">
                    <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
                </div>
            </div>
        </Header>

        <van-pull-refresh class="content-list" v-model="refreshing" @refresh="onRefresh">
            <van-search class="my-search" v-model="searchVal" shape="round" show-action :clearable='false' placeholder="搜索......"
                :left-icon="require('@/static/home/search.png')">
                <template #action>
                    <div class="search-btn" @click="onSearch">搜索</div>
                </template>
            </van-search>

            <div class="content">
                <van-list class="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMoreData">
                    <div class="content-item" v-for="(item, index) in contentList" :key="index" :title="item.id"
                        @click="openDetails(item)">
                        <div class="left">
                            <img :src="item.courseImg" alt="">
                            <div class="status">课程</div>
                        </div>
                        <div class="right">
                            <div class="title_body">
                                <span class="title"><span v-if="item.newStatus == 'Y'" class="new">NEW</span>{{item.courseName}}</span>
                            </div>
                            <div class="tips">
                                <span class="tips_item" v-for="(item,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">药物治疗</span>
                            </div>
                            <div class="bottom2">
                                <div class="time">
                                    <!-- <img src="@/static/home/time.png" alt=""> -->
                                    <span class="name">{{item.lecturerName}} {{item.createTime.split(' ')[0]}}</span>
                                </div>
                                <div class="look" style="margin-left:20px">
                                    <img src="@/static/home/liulan.png" alt="">
                                    <span>{{item.touristVolume}}浏览</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
        </van-pull-refresh>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
import {myCourseList} from '@/request/api.js'
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'myCourse',
  data () {
    return {
        searchVal:'',//搜索

        contentList:[],

        pageNum: 1, //当前页
        pageSize: 5, //每页大小
        totalNum: 0, //总条数
        refreshing: false,
        loading:true,
        finished:false
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    //搜索
    onSearch() {
      // if (!this.searchVal) {
      //   this.$toast("请输入搜索关键词");
      //   return;
      // }
      this.pageNum = 1;
      this.finished = false;
      this.loading = true;
      this.getList()
    },

    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList();
    },
    loadMoreData(){
      if (this.refreshing) {return}
      this.getList();
    },

    getList(){
      var params = new Object()
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize
      params.courseName = this.searchVal
      myCourseList(http_post_params(params)).then(res=>{
        if(res.code == 0){
          if(res.code == 0){
            if(this.pageNum == 1){
                this.contentList = res.data
              } else {
                this.contentList = this.contentList.concat(res.data)
              }
              this.refreshing = false
              this.loading = false
              if(this.contentList.length >= res.total){
                this.finished = true
              } else {
                this.finished = false
              }
              this.pageNum += 1;
          }
        }
      })
    },

    //详情
    openDetails(item){
      this.$addLog({sourceType:'VIEW',viewId:item.contentId,viewType:'userCenter_course_detail'})
        this.$router.push({
            path:'/videoCourse?id=' + item.contentId + '&logType=content'
        })
    },
  },
  mounted () { // 页面进入时加载内容

  },
  watch: { // 监测变化

  },

  activated(){
    this.pageNum = 1
    this.finished = false;
    this.loading = true;
    this.searchVal = ''
    this.getList()
    this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_course_list'})
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.my-search {
  margin-top: 12px;
  width: 100%;
  padding: 10px 3%;
  position: relative;

  .van-search__content {
    background: #F6F5FB;
    height: 40px;
    border-radius: 8px;
  }

  /deep/.van-cell {
    display: flex;
    align-items: center;
    .van-cell__value{
      .van-field__control{
        width: 80%;
      }
    }
  }

  /deep/.van-field__left-icon {
    // height: 18px;
    padding-right: 6px;
    margin-right: 6px;
    border-right: 2px solid #e4e4e4;
  }

  .van-search__action {
    position: absolute;
    right: 3%;
    padding: 0;
    border-radius: 31px;
  }

  .search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 60px;
    background: #1C8BFF;
    border-radius: 30px;
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 10px;
  }
}

/deep/.van-pull-refresh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: unset; // 可选，这个视情况添加
  .van-pull-refresh__track {
    flex: 1;
  }
}

.content {
  width: 94%;
  margin: 10px auto;
}
.content-list {
  background: #ffffff;
  // padding: 30px 15px 150px;
  .content-item {
    display: flex;
    padding: 16px 0 16px 0;
    // border-bottom: 2px #E5E5E5 solid;
    .left{
      display: flex;
      position: relative;
      >img{
        width: 248px;
        height: 180px;
        border-radius: 16px;
        background: gainsboro;
      }
      .status{
        position: absolute;
        background: #BA1735;
        border-radius: 16px 0 16px 0;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 2px 10px;
        top: 0px;
        left: 0px;
        // transform: scale(0.8);
      }
    }
    .right{
      width: 64%;
      padding: 0 30px;
      .title_body{
        display: flex;
        vertical-align: baseline;
      }
      .title{
        font-size: 30px;
        font-weight: 500;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 48px;
        margin: 0;
        .new{
          background: linear-gradient(180deg, #F99068 0%, #F7312A 100%);
          font-size: 14px;
          font-weight: normal;
          color: #FFFFFF;
          padding: 5px 6px;
          // line-height: 20px;
          // transform: scale(0.7);
          margin-right: 10px;
          // margin-left: -6px;
          // margin-top: -15px;
          // width: fit-content;
          // display: inline-block;
          vertical-align: top;
        }
      }
      
      .tips{
        display: flex;
        flex-wrap: wrap;
        margin-top: 6px;
        min-height: 40px;
        .tips_item{
          background-color: #B2E0F7;
          font-size: 16px;
          font-weight: 500;
          color: #8D8D8D;
          border-radius: 6px;
          padding: 2px 8px;
          margin-right: 20px;
          margin-bottom: 10px;
          // transform: scale(0.8);
          height: fit-content;
        }
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .name{
          font-size: 24px;
          font-weight: 500;
          color: #A1A1A1;
        }
        .like{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: -20px;
          >img{
            width: 30px;
            height: auto;
          }
          .t{
            font-size: 12px;
            font-weight: 500;
            color: #A1A1A1;
            margin-top: 6px;
            // transform: scale(0.6);
          }
        }
      }
      .bottom2{
        display: flex;
        align-items: center;
        margin-top: 12px;
        .time{
          display: flex;
          align-items: center;
          >img{
            width: 26px;
            height: auto;
          }
          >span{
            font-size: 24px;
            font-weight: 400;
            color: #A1A1A1;
            margin-left: 6px;
            word-break: break-all;
          }
        }
        .look{
          display: flex;
          align-items: center;
          >img{
            width: 26px;
            height: auto;
          }
          >span{
            font-size: 24px;
            font-weight: 400;
            color: #A1A1A1;
            margin-left: 6px;
            word-break: keep-all;
          }
        }
      }
    }
  }
}
</style>