import { render, staticRenderFns } from "./instructions.vue?vue&type=template&id=ae34298e&scoped=true&"
import script from "./instructions.vue?vue&type=script&lang=js&"
export * from "./instructions.vue?vue&type=script&lang=js&"
import style0 from "./instructions.vue?vue&type=style&index=0&id=ae34298e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae34298e",
  null
  
)

export default component.exports