<!--
* @date 2023-08-04 16:27:23
* @version V1.0.0
!-->
<template>
  <div id='integralRule' class='integralRule'>
       <Header>
            <div class="header-content">
                <div class="header-title">积分规则</div>
                <div class="header-back">
                    <div class="back" @click="handleTitleBack">
                        <img class="returnIcon" src="@/static/return.png" alt="" />
                    </div>
                </div>
            </div>
        </Header>

        <div class="container">
            <div class="words" v-for="(item, idx) in ruleList" :key="idx + '^'">
                <span class="label"></span>
                <span class="words">{{ item.text }}</span>
                <p class="text" v-for="(i, j) in item.child" :key="j + '^'">{{
                i.text
                }}</p>
            </div>
        </div>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
export default {
  props: { // 父辈向子辈传参
  },
  name: 'integralRule',
  data () {
    return {
        ruleList: [
            {
                text: "首次登陆",
                child: [
                    { text: "+10积分" },
                ],
            },
            {
                text: "每日签到（累计7天一个周期）",
                child: [
                    { text: "（1）第1/2/3天登录+2积分" },
                    { text: "（2）第4/5/6天登录+5积分" },
                    { text: "（3）第7天登录+10积分" },
                ],
            },
            {
                text: "文章",
                child: [
                    { text: "阅读文章+2积分" },
                ],
            },
            {
                text: "视频",
                child: [
                    { text: "观看视频+5积分" },
                ],
            },
            {
                text: "课程",
                child: [
                    { text: "（1）学习课程+5积分" },
                    { text: "（2）课程播放至100%+10积分" },
                    { text: "（3）答题+2积分" },
                ],
            },
            { text: "每日首次使用工具积分+2积分" },
        ],
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.beforeUrl = from.path;
      });
    },
  },
  mounted () { // 页面进入时加载内容

  },
  watch: { // 监测变化

  }
}
</script>
<style scoped lang='less'>
.integralRule{
    overflow-y: scroll;
    height: 100vh;
    background: #F6F7FB;
}
.container {
  padding: 40px 50px;

  .label {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: linear-gradient(90deg, #1890ff, #00bbff);
    box-shadow: 3px 1px 5px 0px rgba(70, 70, 70, 0.05);
    border-radius: 50%;
    margin-right: 10px;
  }

  .words {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #828282;
    // line-height: 46px;
  }

  .text {
    margin-left: 40px;
  }
}
</style>