<!--
* @date 2023-07-31 18:06:03
* @version V1.0.0
!-->
<template>
  <div id='index' class='index'>
    <div class="mine">
      <img class="bg" src="@/static/mine/geren_bg.png" alt="">
      <div class="user" @click="userInfo">
        <img v-if="$store.state.login.wecharUser.avatar" class="header" :src="$store.state.login.wecharUser.avatar" alt="">
        <img v-else class="header" src="@/static/mine/def_header.png" alt="">
        <div class="right">
          <div class="info">
            <span>{{$store.state.login.siteUser.name}}</span>
            <img class="arrow" src="@/static/mine/arrow.png" alt="">
          </div>
          <span class="jf">{{point || 0}}积分</span>
        </div>
      </div>
    </div>
    <div class="tool">
      <div class="my_course">
        <div class="item" @click="action('course')">
          <img src="@/static/mine/kc.png" alt="">
          <span>我的课程</span>
        </div>
        <div class="item" style="border-left: 1px #D8D8D8 solid;" @click="action('collect')">
          <img src="@/static/mine/collect.png" alt="">
          <span>我的收藏</span>
        </div>
      </div>
      <div class="meeting">
        <span class="title">我的会议</span>
        <div class="bottom">
          <div class="item" @click="action('meeting_wks')">
            <img src="@/static/mine/wks.png" alt="">
            <span>未开始</span>
          </div>
          <div class="item" @click="action('meeting_hf')">
            <img src="@/static/mine/hf.png" alt="">
            <span>看回放</span>
          </div>
        </div>
      </div>
      <div class="gjx">
        <span class="title">工具箱</span>
        <div class="bottom">
          <div class="item" @click="action('sign')">
            <img src="@/static/mine/qiandao.png" alt="">
            <span>签到</span>
          </div>
          <div class="item" @click="action('myPoints')">
            <img src="@/static/mine/jifen.png" alt="">
            <span>我的积分</span>
          </div>
          <div class="item" @click="action('integralMall')">
            <img src="@/static/mine/shangcheng.png" alt="">
            <span>积分商城</span>
          </div>
        </div>
      </div>
      <div class="set" @click="action('set')">
        <span class="title">设置</span>
        <img class="arrow" src="@/static/mine/arrow.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {getUser} from '@/request/api'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'index',
  data () {
    return {
      point:0,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    userInfo(){
      this.$router.push({path:'/userInfo'})
    },

    action(value){
      if(value == 'course'){
        this.$router.push({path:'/myCourse'})
      } 
      if(value == 'collect'){
        this.$router.push({path:'/myCollect'})
      }
      if(value == 'meeting_wks'){
        this.$router.push({path:'/myMeeting?type=wks'})
      }
      if(value == 'meeting_hf'){
        this.$router.push({path:'/myMeeting?type=hf'})
      }
      if(value == 'myPoints'){
        this.$router.push({path:'/myPoints'})
      }
      if(value == 'sign'){
        this.$router.push({path:'/sign'})
      }
      if(value == 'integralMall'){
        this.$router.push({path:'/integralMall'})
      }

      if(value == 'set'){
        this.$router.push({path:'/set'})
      }
    },
  },
  mounted () { // 页面进入时加载内容
    
  },
  watch: { // 监测变化

  },

  activated(){
    getUser().then(res=>{
      if(res.code== 0){
        this.point = res.data.point
        window.sessionStorage.setItem("siteUser", JSON.stringify(res.data));
        this.$store.commit("setUserInfo", res.data);
      }
    })
  },
}
</script>
<style scoped lang='less'>
.index{
  background: #F6F7FB;
  overflow-y: scroll;
  height: 100vh;
}
.mine{
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
  .bg{
    width: 100%;
    height: auto;
  }
  .user{
    position: absolute;
    display: flex;
    align-items: center;
    margin-top: 96px;
    width: 100%;
    .header{
      width: 108px;
      height: 108px;
      border-radius: 50%;
      margin-left: 52px;
    }
    .right{
      margin-left: 28px;
      margin-right: 52px;
      display: flex;
      flex-direction: column;
      width: 68%;
      .info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 38px;
        font-weight: 600;
        color: #FFFFFF;
        .arrow{
          width: 48px;
          height: auto;
        }
      }
      .jf{
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
.tool{
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 32px 30px 32px;
  margin-top: -90px;
  z-index: 2;
}
.my_course{
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.1);
  border-radius: 16px;
  .item{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    width: 50%;
    >img{
      width: 68px;
      height: auto;
    }
    >span{
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      margin-left: 22px;
    }
  }
}
.meeting{
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.1);
  margin-top: 40px;
  padding: 24px 34px;
  .title{
    font-size: 32px;
    font-weight: 600;
    color: #000000;
  }
  .bottom{
    display: flex;
    align-items: center;
    margin-top: 26px;
    justify-content: space-between;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, #F9F9F9 0%, #F6F6F6 100%);
      border-radius: 16px;
      padding: 14px 0;
      width: 48%;
      >img{
        width: 68px;
        height: auto;
      }
      >span{
        font-size: 28px;
        font-weight: 400;
        color: #666666;
        margin-left: 20px;
      }
    }
  }
}
.gjx{
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.1);
  margin-top: 40px;
  padding: 24px 34px;
  .title{
    font-size: 32px;
    font-weight: 600;
    color: #000000;
  }
  .bottom{
    display: flex;
    align-items: center;
    margin-top: 26px;
    justify-content: space-between;
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      padding: 14px 0;
      width: 33.33%;
      >img{
        width: 42px;
        height: auto;
      }
      >span{
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        margin-top: 6px;
      }
    }
  }
}

.set{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.1);
  margin-top: 40px;
  padding: 24px 34px;
  .title{
    font-size: 32px;
    font-weight: 500;
    color: #000000;
  }
  .arrow{
      width: 48px;
      height: auto;
    }
}
</style>