<!--
* @date 2023-08-04 10:10:34
* @version V1.0.0
!-->
<template>
  <div id='userInfo' class='userInfo'>
        <Header>
            <div class="header-content">
                <div class="header-title">身份信息</div>
                <div class="header-back">
                <div class="back" @click="handleTitleBack">
                    <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
                </div>
            </div>
        </Header>

        <div class="content">
          <div class="top">
              <img v-if="$store.state.login.wecharUser.avatar" class="header" :src="$store.state.login.wecharUser.avatar" alt="">
              <img v-else class="header" src="@/static/mine/def_header.png" alt="">
              <div class="right">
                  <div class="name" @click="infoEdit('name')">
                      <span>{{user.name}}</span>
                      <!-- <img src="@/static/mine/edit.png" alt=""> -->
                  </div>
                  <div class="name" style="margin-top:6px" @click="infoEdit('phone')">
                      <span class="phone">{{user.phone}}</span>
                      <!-- <img src="@/static/mine/edit.png" alt=""> -->
                  </div>
              </div>
          </div>

          <div class="body">
            <span class="t">请选择您要修改的信息</span>
            <div class="form-group">
              <div class="form-item" :class="focus == 'name' ? 'click-item' : ''">
                  <span class="title">真实姓名</span>
                  <div class="inputBody">
                      <input @click.stop="getFocusFun('name')" type="text" v-model="name" placeholder="请输入真实姓名" />
                  </div>
              </div>
              <div v-show="logonType == 'doctor'" class="form-item" :class="focus == 'employer' ? 'click-item' : ''">
                  <span class="title">医院</span>
                  <div class="inputBody">
                      <input @focus="onEmployer = true" @keyup="employer = employer.replace(/[, ]/g, '')"
                      @blur="onblurFun('onEmployer')" @input="getSuggest('employer')" @click.stop="getFocusFun('employer')"
                      type="text" v-model="employer" placeholder="请填写医院名称" />
                      <ul class="inputTips" v-show="onEmployer && employerList.length !== 0">
                      <li v-for="(item, index) in employerList" :key="index + 'emp'" @click="SuggestItemClick('employer', item)">
                          {{ item.hosAlias }}</li>
                      </ul>
                  </div>
              </div>
              <div v-show="logonType == 'doctor'" class="form-item" :class="focus == 'administrative' ? 'click-item' : ''">
                  <span class="title">科室</span>
                  <div class="inputBody">
                      <input @focus="onAdministrative = true" @keyup="employer = employer.replace(/[, ]/g, '')"
                      @blur="onblurFun('onAdministrative')" @input="getSuggest('administrative')" @click.stop="getFocusFun('administrative')"
                      type="text" v-model="administrative" placeholder="请填写科室" />
                      <ul class="inputTips" v-show="onAdministrative && administrativeList.length !== 0">
                      <li v-for="(item, index) in administrativeList" :key="index + 'emp'" @click="SuggestItemClick('administrative', item)">
                          {{ item.name }}</li>
                      </ul>
                  </div>
              </div>
              <div v-show="logonType == 'doctor'" class="form-item" :class="focus == 'jobTitle' ? 'click-item' : ''">
                  <span class="title">职称</span>
                  <div class="inputBody">
                      <input @focus="onJobTitle = true" @keyup="employer = employer.replace(/[, ]/g, '')"
                      @blur="onblurFun('onJobTitle')" @input="getSuggest('jobTitle')" @click.stop="getFocusFun('jobTitle')"
                      type="text" v-model="jobTitle" placeholder="请填写职称" />
                      <ul class="inputTips" v-show="onJobTitle && jobTitleList.length !== 0">
                      <li v-for="(item, index) in jobTitleList" :key="index + 'emp'" @click="SuggestItemClick('jobTitle', item)">
                          {{ item.name }}</li>
                      </ul>
                  </div>
              </div>
              <div class="form-item flex-row-b" :class="focus == 'userPhone' ? 'click-item' : ''">
                  <span class="title">联系方式</span>
                  <div class="inputBody">
                      <input @click.stop="getFocusFun('userPhone')" type="text" minlength="11" maxlength="11" v-model="userPhone"
                      placeholder="请输入手机号" />
                  </div>
              </div>
              <div class="form-item flex-row-b" :class="focus == 'smsCode' ? 'click-item' : ''">
                  <span class="title">验证码</span>
                  <input @click.stop="getFocusFun('smsCode')" type="text" v-model="smsCode" placeholder="请输入验证码" minlength="6"
                      maxlength="6" oninput="if(value.length>6) value = value.slice(0,6)" />
                  <span class="codeBtn flex-c" :class="{ 'ableCodeBtn': codeText == '获取', 'phaBut': logonType != 'doctor' }"
                      @click="getECode">{{
                      codeText
                      }}</span>
              </div>
            </div>
          </div>

          <div class="submit" v-if="!status" @click="saveInfo">提交修改申请</div>
          <div class="tips">
            <span v-if="status" class="sh">修改申请已提交</span>
            <span v-if="status" class="bottom_text">我们将在5个工作日内完成您个人信息的修改</span>
          </div>
          <!-- <span class="bottom_text">我们将在5个工作日内完成您个人信息的修改</span> -->
            
        </div>

        <van-popup class="activity-popup" v-model="showDialog">
          <div id="completeInformation" class="completeInformation">
            <div class="content">
              <div class="top">
                <span>{{ editType == 'name' ? '修改真实姓名' : '修改手机号' }}</span>
                <img src="@/static/mine/guan.png" @click="showDialog = false" alt="">
              </div>

              <div class="form-group flex-col-a">
                <div class="form-item flex-row-b">
                  <div class="inputBody">
                    <input type="text" v-show="editType == 'name'" v-model="dataForm.name" placeholder="请输入用户名" />
                    <input type="text" v-show="editType == 'phone'" v-model="dataForm.phone" placeholder="请输入手机号" />
                  </div>
                </div>
              </div>


              <div class="button">
                <button style="background: linear-gradient(270deg, #c1c9d8 0%, #cbd4e6 100%)" @click="showDialog = false">
                  取消
                </button>
                <button @click="onSubmit()">修改</button>
              </div>
            </div>
          </div>
        </van-popup>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
import { thorttle,checkMobile,http_post_params} from '@/utils/index.js'
import {
  getUser,
  getHospital,
  getDepartment,
  getOccupation,
  sendCode,
  updateUser
} from '@/request/api'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'userInfo',
  data () {
    return {
        focus: "",
        name: "", //真实姓名
        employer: "", //医院名称
        employeeId: "", //医院id
        administrative:'',//科室
        jobTitle: '',//职位名称
        userPhone: "", //联系方式
        smsCode: "", //验证码

        codeText: "获取",
        timer: null,

        logonType: 'doctor',//注册类型

        employerList: [],//医院列表
        administrativeList:[],//科室列表
        jobTitleList:[],//职称列表

        onEmployer: false,//医院名称焦点
        onAdministrative:false,//科室焦点
        onJobTitle:false,//职称焦点


        dataForm: {
          name: '',
          phone:''
        },//所有修改信息
        showDialog: false,
        editType: '',//编辑类型

        user:{},
        status:false,//是否为审核状态
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    infoEdit(type) {
      return
      this.editType = type
      this.showDialog = true
    },

    getFocusFun(item) {
      this.focus = item;
    },
    onblurFun(type) {
      var then = this
      setTimeout(() => {
        then[type] = false
      }, 10);

    },
    getSuggest: thorttle(function (type) {
      this[type + 'Id'] = ''
      if (!this[type]) return;
      switch (type) {
        case 'employer':
          getHospital(`content=${this.employer}`).then(res => {
            this.employerList = res.data
          })
          break;
        case 'administrative':
            getDepartment(`content=${this.administrative}`).then(res => {
              this.administrativeList = res.data
            })
          break;
        case 'jobTitle':
          getOccupation(`content=${this.jobTitle}`).then(res => {
            this.jobTitleList = res.data
          })
          break;
      }
    }, 500),

    SuggestItemClick(type, item) {
      if (type == 'employer') {
        this[type] = item.hosName
        this.employeeId = item.id
      } else {
        this[type] = item.name
      }

      this[type + 'Id'] = item.id
      console.log(type)
      if (type == 'linkage') {
        autoQueryPhaPartnerStore(`partnerId=${this.linkageId}&keywords=${this.shop}`).then(res => {
          this.shopList = res.data
          this.shopList.push({ name: '其他' })
        })
      }
    },

    getECode() {
      if (this.userPhone == "") {
        this.$toast("请输入手机号码");
        return;
      }
      if (!checkMobile(this.userPhone)) {
        this.$toast("您输入的手机号码格式不正确，请您重新输入");
        return;
      }
      let p = {
        userPhone: this.userPhone,
      };
      sendCode(http_post_params(p)).then((res) => {
        if (res.code == 0) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.$toast("验证码已发送");
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.codeText = this.count + "s后重发";
              } else {
                this.codeText = "获取";
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },

    saveInfo() {
      
      if (!this.name || !this.userPhone ) {
        this.$toast("所有信息需全部填写，请完善信息");
        return;
      }
      if (this.logonType == 'doctor' && (!this.employer || !this.administrative || !this.jobTitle)) {
        this.$toast("所有信息需全部填写，请完善信息");
        return;
      }
      if (this.userPhone.length != 11) {
        this.$toast("您输入的手机号码格式不正确，请您重新输入");
        return;
      }
      if (!checkMobile(this.userPhone)) {
        this.$toast("您输入的手机号码格式不正确，请您重新输入");
        return;
      }
      if (this.smsCode.length != 6) {
        this.$toast("请输入6位数字验证码");
        return;
      }

      this.$toast.loading({
        duration: 0,
        message: '修改中',
        forbidClick: true,
      });
      
      var param = {
        employer: this.employer,
        employeeId:this.employeeId,
        name: this.name,
        phone: this.userPhone,
        smsCode: this.smsCode,
        department:this.administrative,
        occupation:this.jobTitle,
      };

      
      updateUser(http_post_params(param),param).then((res) => {
        this.$toast.clear()
        if (res.code == 0) {
          this.$toast('提交成功，等待后台审核')
          this.getUserData()
        } else {
          this.$toast(res.message);
          this.getUserData()
        }
      }).catch(err=>{
        this.$toast.clear()
      })
    },


    //获取信息
    getUserData(){
      getUser().then(res=>{
        if(res.code== 0){
          this.user = res.data
          this.name = this.user.name
          this.employer = this.user.employer
          this.employeeId = this.user.employeeId
          this.administrative = this.user.department
          this.jobTitle = this.user.occupation
          this.userPhone = this.user.phone
          window.sessionStorage.setItem("siteUser", JSON.stringify(res.data));
          this.$store.commit("setUserInfo", res.data);
          this.status = res.data.userChangeStatus == 'W' ? true : false
        }
      })
    },
  },
  mounted () { // 页面进入时加载内容
    this.getUserData()
    this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_update'})
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.userInfo{
    overflow-y: scroll;
    background: #F6F6F8;
    height: 100vh;
}
.content{
  display: flex;
  flex-direction: column;
  padding: 0 32px 62px 32px;
    .top{
        display: flex;
        align-items: center;
        margin-top: 70px;
        .header{
            width: 140px;
            height: 140px;
            border-radius: 50%;
        }
        .right{
            display: flex;
            flex-direction: column;
            margin-left: 34px;
            .name{
                display: flex;
                align-items: center;
                >span{
                    font-size: 35px;
                    font-weight: 500;
                    color: #000000;
                }
                >img{
                    margin-left: 20px;
                    width: 32px;
                    height: auto;
                }
                .phone{
                  font-size: 32px;
                }
            }
        }
    }
    .body{
      background: #FFFFFF;
      border-radius: 16px;
      margin-top: 46px;
      padding: 30px 34px;
      .t{
        font-size: 35px;
        font-weight: 500;
        color: #000000;
      }
      .form-group{
        display: flex;
        flex-direction: column;
        // margin-top: 60px;
        // background: #ffffff;
        // padding: 0px 60px;
        .form-item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 44px;
            position: relative;
            border-bottom: 2px #E5E5E5 solid;
            padding-bottom: 18px;
            .title{
                font-size: 32px;
                font-weight: 500;
                color: #000000;
                margin-right: 58px;
                text-align-last: justify;
                flex: auto;
                max-width: 135px;
                min-width: 135px;
            }

            /deep/ input {
                border: 0;
                border-radius: 0;
                width: 100%;
                font-size: 28px;
                color: #303030;
                line-height: 42px;
                flex: auto;
                background: none;
            }

            .inputBody {
                flex: auto;
                position: relative;
            }

            .inputTips {
                position: absolute;
                top: 100%;
                z-index: 2;
                width: 100%;
                padding-top: 5px;
                margin-top: 10px;

                background: #FFFFFF;
                box-shadow: 0px 4px 19px 0px rgba(151, 151, 151, 0.3);
                border-radius: 12px;
                height: auto;
                max-height: 400px;
                overflow: auto;

                li {
                padding: 19px 0;
                margin: 0 21px;
                border-bottom: 1px solid #DAD8D9;
                }

                li:last-child {
                border-bottom: none
                }
            }

            input:disabled {
                background-color: #ffffff;
                color: #303030 !important;
                opacity: 1;
                -webkit-text-fill-color: #303030;
            }

            input::-webkit-input-placeholder {
                color: #aaaaaa;
                opacity: 1;
                -webkit-text-fill-color: #aaaaaa;
            }

            .van-icon {
                position: absolute;
                right: 0;
            }

            .codeBtn {
                display: block;
                width: 250px;
                height: 53px;
                background: #a4adb3;
                border-radius: 12px;
                font-size: 24px;
                margin-right: 0;
                margin-left: 10px;
                font-weight: 400;
                color: #ffffff;
                line-height: 53px;
                text-align-last: auto;
                text-align: center;
            }

            .ableCodeBtn {
                // background: linear-gradient(270deg, #1890ff, #00bbff);
                background: none;
                color:#0495f7;
            }

            &:last-child {
                // align-items: flex-end;

                // /deep/ input {
                //   width: 45%;
                // }
            }

            .sel-items {
                height: 100px;
                font-size: 28px;
                font-weight: 500;
                color: #303030;
                text-align: center;
                line-height: 100px;
            }

            .selted-items {
                background: #ffffff;

                span {
                color: #0495f7;
                }
            }
        }
        .click-item {
            .title {
                color: #0495f7;
            }
        }
      }
    }
    .submit{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: 400;
      color: #FFFFFF;
      text-shadow: 0px 4px 16px rgba(28,139,255,0.5);
      background: #1C8BFF;
      box-shadow: 0px 4px 16px 0px rgba(28,139,255,0.5);
      border-radius: 48px;
      height: 100px;
      margin-top: 60px;
    }
    .bottom_text{
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      margin-top: 36px;
      text-align: center;
    }
    .tips {
      display: flex;
      flex-direction: column;
      bottom: 100px;
      text-align: center;
      width: 100%;
      margin-top: 20px;
      text-align: center;
      color: #828282;
      .sh{
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
}

.activity-popup {
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff00;

  &.activity-popup-aqa {
    width: 100%;
  }

  img:nth-child(1) {
    width: 96%;
    height: 90%;
  }

  img:nth-child(2) {
    width: 56px;
    height: 56px;
  }
}
.completeInformation {
  width: 100%;
  height: 100vh;
  background: #ffffff00;

  .content {
    margin: 0 32px;
    margin-top: calc((100vh - 684px) / 2);
    background-color: white;
    padding: 60px 54px 88px;
    border-radius: 20px;
  }

  .top {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 36px;
      font-weight: 600;
      color: #333339;
      line-height: 50px;
    }
  }

  .form-group {
    .form-item {
      width: 100%;
      margin-top: 36px;
      position: relative;

      span {
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 42px;
        max-width: 130px;
        min-width: 130px;
        margin-right: 30px;
        text-align-last: justify;
      }

      /deep/ input {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #dad8d9;
        width: 100%;
        font-size: 28px;
        color: #303030;
        line-height: 60px;
      }

      .inputBody {
        width: 100%;
      }

      input:disabled {
        background-color: #ffffff;
        color: #303030 !important;
        opacity: 1;
        -webkit-text-fill-color: #303030;
      }

      input::-webkit-input-placeholder {
        color: #aaaaaa;
        opacity: 1;
        -webkit-text-fill-color: #aaaaaa;
      }
    }

    .click-item {
      .title {
        color: #0495f7;
      }

      /deep/ input {
        border-bottom: 1px solid #0495f7;
      }
    }
  }

  .tip {
    margin-top: 36px;

    span {
      font-size: 28px;
      font-weight: 400;
      color: #999999;
      line-height: 40px;
    }
  }

  .button {
    display: flex;
    // width: 100%;
    justify-content: space-between;
    margin: 72px 60px 0;

    button {
      width: 200px;
      height: 64px;
      background: linear-gradient(270deg, #1890ff, #00bbff);
      box-shadow: 3px 1px 5px 0px rgba(70, 70, 70, 0.05);
      border-radius: 15px;
      border: 0;
      font-size: 28px;
      color: #ffffff;
      line-height: 64px;
    }
  }
}
</style>