<!--
* @date 2023-07-31 13:45:09
* @version V1.0.0
!-->
<template>
  <div id='index' class='index'>
    <van-pull-refresh class="content-list" v-model="refreshing" @refresh="onRefresh">
      <van-search class="my-search" v-model="searchVal" shape="round" :clearable='false' show-action placeholder="搜索......"
        :left-icon="require('@/static/home/search.png')">
        <template #action>
          <div class="search-btn" @click="onSearch">搜索</div>
        </template>
      </van-search>
      
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" @change="onChange">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <img @click="openUrl(item)" :src="item.url" alt="" />
        </van-swipe-item>
      </van-swipe>

      <div class="navMenu">
        <div class="nav_item" v-for="(item,index) in navMenu" :key="index" @click="navChange(index)">
          <p class="t" :class="navIndex == index ? 't_s':''">{{item}}</p>
          <div v-if="navIndex == index" class="line"></div>
        </div>
      </div>

      <div class="content">
        <van-list class="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMoreData">
          <div class="content-item" v-for="(item, index) in contentList" :key="index" :title="item.id"
            @click="openDetails(item)">
            <div class="left">
              <img :src="navIndex == 2 ? item.cover : item.image" alt="">
              <div v-if="navIndex == 0 && item.meetStatus" class="status">{{item.meetStatus}}</div>
              <div v-if="navIndex == 2 && item.typeName == 'ARTICLE'" class="status status_h">文章</div>
              <div v-if="navIndex == 2 && item.typeName == 'VIDEO'" class="status">视频</div>
            </div>
            <div class="right">
              <div class="title_body">
                <span class="title"><span v-if="item.newStatus == 'NEW'" class="new">NEW</span>{{item.title}}</span>
              </div>
              <div class="tips">
                <span class="tips_item" v-for="(item,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{item}}</span>
              </div>
              <div class="bottom" v-if="navIndex != 2">
                <span class="name">{{item.speakerName}} {{item.createTime.split(' ')[0]}}</span>
                <div class="like" @click.stop="collect(item)">
                  <img v-if="item.favority == 'Y'" src="@/static/home/liked.png" alt="">
                  <img v-else src="@/static/home/like.png" alt="">
                  <span class="t">收藏</span>
                </div>
              </div>
              <div class="bottom2" v-if="navIndex == 2">
                <div class="time">
                  <img src="@/static/home/time.png" alt="">
                  <span>{{item.createTime.split(' ')[0]}}</span>
                </div>
                <div class="time" style="margin-left:20px">
                  <img src="@/static/home/liulan.png" alt="">
                  <span>{{item.touristVolume || 0}}浏览</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>

    </van-pull-refresh>

    <!-- 成功注册 -->
    <van-popup v-model="showRegister" :close-on-click-overlay='false'>
        <div class="firstRegister">
            <img class="firstRegister_bg" src="@/static/model/firstRegister_bg.png" alt="">
            <div class="content">
              <span class="title">欢迎关注肥胖科学</span>
              <span class="desc">恭喜您获得<span>10</span>积分</span>
            </div>
            <img class="close" src="@/static/model/close.png" alt="" @click="showRegisterClose">
        </div>
    </van-popup>

    <!-- 每日签到 -->
    <van-popup v-model="showSign" :close-on-click-overlay='false'>
        <!-- <div class="sign">
            <img class="sign_bg" src="@/static/model/sign_bg.png" alt="">
            <div class="content">
              <span class="title">每日签到领积分</span>
              <div class="days">
                <div class="item" :class="index < 3 ? 'item_ed' : ''" v-for="(item,index) in 7" :key="index">
                  <div class="top" :class="index < weekDays.length ? 'top_ed' : ''">
                    <span class="day">第{{index + 1}}天</span>
                    <img v-if="index < weekDays.length" class="sign_img" src="@/static/model/qiandao.png" alt="">
                    <img v-else class="sign_img" src="@/static/model/weiqiandao.png" alt="">
                  </div>
                  <span class="status" :class="index <  weekDays.length ? 'status_ed' : ''">{{index < weekDays.length ? '已签到' : '未签到'}}</span>
                </div>
              </div>
              <span class="desc">已连续签到{{weekDays.length || 0}}天</span>
              <div class="stratSign" @click="sign">{{isSign == 'Y' ? '已签到' : '签到'}}</div>
            </div>
            <img class="close" src="@/static/model/close.png" alt="" @click="showSign = false">
        </div> -->
        <div class="sign1">
            <div class="content">
              <img class="top" src="@/static/home/sign.png" alt="">
              <span class="desc">- 已连续签到<span class="signDay">{{weekDays.length || 0}}</span>天 -</span>
              <div class="days">
                <div class="item" v-for="(item,index) in 7" :key="index">
                  <div class="top" :class="index < weekDays.length ? 'top_ed' : ''">
                    <span class="day" :class="index < weekDays.length ? 'day_ed' : ''">第{{index + 1}}天</span>
                    <img v-if="index < weekDays.length" class="sign_img" src="@/static/model/qiandao.png" alt="">
                    <img v-else class="sign_img" src="@/static/model/weiqiandao.png" alt="">
                    <span class="jf" :class="index < weekDays.length ? 'jf_ed' : ''">+2</span>
                  </div>
                  <span class="status" :class="index <  weekDays.length ? 'status_ed' : ''">{{index < weekDays.length ? '已签到' : '未签到'}}</span>
                </div>
              </div>
              <div class="stratSign" @click="sign">{{isSign == 'Y' ? '已签到' : '签到'}}</div>
            </div>
            <img class="close" src="@/static/model/close.png" alt="" @click="showSign = false">
        </div>
    </van-popup>

  </div>
</template>

<script>
import {meetBanner,meetSign,meetReply,meetsummarize,meetCollect,meetCollectCancle,isSignIn,signIn,getSignInForWeeks} from '@/request/api'
import {http_post_params} from '@/utils/index'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'index',
  data () {
    return {
        searchVal:'',//搜索
        current: 0,//轮播change
        navIndex:0,//导航index
        navMenu:['会议报名','会议回放','会议资料'],

        bannerList:[],//轮播数据
        contentList:[],
        weekDays:[],//签到几天

        pageNum: 1, //当前页
        pageSize: 20, //每页大小
        totalNum: 0, //总条数
        refreshing: false,
        loading:true,
        finished:false,

        showRegister:false,//第一次注册成功
        showSign:false,//签到
        isSign:false,//是否签到
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    //搜索
    onSearch() {
      if (!this.searchVal) {
        this.$toast("请输入搜索关键词");
        return;
      }
      this.$router.push({
        path: "/searchpage",
        query: {
          searchVal: this.searchVal,
        },
      });
    },
    //轮播
    onChange(index) {
      this.current = index;
    },
    //轮播图跳转
    openUrl(item) {
      this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_banner'})
      if(item.isSkipType == 'CONTENT'){
        if(!item.baid){return}
        if(item.isChainSelect == 'VIDEO'){
          //视频
          this.$router.push({
            path:'/video?id=' + item.baid
          })
        } else if(item.isChainSelect == 'ARTICLE'){
          //文章
          this.$router.push({
            path:'/article?id=' + item.baid
          })
        } else if(item.isChainSelect == 'COURSE'){
          //课程
          this.$router.push({
            path:'/videoCourse?id=' + item.baid
          })
        }
      } else if (item.isSkipType == 'LINK'){
        window.location.href = item.isSkipContext
      }
    },
    //菜单导航点击
    navChange(index){
      this.navIndex = index;
      this.pageNum = 1;
      this.refreshing = false;
      this.finished = false;
      this.loading = true;
      this.contentList = []
      this.getList();

      if(index == 0){
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'meeting_hybm_list'})
      } else if(index == 1){
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'meeting_hyhf_list'})
      } else if (index == 2){
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'meeting_hyzl_list'})
      }
      
    },

    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList();
      this.getBanner()
    },
    loadMoreData(){
      if (this.refreshing) {return}
      this.getList();
    },

    //列表数据
    getList(){
      var params = new Object()
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize

      if(this.navIndex == 0){
        // 会议报名
        params.isjudge = 'HYGL'
        meetSign(http_post_params(params)).then(res=>{
          if(res.code == 0){
            if(this.pageNum == 1){
              this.contentList = res.data || []
            } else {
              this.contentList = this.contentList.concat(res.data || [])
            }
            this.refreshing = false
            this.loading = false
            if(this.contentList.length >= res.total){
              this.finished = true
            } else {
              this.finished = false
            }
            this.pageNum += 1;
          }
        })
      }
      if(this.navIndex == 1){
        //会议回放
        params.isjudge = 'HYGL'
        meetReply(http_post_params(params)).then(res=>{
          if(res.code == 0){
            if(this.pageNum == 1){
              this.contentList = res.data || []
            } else {
              this.contentList = this.contentList.concat(res.data || [])
            }
            this.refreshing = false
            this.loading = false
            if(this.contentList.length >= res.total){
              this.finished = true
            } else {
              this.finished = false
            }
            this.pageNum += 1;
          }
        })
      }
      if(this.navIndex == 2){
        //会议资料
        meetsummarize(http_post_params(params)).then(res=>{
          if(res.code == 0){
            if(this.pageNum == 1){
              this.contentList = res.data || []
            } else {
              this.contentList = this.contentList.concat(res.data || [])
            }
            this.refreshing = false
            this.loading = false
            if(this.contentList.length >= res.total){
              this.finished = true
            } else {
              this.finished = false
            }
            this.pageNum += 1;
          }
        })
      }
    },

    //banner 数据
    getBanner(){
      var params = new Object()
      params.meetormedicline = 'HYZQ'
      meetBanner(http_post_params(params)).then(res=>{
        if(res.code == 0){
          this.bannerList = res.data
        }
      })
    },

    //是否签到
    getSignInStatus(){
      isSignIn().then(res=>{
        if(res.code == 0){
          if(res.data.status != 'Y'){
            this.signInForWeeks()
            this.showSign = true
            this.isSign = res.data.status
          }
        }
      })
    },
    //获取七日签到日期
    signInForWeeks(){
        getSignInForWeeks().then(res=>{
            if(res.code == 0){
                this.weekDays = res.data;
            }
        })
    },

    //签到
    sign(){
      if(this.isSign == 'Y'){
            return
        }
      this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
      });
      signIn().then(res=>{
          if(res.code == 0){
              this.$toast('签到成功')
              this.isSign = 'Y'
              this.signInForWeeks()
              this.$addLog({sourceType:'VIEW',viewId:'',viewType:'signInPoint'})
          } else {
              this.$toast(res.message)
          }
      }).catch(error=>{
          this.$toast.clear()
      })
    },

    //收藏
    collect(item){
      if(item.favority == 'Y'){
        meetCollectCancle(item.id).then(res=>{
          if(res.code == 0){
            this.$toast('取消收藏')
            item.favority = 'N'

            this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_collect_no'})
          }
        })
      } else {
        meetCollect(http_post_params({id:item.id})).then(res=>{
          if(res.code == 0){
            this.$toast('收藏成功')
            item.favority = 'Y'

            this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_collect'})
          }
        })
      }
    },

    // 详情跳转
    openDetails(item){
      if(this.navIndex == 0 || this.navIndex == 1){
        if(this.navIndex == 0){
          this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_hybm_detail'})
        } else {
          this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_hyhf_detail'})
        }
        window.location.href = item.toUrl 
      } else {
        if(item.typeName == 'ARTICLE'){
          this.$router.push({
            path:'/article?id=' + item.id + '&logType=meeting'
          })
          this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_hyzl_article'})
        }
        if(item.typeName == 'VIDEO'){
          this.$router.push({
            path:'/video?id=' + item.id + '&logType=meeting'
          })
          this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_hyzl_video'})
        }
      }
    },

    showRegisterClose(){
      this.showRegister = false
      // 清除特定的查询参数
      const { ...query } = this.$route.query;  // 复制原有的查询参数
      delete query.firstRegister;                  // 删除指定的查询参数
      this.$router.replace({ query });         // 使用 replace 方法替换当前路由
    },
  },
  mounted () { // 页面进入时加载内容
    if(this.$route.query.firstRegister){
      this.showRegister = true
    }
    // this.getBanner()
    // this.getList();
    // this.getSignInStatus()
  },
  activated(){
    this.pageNum = 1
    this.getBanner()
    this.getList();
    // this.getSignInStatus()
  },
  watch: { // 监测变化

  }
}
</script>
<style scoped lang='less'>
.index{
    width: 100%;
    height: 100%;
}
.my-search {
  margin-top: 12px;
  width: 100%;
  padding: 10px 3%;
  position: relative;

  .van-search__content {
    background: #F6F5FB;
    height: 40px;
    border-radius: 8px;
  }

  /deep/.van-cell {
    display: flex;
    align-items: center;
    .van-cell__value{
      .van-field__control{
        width: 80%;
      }
    }
  }

  /deep/.van-field__left-icon {
    // height: 18px;
    padding-right: 6px;
    margin-right: 6px;
    border-right: 2px solid #e4e4e4;
  }

  .van-search__action {
    position: absolute;
    right: 3%;
    padding: 0;
    border-radius: 31px;
  }

  .search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 60px;
    background: #1C8BFF;
    border-radius: 30px;
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 10px;
  }
}

.my-swipe {
  width: 94%;
  height: 300px;
  border-radius: 30px;
  margin: 20px auto;
  background: #F6F5FB;

  /deep/ .van-swipe__indicator {
    width: 14px;
    height: 2px;
    background: #fffeffff;
    border-radius: 1px;
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 180px;
  text-align: center;
  background-color: rgb(224, 224, 224);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.navMenu{
  display: flex;
  align-items: center;
  border-bottom: 2px #E5E5E5 solid;
  .nav_item{
    position: relative;
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 33.333%;
    height: 112px;
    .t{
      font-size: 36px;
      font-weight: 600;
      color: #000000;
    }
    .t_s{
      color: #004588;
    }
    .line{
      position: absolute;
      width: 136px;
      height: 6px;
      background: #004588;
      bottom: 0px;
    }
  }
}

.content {
  width: 94%;
  margin: 10px auto;
}
.content-list {
  background: #ffffff;
  // padding: 30px 15px 150px;
  .content-item {
    display: flex;
    padding: 36px 0 36px 0;
    border-bottom: 2px #E5E5E5 solid;
    .left{
      display: flex;
      position: relative;
      >img{
        width: 248px;
        height: 180px;
        border-radius: 16px;
        background: gainsboro;
      }
      .status{
        position: absolute;
        background: #1C8BFF;
        border-radius: 16px 0 16px 0;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 2px 10px;
        top: 0px;
        left: 0px;
        // transform: scale(0.8);
      }
      .status_h{
        background: #FDAD4A;
      }
    }
    .right{
      width: 64%;
      padding: 0 30px;
      .title_body{
        display: flex;
        vertical-align: baseline;
      }
      .title{
        font-size: 30px;
        font-weight: 500;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 48px;
        margin: 0;
        word-break: break-all;
        .new{
          background: linear-gradient(180deg, #F99068 0%, #F7312A 100%);
          font-size: 14px;
          font-weight: normal;
          color: #FFFFFF;
          padding: 5px 6px;
          // line-height: 20px;
          // transform: scale(0.7);
          margin-right: 10px;
          // margin-left: -6px;
          // margin-top: -15px;
          // width: fit-content;
          // display: inline-block;
          vertical-align: top;
        }
      }
      
      .tips{
        display: flex;
        flex-wrap: wrap;
        margin-top: 6px;
        min-height: 50px;
        .tips_item{
          background-color: #B2E0F7;
          font-size: 16px;
          font-weight: 500;
          color: #8D8D8D;
          border-radius: 6px;
          padding: 2px 8px;
          margin-right: 20px;
          margin-bottom: 10px;
          height: fit-content;
          // transform: scale(0.8);
        }
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .name{
          font-size: 24px;
          font-weight: 500;
          color: #A1A1A1;
        }
        .like{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: -20px;
          >img{
            width: 35px;
            height: auto;
          }
          .t{
            font-size: 14px;
            font-weight: 500;
            color: #A1A1A1;
            margin-top: 6px;
            // transform: scale(0.6);
          }
        }
      }
      .bottom2{
        display: flex;
        align-items: center;
        margin-top: 12px;
        .time{
          display: flex;
          align-items: center;
          >img{
            width: 26px;
            height: auto;
          }
          >span{
            font-size: 24px;
            font-weight: 400;
            color: #A1A1A1;
            margin-left: 6px;
          }
        }
      }
    }
  }
}

/deep/.van-pull-refresh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: unset; // 可选，这个视情况添加
  .van-pull-refresh__track {
    flex: 1;
  }
}

.van-popup{
  background: none;
}
.firstRegister{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  .firstRegister_bg{
    width: 608px;
    height: auto;
  }
  >.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 350px;
    // background: red;
    .title{
      font-size: 45px;
      font-weight: 600;
      color: #5CA8FD;
    }
    .desc{
      font-size: 35px;
      font-weight: 400;
      color: #000000;
      margin-top: 36px;
      >span{
        color: #FFDA49;
      }
    }
  }
  >.close{
    width: 48px;
    height: auto;
    margin-top: 62px;
  } 
}

.sign{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  .sign_bg{
    width: 582px;
    // height: auto;
    height: 800px;
  }
  >.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0px;
    .title{
      font-size: 40px;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 140px;
    }
    .desc{
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 10px;
    }
    .days{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 90%;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 26px;
        width: 25%;
        .top{
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #EEF2F5;
          border-radius: 8px;
          padding: 10px 0px;
          height: 108px;
          width: 92px;
          // width: 60%;
          .day{
            font-size: 16px;
            font-weight: 400;
            color: #599DFA;
          }
          .sign_img{
            width: 100%;
            height: auto;
            margin-top: 10px;
          }
        }
        .top_ed{
          background: #fff;
        }
        .status{
          margin-top: 8px;
          background: #EEF2F5;
          border-radius: 40px;
          padding: 2px 12px;
          font-size: 16px;
          font-weight: 400;
          color: #599DFA;
        }
        .status_ed{
          columns: #599DFA;
          background: #FFD94A;
        }
      }
      .item_ed{
        width:33.3%;
      }
    }
    .stratSign{
      width: 252px;
      height: 68px;
      background: #618EFF;
      border-radius: 40px;
      font-size: 38px;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
  >.close{
    width: 48px;
    height: auto;
    margin-top: 62px;
  } 
}
.sign1{
  display: flex;
  align-items: center;
  flex-direction: column;
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 28px;
    width: 710px;
    padding: 40px 0;
    .top{
      width: 400px;
      height:auto;
    }
    .desc{
      font-size: 28px;
      font-weight: 500;
      // color: #333333;
      color: black;
      margin-top: 32px;
      .signDay{
        color: #FDC72F;
        font-size: 40px;
        font-weight: bold;
        margin: 0 5px;
      }
    }
    .days{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-top: 30px;
      width: 90%;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 26px;
        width: 12.8%;
        .top{
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #EEF2F5;
          border-radius: 8px;
          padding: 10px 0px;
          // height: 124px;
          width: 100%;
          .day{
            font-size: 16px;
            font-weight: 400;
            color: #599DFA;
          }
          .day_ed{
            color: white;
          }
          .sign_img{
            width: 100%;
            height: auto;
            // margin-top: 10px;
          }
          .jf{
            display: flex;
            color: #618EFF;
            font-size: 9px;
            margin-top: -5px;
          }
          .jf_ed{
            color: white;
          }
        }
        .top_ed{
          background: #618EFF;
        }
        .status{
          margin-top: 14px;
          background: #EEF2F5;
          border-radius: 6px;
          padding: 2px 2px;
          font-size: 9px;
          font-weight: 400;
          color: #599DFA;
        }
        .status_ed{
          color: white;
          background: #618EFF;
        }
      }
      .item_ed{
        width:33.3%;
      }
    }
    .stratSign{
      width: 320px;
      height: 56px;
      // background: #618EFF;
      background: linear-gradient(180deg, #5DA8FD 0%, #475FE6 100%);
      border-radius: 18px;
      font-size: 28px;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
  >.close{
    width: 48px;
    height: auto;
    margin-top: 62px;
  }
}
</style>