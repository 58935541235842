<!--
* @date 2023-08-04 15:16:44
* @version V1.0.0
!-->
<template>
  <div id='myMeeting' class='myMeeting'>
        <Header>
            <div class="header-content">
                <div class="header-title">我的会议</div>
                <div class="header-back">
                <div class="back" @click="handleTitleBack">
                    <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
                </div>
            </div>
        </Header>

        <van-pull-refresh class="content-list" v-model="refreshing" @refresh="onRefresh">
            <div class="navMenu">
                <div class="nav_item" v-for="(item,index) in navMenu" :key="index" @click="navChange(index)">
                <p class="t" :class="navIndex == index ? 't_s':''">{{item}}</p>
                <div v-if="navIndex == index" class="line"></div>
                </div>
            </div>

            <div class="content">
                <van-list class="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMoreData">
                <div class="content-item" v-for="(item, index) in contentList" :key="index" :title="item.id"
                    @click="openDetails(item)">
                    <div class="left">
                        <img :src="item.cover" alt="">
                    </div>
                    <div class="right">
                        <div class="title_body">
                            <span class="title"><span v-if="item.newStatus == 'Y'" class="new">NEW</span>{{item.title}}</span>
                        </div>
                        <div class="tips">
                            <span class="tips_item" v-for="(item,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{item}}</span>
                        </div>
                        <div class="bottom">
                            <span class="name">{{item.speakerName}} {{item.createTime.split(' ')[0]}}</span>
                            <div class="like" @click.stop="collect(item)">
                              <img v-if="item.favority == 'Y'" src="@/static/home/liked.png" alt="">
                              <img v-else src="@/static/home/like.png" alt="">
                              <span class="t">收藏</span>
                            </div>
                        </div>
                    </div>
                </div>
                </van-list>
            </div>

        </van-pull-refresh>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
import {myMeetList,meetCollectCancle} from "@/request/api"
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'myMeeting',
  data () {
    return {
        navIndex:0,//导航index
        navMenu:['未开始','看回放'],

        contentList:[],

        pageNum: 1, //当前页
        pageSize: 20, //每页大小
        totalNum: 0, //总条数
        refreshing: false,
        loading:true,
        finished:false
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    //菜单导航点击
    navChange(index){
      console.log(index)
      this.navIndex = index;
      this.pageNum = 1;
      this.finished = false;
      this.loading = true;
      this.getList();
      if(this.navIndex == 0){
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_meeting_wks_list'})
      } else {
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_meeting_khf_list'})
      }
    },

    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList();
    },
    loadMoreData(){
      if (this.refreshing) {return}
      this.getList();
    },

    getList(){
      var params = new Object()
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize
      params.meetstatus = this.navIndex == 0 ? 'Y' : 'N'
      myMeetList(http_post_params(params)).then(res=>{
        if(res.code == 0){
          if(this.pageNum == 1){
            this.contentList = res.data
          } else {
            this.contentList = this.contentList.concat(res.data)
          }
          this.refreshing = false
          this.loading = false
          if(this.contentList.length >= res.total){
            this.finished = true
          } else {
            this.finished = false
          }
          this.pageNum += 1;
        }
      })
    },

    //取消收藏
    collect(item){
      if(item.favority == 'Y'){
        meetCollectCancle(item.id).then(res=>{
          if(res.code == 0){
            this.$toast('取消收藏')
            const index = this.contentList.indexOf(item);
            if (index !== -1) {
              this.contentList.splice(index, 1); // 从数组中删除指定索引位置的元素
            }
          }
        })
      }
      this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_collect_no'})
    },

    // 详情跳转
    openDetails(item){
      if(this.navIndex = 0){
        this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'userCenter_meeting_wks_detail'})
      } else {
        this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'userCenter_meeting_khf_detail'})
      }
      window.location.href = item.toUrl 
    },

  },
  mounted () { // 页面进入时加载内容
    
  },

  activated(){
    if(this.$route.query.type){
        if(this.$route.query.type == 'wks'){
          this.navIndex = 0
          this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_meeting_wks_list'})
        }
        if(this.$route.query.type == 'hf'){
          this.navIndex = 1
          this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_meeting_khf_list'})
        }
    }
    this.pageNum = 1;
    this.finished = false;
    this.loading = true;
    this.getList()
  },

  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>

.navMenu{
  display: flex;
  align-items: center;
  border-bottom: 2px #E5E5E5 solid;
  .nav_item{
    position: relative;
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 33.333%;
    height: 112px;
    .t{
      font-size: 36px;
      font-weight: 600;
      color: #000000;
    }
    .t_s{
      color: #004588;
    }
    .line{
      position: absolute;
      width: 136px;
      height: 6px;
      background: #004588;
      bottom: 0px;
    }
  }
}

.content {
  width: 94%;
  margin: 10px auto;
}
.content-list {
  background: #ffffff;
  // padding: 30px 15px 150px;
  .content-item {
    display: flex;
    padding: 26px 0 26px 0;
    // border-bottom: 2px #E5E5E5 solid;
    .left{
      display: flex;
      position: relative;
      >img{
        width: 248px;
        height: 180px;
        border-radius: 16px;
        background: gainsboro;
      }
      .status{
        position: absolute;
        background: #1C8BFF;
        border-radius: 16px 0 16px 0;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 2px 10px;
        top: 0px;
        left: 0px;
        // transform: scale(0.8);
      }
      .status_h{
        background: #FDAD4A;
      }
    }
    .right{
      width: 64%;
      padding: 0 30px;
      .title_body{
        display: flex;
        vertical-align: baseline;
      }
      .title{
        font-size: 30px;
        font-weight: 500;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 48px;
        margin: 0;
        word-break: break-all;
        .new{
          background: linear-gradient(180deg, #F99068 0%, #F7312A 100%);
          font-size: 14px;
          font-weight: normal;
          color: #FFFFFF;
          padding: 5px 6px;
          // line-height: 20px;
          // transform: scale(0.7);
          margin-right: 10px;
          // margin-left: -6px;
          // margin-top: -15px;
          // width: fit-content;
          // display: inline-block;
          vertical-align: top;
        }
      }
      
      .tips{
        display: flex;
        flex-wrap: wrap;
        margin-top: 6px;
        min-height: 50px;
        .tips_item{
          background-color: #B2E0F7;
          font-size: 16px;
          font-weight: 500;
          color: #8D8D8D;
          border-radius: 6px;
          padding: 2px 8px;
          margin-right: 20px;
          margin-bottom: 10px;
          height: fit-content;
          // transform: scale(0.8);
        }
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .name{
          font-size: 24px;
          font-weight: 500;
          color: #A1A1A1;
        }
        .like{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: -20px;
          >img{
            width: 35px;
            height: auto;
          }
          .t{
            font-size: 14px;
            font-weight: 500;
            color: #A1A1A1;
            margin-top: 6px;
            // transform: scale(0.6);
          }
        }
      }
      .bottom2{
        display: flex;
        align-items: center;
        margin-top: 12px;
        .time{
          display: flex;
          align-items: center;
          >img{
            width: 26px;
            height: auto;
          }
          >span{
            font-size: 24px;
            font-weight: 400;
            color: #A1A1A1;
            margin-left: 6px;
          }
        }
      }
    }
  }
}

/deep/.van-pull-refresh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: unset; // 可选，这个视情况添加
  .van-pull-refresh__track {
    flex: 1;
  }
}
</style>