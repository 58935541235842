<!--
* @date 2023-08-04 17:25:39
* @version V1.0.0
!-->
<template>
  <div id='sign' class='sign'>
        <Header>
            <div class="header-content">
                <div class="header-title">签到</div>
                <div class="header-back">
                    <div class="back" @click="handleTitleBack">
                        <img class="returnIcon" src="@/static/return.png" alt="" />
                    </div>
                </div>
            </div>
        </Header>

        <div class="content">
            <div class="bg">
                <img src="@/static/mine/qiandao_bg_1.png" alt="">
                <img src="@/static/mine/qiandao_bg_2.png" alt="">
            </div>
            <div class="body">
                <div class="days">
                    <span class="total">已连续签到{{weekDays ? weekDays.length  : 0}}天</span>
                    <div class="day7">
                        <span :class="index < weekDays.length ? 'signed' : ''" v-for="(item,index) in progressDays" :key="index">{{item}}</span>
                    </div>
                    <van-progress class="progress" color="#FFD94A" :show-pivot="false" :percentage="progress" stroke-width="8" />
                    <div class="startSign" @click="startSign">{{isSign == 'Y' ? '已打卡' : '立即打卡'}}</div>
                </div>
                <div class="calendar">
                    <Calendar :selectDays="selectDays" @nextMoth="nextMoth" @upMoth="upMoth" class="calendar_b"></Calendar>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
import Calendar from "../../../components/calendar.vue"
import {signIn,isSignIn,getSignInForMonth,getSignInForWeeks} from '@/request/api'
import {http_post_params} from '@/utils/index'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'sign',
  data () {
    return {
        progressDays:[],//进度日期
        selectDays:[],//某月的签到日期
        weekDays:[],//连续签到日期
        progress:0,//进度
        isSign:false,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header,
    Calendar
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    //签到
    startSign(){
        if(this.isSign == 'Y'){
            return
        }
        this.$toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
        });
        signIn().then(res=>{
            if(res.code == 0){
                this.$toast('打卡成功')
                this.isSign = 'Y'
                this.signInForWeeks()
                this.getSignMonth()
            } else {
                this.$toast(res.message)
            }
        }).catch(error=>{
            this.$toast.clear()
        })
    },

    //是否签到
    getSignStatus(){
        isSignIn().then(res=>{
            if(res.code == 0){
                this.isSign = res.data.status;
            }
        })
    },

    //获取七日签到日期
    signInForWeeks(){
        getSignInForWeeks().then(res=>{
            if(res.code == 0){
                this.weekDays = res.data;
                this.progress = ((this.weekDays && this.weekDays.length > 0) ? this.weekDays.length / 7 : 0) * 100
                this.get7days()
            }
        })
    },

    //获取签到第一天后的7天日期
    get7days(){
        this.progressDays = []
        if(this.weekDays && this.weekDays.length > 0){
            let d = new Date();
            var year = d.getFullYear();
            var month = d.getMonth()
            var day = this.weekDays[0];
            var currentDate = `${year}/${month}/${day}`

            const firstDay = new Date(currentDate)
            // console.log(firstDay)
            for (let i = 0;i <= 6; i++){
                const nextDay = new Date(firstDay)
                nextDay.setDate(firstDay.getDate() + i);
                // const year = nextDay.getFullYear();
                // const month = String(nextDay.getMonth() + 1).padStart(2, '0');
                const day = String(nextDay.getDate()).padStart(2, '0');
                // const formattedDate = `${year}-${month}-${day}`;
                // console.log(formattedDate)
                this.progressDays.push(day)
            }
        } else {
            //没有返回就是当前日期开始的后6天
            const firstDay = new Date()
            // console.log(firstDay)
            for (let i = 0;i <= 6; i++){
                const nextDay = new Date(firstDay)
                nextDay.setDate(firstDay.getDate() + i);
                // const year = nextDay.getFullYear();
                // const month = String(nextDay.getMonth() + 1).padStart(2, '0');
                const day = String(nextDay.getDate()).padStart(2, '0');
                // const formattedDate = `${year}-${month}-${day}`;
                // console.log(formattedDate)
                this.progressDays.push(day)
            }
        }
    },

    //上下月切换日历
    upMoth(date){
        this.selectDays = [];
        this.getSignMonth(date)
    },
    nextMoth(date){
        this.selectDays = [];
        this.getSignMonth(date)
    },

    //获取月签到日
    getSignMonth(date){
        this.$toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
        });
        getSignInForMonth(http_post_params({dateTime:date})).then(res=>{
            if(res.code == 0){
                if(res.data && res.data.length  > 0){
                    var list = res.data.map(item => parseInt(item));
                    this.selectDays = list
                }
            }
            this.$toast.clear()
        })
    },
  },
  mounted () { // 页面进入时加载内容
    this.getSignStatus()
    this.signInForWeeks()
    let d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth()
    this.getSignMonth(year + '-' + (month < 9 ? '0' + (month + 1): month + 1))
    this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_sign'})
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.content{
    display: flex;
    position: relative;
    .bg{
        width: 100%;
        >img{
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        // top: 448px;
        top:31%;
        width: 100%;
        .days{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 38px;
            background: #FFFFFF;
            border-radius: 16px;
            width: 80%;
            .total{
                font-size: 32px;
                font-weight: 400;
                color: #333333;
            }
            .day7{
                display: flex;
                align-items: center;
                margin-top: 40px;
                >span{
                    font-size: 26px;
                    font-weight: 500;
                    color: #000000;
                    padding: 0 27px;
                }
                .signed{
                    color: #FF733A;
                }
            }
            .progress{
                width: 88%;
                margin-top: 32px;
            }
            .startSign{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 48px;
                background: #FF733A;
                font-size: 28px;
                font-weight: 600;
                color: #FFFFFF;
                height: 62px;
                width: 60%;
                border-radius: 31px;
            }
        }
        .calendar{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 38px;
            background: #FFFFFF;
            border-radius: 16px;
            width: 80%;
            margin-top: 40px;
            // .calendar_b{
            //     width: 80%;
            // }
        }
    }
}
</style>