<!--
* @date 2023-08-17 18:38:52
* @version V1.0.0
!-->
<template>
  <div id='instructions' class='instructions'>
       <Header>
          <div class="header-content">
              <div class="header-title">使用说明</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
       </Header>

        <div>
            <div class="card">
                <div>卡号：{{ number }}</div>
                <div>卡密：{{ password }}</div>
            </div>
            <div class="explain">
                <div class="headFont">使用说明</div>
                <div class="article">
                <div>{{ articleText }}</div>
                <div class="dataMar">学习卡申请有效期为{{ date }}</div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
export default {
  props: { // 父辈向子辈传参
  },
  name: 'instructions',
  data () {
    return {
        number: 0,
      password: 0,
      articleText:
        '此学习卡可在华医网www.91huayi.com或掌上华医APP学习任一远程继续医学教育项目，请老师完成该项目下所有课程的学习，并通过相应考核后，可获得对应的继教学分，具体分值以当地政策为准。华医网学习卡目前不适用地区如下：上海市、浙江省、云南省、内蒙古自治区；广东省深圳市，山东省淄博市、枣庄市、菏泽市，黑龙江农垦、森工',
      date: '2023年12月31日',
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },
  },
  mounted () { // 页面进入时加载内容
    this.$nextTick(() => {
      var usersn = this.$route.query.sn.split(';')
      this.number = usersn[0]
      this.password = usersn[1]
      console.log(this.number, this.password)
    })
  },
  watch: { // 监测变化

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },

}
</script>
<style scoped lang='less'>
.card {
  width: 90%;
  margin: auto;
  margin-top: 54px;
  height: 170px;
  background: #ffffff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 42px;
  div {
    padding: 26px 0 0 32px;
  }
}

.explain {
  width: 90%;
  margin: auto;
  margin-top: 34px;
  height: 698px;
  background: #ffffff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  .headFont {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333336;
    line-height: 42px;
    padding: 32px;
  }
  .article {
    padding: 0 32px 0 32px;
    height: calc(100% - 106px);
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 46px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .dataMar {
      margin-bottom: 58px;
    }
  }
}
</style>