import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index'
import {
    getUrlParam,
} from "@/utils/index.js";

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, _from, next) => {
    // next()
    // return
  if (!window.sessionStorage.getItem("TokenKey")) {
    //无token
    var test39 = location.href.indexOf('fpkx.roztop.com') != -1
    var test = location.href.indexOf('test.feipangkexue.novonordiskchina.cn') != -1
    var formal = location.href.indexOf('feipangkexue.novonordiskchina.cn') != -1
    if (test39 || test || formal) {//打包
      const code = getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href;
      if (code == null || code === "") { //打包
          window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${(test || test39) ? 'wx5562ce9c04e798a7' : 'wxb0c6e6d4c85bcb8c'}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`)
      } else {
          store.dispatch("getInfo", code).then((res) => { //打包
              resfun(res)
          }).catch((rem) => {
              console.log(rem + "//");
          });
      }
    } else {//本地
        //测试openid o6HSvjv9XVd1uXHtFll-jxkLmjMI    李 o60D-6j7H6WV8YYeZG9mhv77Zdp8  佳瑶 o60D-6l0A03qChWA-QZLvf6Yh06g
        store.dispatch("getInfo", "o60D-6j7H6WV8YYeZG9mhv77Zdp8").then((res) => { //本地
            resfun(res)
        }).catch((rem) => {
            console.log(rem + "//");
        });
    }
    var resfun = (res) => {
        Vue.prototype.$addLog({sourceType:'VIEW',viewId:'',viewType:'login'})
        console.log('------' + to.path)
        if (res.siteUser || res.siteSales) { //已注册任一角色
            if (to.path === '/' || to.path === '/register') {
                next({
                    path: '/home/index'
                })
            } else {
                next()
            }
        } else { //没注册跳注册页
            if (to.path === '/register') {
                next()
            } else if(to.path === '/home/tools' || to.path === '/reckoner'){
                //工具页直接可以进
                next()
            } else {
                next('/register')
            }
        }
        if (window.location.href.indexOf("&code=") !== -1 || window.location.href.indexOf("?code=") !== -1) {
            let key = window.location.href.indexOf("&code=") !== -1 ? '&code=' : '?code='
            window.location.replace(window.location.href.split(key)[0] + "#" + window.location.href.split("#")[1])
        }
    }
  } else {
    // 有token
    if (window.sessionStorage.getItem("siteUser") && window.sessionStorage.getItem("siteUser") != 'null') { //已注册
        store.commit("setUserInfo", JSON.parse(window.sessionStorage.getItem("siteUser")))
        store.commit("setWecharUser", JSON.parse(window.sessionStorage.getItem("wecharUser")))
        
        if (to.path === '/' || to.path === '/register') {
            next({path: '/home/index'})
        } else {
            next()
        }
    } else { //没注册
        if (to.path === '/register') {
            next()
        } else if(to.path === '/home/tools' || to.path === '/reckoner'){
            //工具页直接可以进
            next()
        } else {
          next('/register')
      }
    }
  }

  window.scrollTo(0, 0); // 将滚动位置设置为顶部
  store.commit("changeTabActive", to.path)
})

export default router
