var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index",attrs:{"id":"index"}},[_c('Header',[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"header-title"},[_vm._v("积分商城")]),_c('div',{staticClass:"header-back"},[_c('div',{staticClass:"back",on:{"click":_vm.handleTitleBack}},[_c('img',{staticClass:"returnIcon",attrs:{"src":require("@/static/return.png"),"alt":""}})])])])]),_c('div',{staticClass:"derTop"},[_c('img',{staticClass:"bg",attrs:{"src":require("@/static/mine/jifen_bg.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-item"},[_c('span',{staticClass:"photo-bg"},[(_vm.$store.state.login.wecharUser.avatar)?_c('img',{attrs:{"src":_vm.$store.state.login.wecharUser.avatar,"alt":""}}):_c('img',{attrs:{"src":require("@/static/mine/def_header.png"),"alt":""}})]),_c('span',{staticClass:"namesty"},[_vm._v(_vm._s(_vm.$store.state.login.siteUser.name))])]),_c('div',{staticClass:"info-item"},[_c('img',{staticClass:"top-icon",attrs:{"src":require("@/static/mine/jifen_icon.png"),"alt":""}}),_c('span',[_vm._v("积分")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.point || 0))])]),_c('div',{staticClass:"info-item",on:{"click":_vm.address}},[_c('img',{staticClass:"top-icon",attrs:{"src":require("@/static/mine/dizhi.png"),"alt":""}}),_c('span',[_vm._v("地址")])])])]),_c('div',{staticClass:"mylist"},_vm._l((_vm.shopList),function(item,idx){return _c('div',{key:idx + 'm',staticClass:"list-item"},[_c('div',{staticClass:"goods-group",on:{"click":function($event){return _vm.handleExchange(item)}}},[_c('div',{staticClass:"goods-img"},[_c('img',{attrs:{"src":item.image,"alt":""}})]),_c('p',{staticClass:"goods-name over-hid"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"btn-group"},[_c('span',{staticClass:"integral"},[_c('span',{staticClass:"point"},[_vm._v(_vm._s(item.point || 0))]),_vm._v("分")]),_c('div',{staticClass:"exchange-btn",class:_vm.times > 0 ||
                (item.yearLimit !== null && item.yearSum >= item.yearLimit) ||
                (item.monthLimit !== null && item.monthSum >= item.monthLimit) ||
                item.shopStatus == 'N' ||
                item.totalNum == '0'
                ? 'dis-btn'
                : '',on:{"click":function($event){return _vm.handleExchange(item)}}},[_vm._v(" "+_vm._s(_vm.times > 0 || (item.yearLimit !== null && item.yearSum >= item.yearLimit) || (item.monthLimit !== null && item.monthSum >= item.monthLimit) ? "超过限额" : item.totalNum == 0 ? "补货中" : item.shopStatus == "N" ? "积分不足" : "立即兑换")+" ")])])])}),0),_c('div',{staticClass:"my-footer"},[_c('div',{staticClass:"foot-btn flex-c",on:{"click":_vm.integralRule}},[_vm._v("积分规则")]),_c('div',{staticClass:"foot-btn flex-c",on:{"click":_vm.exchange}},[_vm._v("兑换记录")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }