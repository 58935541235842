<template>
  <div class="header">
    <div class="header-bg">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped lang="less">
.header {
  width: 100%;
  height: 100px;
  background: #ffffff;
  box-shadow: 1px 4px 9px 0px rgba(151, 151, 151, 0.16);
  z-index: 100;
  .header-bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 400;
    color: #303030;
  }
}
</style>