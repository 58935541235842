export function clearLoginStatus() {
    window.sessionStorage.removeItem("TokenKey");
    window.sessionStorage.removeItem("wecharUser");
    window.sessionStorage.removeItem("siteUser");
    window.location.reload();
}

//节流
export function thorttle(fn, delay = 1000) {
    let lastTime = ''
    let timer = ''
    let interval = delay
    return function () {
        let args = arguments
        let nowTime = Date.now()
        if (lastTime && nowTime - lastTime < interval) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                lastTime = nowTime
                fn.apply(this, args)
            }, interval)
        } else {
            lastTime = nowTime
            fn.apply(this, args)
        }
    }
}

// 校验手机格式
export function checkMobile(mobile) {
    return RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/).test(mobile);
}

// post接口拼接
export function http_post_params(data) {
    var key = Object.keys(data)
    var val = Object.values(data)
    var str = ''
    for (var i = 0; i < key.length; i++) {
        // if (val[i]) {
        str += `${key[i]}=${val[i] || val[i] === 0 ? val[i] : ''}`
        if (i != key.length - 1) {
            str += '&'
        }
        // }
    }
    return encodeURI(str)
}

// 获取url[code]参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

// 时间戳转为日期年月日时分秒
export function formatDate(value) {
    let date = new Date(parseInt(value));
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    // let s = date.getSeconds();
    // s = s < 10 ? ('0' + s) : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
}
// 时间戳转为日期年月日
export function formatDay(value) {
    let date = new Date(parseInt(value));
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    // let h = date.getHours();
    // h = h < 10 ? ('0' + h) : h;
    // let m = date.getMinutes();
    // m = m < 10 ? ('0' + m) : m;
    // let s = date.getSeconds();
    // s = s < 10 ? ('0' + s) : s;
    return y + '-' + MM + '-' + d;
}