<!--
* @date 2023-08-07 15:57:14
* @version V1.0.0
!-->
<template>
  <div id='index' class='index'>
       <Header>
          <div class="header-content">
              <div class="header-title">积分商城</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
       </Header>

        <div class="derTop">
            <img class="bg" src="@/static/mine/jifen_bg.png" alt="">
            <div class="info">
                <div class="info-item">
                    <span class="photo-bg">
                        <img v-if="$store.state.login.wecharUser.avatar" :src="$store.state.login.wecharUser.avatar" alt="">
                        <img v-else src="@/static/mine/def_header.png" alt="">
                    </span>
                    <span class="namesty">{{$store.state.login.siteUser.name}}</span>
                </div>
                <div class="info-item">
                    <img class="top-icon" src="@/static/mine/jifen_icon.png" alt="" />
                    <span>积分</span>
                    <span class="num">{{point || 0}}</span>
                </div>
                <div class="info-item" @click="address">
                    <img class="top-icon" src="@/static/mine/dizhi.png" alt="" />
                    <span>地址</span>
                </div>
            </div>
        </div>

        <div class="mylist">
          <div class="list-item" v-for="(item, idx) in shopList" :key="idx + 'm'">
            <div class="goods-group" @click="handleExchange(item)">
              <div class="goods-img">
                <img :src="item.image" alt="" />
              </div>
              <p class="goods-name over-hid">{{ item.name }}</p>
            </div>
            <div class="btn-group">
              <span class="integral"><span class="point">{{ item.point || 0}}</span>分</span>
              <div class="exchange-btn " :class="
                times > 0 ||
                  (item.yearLimit !== null && item.yearSum >= item.yearLimit) ||
                  (item.monthLimit !== null && item.monthSum >= item.monthLimit) ||
                  item.shopStatus == 'N' ||
                  item.totalNum == '0'
                  ? 'dis-btn'
                  : ''
              " @click="handleExchange(item)">
                <!-- limitType：物品兑换限制类型(月，年，永久)
                monthLimit：物品月兑换限制次数
                monthSum：用户实际月兑换次数
                yearLimit：物品年兑换限制次数
                yearSum：用户实际年兑换次数
                total：用户总计兑换次数 -->

                <!-- 有年兑换限制次数 && 实际年兑换次数  大于  年兑换次数
                有物品月兑换次数 && 实际月兑换次数  大于 月兑换次数
                * 放弃规则 有年兑换限制次数 && 用户总计兑换次数  大于  年兑换次数 -->
                {{
                  times > 0 ||
                  (item.yearLimit !== null && item.yearSum >= item.yearLimit) ||
                  (item.monthLimit !== null && item.monthSum >= item.monthLimit)
                  ? "超过限额"
                  : item.totalNum == 0
                    ? "补货中"
                    : item.shopStatus == "N"
                      ? "积分不足"
                      : "立即兑换"
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-footer">
            <div class="foot-btn flex-c" @click="integralRule">积分规则</div>
            <div class="foot-btn flex-c" @click="exchange">兑换记录</div>
        </div>

  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {pointMallList,getUser} from "@/request/api"
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'index',
  data () {
    return {
      point:0,
      shopList:[],

      times: "",
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          // this.$router.back();
          this.$router.replace({
            path:'/home/mine'
          })
        }
      });
    },

    //地址
    address(){
        this.$router.push({path:'/address'})
    },
    //规则
    integralRule(){
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'shopping_mall_point_rule'})
        this.$router.push({path:'/integralRule'})
    },
    //兑换记录
    exchange(){
      this.$addLog({sourceType:'VIEW',viewId:'',viewType:'shopping_mall_history'})
      this.$router.push({
        path:'/exchangeRecord'
      })
    },

    //兑换
    handleExchange(item) {
      if (
        this.times > 0 ||
        (item.yearLimit !== null && item.yearSum >= item.yearLimit) ||
        (item.monthLimit !== null && item.monthSum >= item.monthLimit) ||
        item.shopStatus == "N" ||
        item.totalNum == "0"
      ) {
        return;
      }

      this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'shopping_mall_detail'})

      if (item.online == "XNSP") {
        //虚拟商品
        this.$router.push({
          path: "/confirmOrder",
          query: {
            id: item.id,
            online: item.online
          },
        });
      } else {
        this.$router.push({
          path: "/address",
          query: {
            id: item ? item.id : 0,
          },
        });
      }
    },

    //积分商城数据
    getShopList(){
      var params = new Object()

      pointMallList(http_post_params(params)).then(res=>{
        if(res.code == 0){
          this.shopList = res.data.mall
          this.times = res.data.numOfTimes;
        }
      })
    },

    //获取积分
    getPoint(){
      getUser().then(res=>{
        if(res.code== 0){
          this.point = res.data.point
        }
      })
    },
  },
  mounted () { // 页面进入时加载内容

  },

  activated(){
    this.getShopList()
    this.getPoint()
    this.$addLog({sourceType:'VIEW',viewId:'',viewType:'shopping_mall'})
  },

  watch: { // 监测变化

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.index{
    height: 100vh;
    overflow-y: scroll;
    background: #F6F6F8;
}
.derTop {
    display: flex;
    width: 100%;
    height: 126px;
    background: #ffffff;
    position: relative;
    .bg{
        width: 100%;
        height: 200px;
    }
    .info{
        position: absolute;
        display: flex;
        width: 100%;
        margin-top: 40px;

        .info-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33.33%;
            // background: orange;
            .photo-bg {
                margin-right: 17px;
                img {
                    width: 88px;
                    height: auto;
                    border-radius: 50%;
                }
            }

            .namesty {
                font-size: 30px;
            }

            .top-icon {
                width: 38px;
                height: 38px;
                margin-right: 8px;
            }

            .num {
                font-size: 38px;
                font-weight: bold;
                color: #F9BE0F;
                margin-left: 8px;
            }
        }
    }
}

.my-footer {
  width: 100%;
  height: 120px;
  background: #FFFFFF;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .foot-btn {
    width: 25%;
    height: 56px;
    background: linear-gradient(90deg, #1C8BFF 0%, #004588 100%);
    border-radius: 8px;
    font-size: 27px;
    font-weight: 400;
    color: #ffffff;
    line-height: 56px;
    text-align: center;
  }
}

.mylist {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px 120px 48px;
  justify-content: space-between;

  .list-item {
    width: 47%;
    border-radius: 10px;
    // padding: 10px 0;
    margin-bottom: 37px;
    position: relative;

    .goods-group {
      font-size: 19px;
      font-weight: 400;
      // padding-bottom: 10px;
      // border-bottom: 2px solid #e8e8e8;

      .goods-img {
        height: 308px;
        border-radius: 16px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;

        img {
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .goods-name {
        width: 100%;
        font-size: 28px;
        font-weight: 400;
        color: #000000;
        margin: 20px 0 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .btn-group {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .exchange-btn {
        padding: 2px 8px;
        background: #1C8BFF;
        border-radius: 10px;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }

      .dis-btn {
        background: #dadada;
        // box-shadow: 0px 3px 5px 0px rgba(218, 218, 218, 0.52);
      }

      .integral {
        font-size: 24px;
        color: #000000;
        .point{
          color: #1C8BFF;
          font-size: 30px;
          font-weight: bold;
          margin-right: 14px;
        }
      }
    }
  }
}
</style>