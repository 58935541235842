<!--
* @date 2023-08-10 15:33:59
* @version V1.0.0
!-->
<template>
  <div id='confirmOrder' class='confirmOrder'>
       <Header>
          <div class="header-content">
              <div class="header-title">确认下单</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
       </Header>

       <div class="container">
            <div class="order-group">
                <div class="top flex-row-a">
                    <img :src="thumb" alt="" />
                    <div class="good">
                        <p class="name">{{ name }}</p>
                        <p>兑换使用积分：{{ point || 0 }}</p>
                        <p>兑换后剩余积分：{{ surplusPoint || 0}}</p>
                    </div>
                </div>
                <div class="bot" v-show="$route.query.addressId">
                    <div>
                        <span>{{ userName }}</span>
                        <span class="phone">{{ phone }}</span>
                    </div>
                    <span class="address">{{address}}</span>
                </div>
            </div>
            <div class="add-btn flex-c" @click="submit">确认下单</div>
        </div>

        <van-popup v-model="successShow" :close-on-click-overlay='false'>
            <div class="success">
                <div class="content">
                  <span class="title">恭喜您兑换成功</span>
                  <span class="title">请在兑换记录中查看</span>
                </div>
                <img class="close" src="@/static/model/close.png" alt="" @click="closeSuccess">
            </div>
        </van-popup>

  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {getOrderOk,submitOrder} from "@/request/api"
import { http_post_params } from "@/utils/index.js";
export default {
  props: { // 父辈向子辈传参
  },
  name: 'confirmOrder',
  data () {
    return {
        thumb: "",
        name: "",
        learn: false,
        point: 0,
        surplusPoint: 0,
        userName: "",
        phone: "",
        address: "",

        successShow:false,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    getDetail(){
        this.$toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
        });
        let p = {
            id: this.$route.query.id,
            addressId: this.$route.query.addressId,
        };
        getOrderOk(http_post_params(p)).then(res=>{
            if(res.code == 0){
                let info = res.data;
                this.thumb = info.image;
                this.name = info.ShopName;
                this.point = info.ShopPoint;
                this.surplusPoint = info.surplusPoint;
                this.userName = info.userName;
                this.phone = info.userPhone;
                this.address = info.address;
                if (this.$route.query.online == "1") {
                    this.learn = true;
                } else {
                    this.learn = false;
                }
                this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'shopping_mall_confirm'})
            }
            this.$toast.clear();
        })
    },

    submit(){
      this.$dialog
        .confirm({
          message: "确认兑换此商品？",
        })
        .then(() => {
          let p = {
            merchandiseId: this.$route.query.id,
            addressId: this.$route.query.addressId,
          };
          submitOrder(http_post_params(p)).then((res) => {
            if (res.code == 0) {
              this.successShow = true;
              this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'shopping_mall_purchase'})
            }else if(res.code == -100){
              this.$toast(res.message);
            }
          });
        })
        .catch(() => {});
    },
    
    closeSuccess(){
      this.successShow = false;
      this.$router.push({
        path: "/integralMall",
      });
    },
  },
  mounted () { // 页面进入时加载内容

  },

  activated(){
    this.successShow = false
    this.getDetail()
  },

  watch: { // 监测变化

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.confirmOrder{
    background: #F6F6F8;
    height: 100vh;
    overflow-y: scroll;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0 36px;
    width: 100%;
  .order-group {
    margin-top: 40px;
    background: #fdfffe;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.5);
    border-radius: 16px;
    padding: 30px 38px;
    width: 80%;
    font-size: 24px;
    font-weight: 400;
    color: #777778;
    line-height: 31px;
    display: flex;
    flex-direction: column;
    .top {
      margin: 5px auto;
      display: flex;
      align-items: center;
      width: 100%;
      img {
        width: 254px;
        height: 230px;
        margin-right: 36px;
      }
      .good {
        width: 52%;
        .name {
          font-size: 32px;
          font-weight: bold;
          color: #000000;
          line-height: 41px;
          margin-bottom: 24px;
        }
        >P{
            margin: 0;
            padding: 0;
        }
        >P:nth-child(3){
            margin-top: 10px;
        }
      }
    }
    .bot {
        margin-top: 14px;
        .phone{
            margin-left: 40px;
        }
        .address{
            display: flex;
            margin-top: 10px;
        }
    }
  }

  .add-btn {
    padding: 8px 22px;
    margin-top: 30px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #1C8BFF 0%, #004588 100%);
    border-radius: 8px;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
  }
}

.van-popup{
    background: none;
}
.success{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  >.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    width: 606px;
    border-radius: 20px;
    padding: 60px 0;
    .title{
      font-size: 32px;
      font-weight: 400;
      color: #000000;
    }
  }
  >.close{
    width: 48px;
    height: auto;
    margin-top: 62px;
  } 
}
</style>