<!--
* @date 2023-07-31 13:55:41
* @version V1.0.0
!-->
<template>
  <div id='index' class='home-section'>
        <div class="tab-page cant-tab-page">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>

        <div class="tabFloat" @click="showTabbar=true">
            <!-- <div></div> -->
            <img src="@/static/tabbar/add.png" alt="">
        </div>
        
        <van-popup v-model="showTabbar" closeable round position="bottom" :style="{ height: '10%' }">
            <van-tabbar class="tab-bar" v-model="$store.state.tabbar.active"
                @change="tabChange">
                <van-tabbar-item name="/home/index">
                    <template #icon="props">
                    <img class="home-icon" v-if="props.active" src="@/static/tabbar/tab_1_s.png" />
                    <img class="home-icon" v-else src="@/static/tabbar/tab_1.png" />
                    <p class="text" :class="props.active ? 'sel-txt' : ''">会议专区</p>
                    </template>
                </van-tabbar-item>
                <van-tabbar-item name="/home/science">
                    <template #icon="props">
                    <img class="home-icon" v-if="props.active" src="@/static/tabbar/tab_2_s.png" />
                    <img class="home-icon" v-else src="@/static/tabbar/tab_2.png" />
                    <p class="text" :class="props.active ? 'sel-txt' : ''">医学内容</p>
                    </template>
                </van-tabbar-item>
                <van-tabbar-item name="/home/tools">
                    <template #icon="props">
                    <img class="home-icon" v-if="props.active" src="@/static/tabbar/tab_3_s.png" />
                    <img class="home-icon" v-else src="@/static/tabbar/tab_3.png" />
                    <p class="text" :class="props.active ? 'sel-txt' : ''">实用工具</p>
                    </template>
                </van-tabbar-item>
                <van-tabbar-item name="/home/mine">
                    <template #icon="props">
                    <img class="home-icon" v-if="props.active" src="@/static/tabbar/tab_4_s.png" />
                    <img class="home-icon" v-else src="@/static/tabbar/tab_4.png" />
                    <p class="text" :class="props.active ? 'sel-txt' : ''">个人中心</p>
                    </template>
                </van-tabbar-item>
            </van-tabbar>
        </van-popup>

        <!-- 每日签到 -->
        <van-popup v-model="showSign" :close-on-click-overlay='false'>
            <!-- <div class="sign">
                <img class="sign_bg" src="@/static/model/sign_bg.png" alt="">
                <div class="content">
                <span class="title">每日签到领积分</span>
                <div class="days">
                    <div class="item" :class="index < 3 ? 'item_ed' : ''" v-for="(item,index) in 7" :key="index">
                    <div class="top" :class="index < weekDays.length ? 'top_ed' : ''">
                        <span class="day">第{{index + 1}}天</span>
                        <img v-if="index < weekDays.length" class="sign_img" src="@/static/model/qiandao.png" alt="">
                        <img v-else class="sign_img" src="@/static/model/weiqiandao.png" alt="">
                    </div>
                    <span class="status" :class="index <  weekDays.length ? 'status_ed' : ''">{{index < weekDays.length ? '已签到' : '未签到'}}</span>
                    </div>
                </div>
                <span class="desc">已连续签到{{weekDays.length || 0}}天</span>
                <div class="stratSign" @click="sign">{{isSign == 'Y' ? '已签到' : '签到'}}</div>
                </div>
                <img class="close" src="@/static/model/close.png" alt="" @click="showSign = false">
            </div> -->
            <div class="sign1">
                <div class="content">
                <img class="top" src="@/static/home/sign.png" alt="">
                <span class="desc">- 已连续签到<span class="signDay">{{weekDays.length || 0}}</span>天 -</span>
                <div class="days">
                    <div class="item" v-for="(item,index) in 7" :key="index">
                    <div class="top" :class="index < weekDays.length ? 'top_ed' : ''">
                        <span class="day" :class="index < weekDays.length ? 'day_ed' : ''">第{{index + 1}}天</span>
                        <img v-if="index < weekDays.length" class="sign_img" src="@/static/model/qiandao.png" alt="">
                        <img v-else class="sign_img" src="@/static/model/weiqiandao.png" alt="">
                        <span class="jf" :class="index < weekDays.length ? 'jf_ed' : ''">+2</span>
                    </div>
                    <span class="status" :class="index <  weekDays.length ? 'status_ed' : ''">{{index < weekDays.length ? '已签到' : '未签到'}}</span>
                    </div>
                </div>
                <div class="stratSign" @click="sign">{{isSign == 'Y' ? '已签到' : '签到'}}</div>
                </div>
                <img class="close" src="@/static/model/close.png" alt="" @click="showSign = false">
            </div>
        </van-popup>
  </div>
</template>

<script>
import {isSignIn,signIn,getSignInForWeeks} from '@/request/api'
import {http_post_params} from '@/utils/index'
import { clearLoginStatus } from '../../utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'index',
  data () {
    return {
        showTabbar:false,
        showSign:false,//签到
        isSign:false,//是否签到
        weekDays:[],//签到几天
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    tabChange(active) {
    //   let p = {
    //     sourceType: "VIEW",
    //     viewId: active,
    //     viewType: "ButtonClick",
    //   };
    //   log(http_post_params(p)).then(() => { });
    //   this.showBadgeFn();
        
        var res = window.sessionStorage.getItem('siteUser')
        this.showTabbar = false
        if(res){
          const router = this.$router;
          router.push({
              path: active,
          });
          if(active == '/home/index'){
              this.$addLog({sourceType:'VIEW',viewId:'',viewType:'meeting_mode'})
          }
          if(active == '/home/science'){
              this.$addLog({sourceType:'VIEW',viewId:'',viewType:'content_mode'})
          }
          if(active == '/home/tools'){
              this.$addLog({sourceType:'VIEW',viewId:'',viewType:'tool_mode'})
          }
          if(active == '/home/mine'){
              this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_mode'})
          }
        } else {
          //去注册页
          this.$router.push({
              path: '/register',
          })

        }
    },

    //是否签到
    getSignInStatus(){
      isSignIn().then(res=>{
        if(res.code == 0){
          if(res.data.status != 'Y'){
            this.signInForWeeks()
            this.showSign = true
            this.isSign = res.data.status
          }
        }
      })
    },
    //获取七日签到日期
    signInForWeeks(){
        getSignInForWeeks().then(res=>{
            if(res.code == 0){
                this.weekDays = res.data;
            }
        })
    },

    //签到
    sign(){
      if(this.isSign == 'Y'){
            return
        }
      this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
      });
      signIn().then(res=>{
          if(res.code == 0){
              this.$toast('签到成功')
              this.isSign = 'Y'
              this.signInForWeeks()
              this.$addLog({sourceType:'VIEW',viewId:'',viewType:'signInPoint'})
          } else {
              this.$toast(res.message)
          }
      }).catch(error=>{
          this.$toast.clear()
      })
    },
  },
  mounted () { // 页面进入时加载内容
    var res = window.sessionStorage.getItem('siteUser')
    if(res){
      this.getSignInStatus()
    }
  },
  watch: { // 监测变化

  }
}
</script>
<style scoped lang='less'>
.home-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .tab-page {
    &.cant-tab-page {
        // height: 100%;
        // height: calc(100% - 50px);
    }
    }

    .tab-bar {
        height: 120px;
        ::v-deep .van-tabbar-item{
            justify-content: end;
        }
        .home-icon {
            width: 54px;
            height: 54px;
            // margin: 0 auto;
        }

        .text {
            font-size: 25px;
            font-family: STHeiti;
            font-weight: 400;
            color: #666666;
            margin-top: 10px;
        }

        .sel-txt {
            color: #0095f9;
        }

        /deep/ .van-tabbar-item__icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            .home-icon{
                height: auto !important;
            }
        }
    }
    /deep/.tab-bar{
        overflow:hidden;
        border-radius: 12px 12px 0 0 !important;
    }
    /deep/.van-hairline--top-bottom::after{
        border-width: 0 !important;
    }
    /deep/.van-popup{
        overflow-y: initial !important;
        border-radius: 12px 12px 0 0 !important;
        .van-icon{
            top:-30px !important;
            right: 5px !important;
        }
        .van-icon-cross::before{
            content: '' !important;
            background-image: url(../../static/tabbar/close.png) !important;
            background-size: 100% 100%;
            width: 24px;
            height: 24px;
        }
    }

    .tabFloat{
        position: fixed;
        width: 70px;
        height: 70px;
        right: 22px;
        bottom: 230px;
        border-radius: 50%;
        margin: 0;
        padding: 0;
        z-index: 999;
        >img{
            width: 100%;
            height: 100%;
        }
    }
}

.sign1{
  display: flex;
  align-items: center;
  flex-direction: column;
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 28px;
    width: 710px;
    padding: 40px 0;
    .top{
      width: 400px;
      height:auto;
    }
    .desc{
      font-size: 28px;
      font-weight: 500;
      // color: #333333;
      color: black;
      margin-top: 32px;
      .signDay{
        color: #FDC72F;
        font-size: 40px;
        font-weight: bold;
        margin: 0 5px;
      }
    }
    .days{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-top: 30px;
      width: 90%;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 26px;
        width: 12.8%;
        .top{
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #EEF2F5;
          border-radius: 8px;
          padding: 10px 0px;
          // height: 124px;
          width: 100%;
          .day{
            font-size: 16px;
            font-weight: 400;
            color: #599DFA;
          }
          .day_ed{
            color: white;
          }
          .sign_img{
            width: 100%;
            height: auto;
            // margin-top: 10px;
          }
          .jf{
            display: flex;
            color: #618EFF;
            font-size: 9px;
            margin-top: -5px;
          }
          .jf_ed{
            color: white;
          }
        }
        .top_ed{
          background: #618EFF;
        }
        .status{
          margin-top: 14px;
          background: #EEF2F5;
          border-radius: 6px;
          padding: 2px 2px;
          font-size: 9px;
          font-weight: 400;
          color: #599DFA;
        }
        .status_ed{
          color: white;
          background: #618EFF;
        }
      }
      .item_ed{
        width:33.3%;
      }
    }
    .stratSign{
      width: 320px;
      height: 56px;
      // background: #618EFF;
      background: linear-gradient(180deg, #5DA8FD 0%, #475FE6 100%);
      border-radius: 18px;
      font-size: 28px;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
  >.close{
    width: 48px;
    height: auto;
    margin-top: 62px;
  }
}

.van-popup{
  background: none;
}
</style>