import {
    get,
    post,
    delet,
    upload
} from './http'

// 获取个人信息 本地测试根据openid 获取用户信息
export let login = (p) => get('/api/open/wechat/login?' + p); //本地临时
// 根据code获取微信用户信息
export let getOauth = (p) => get('/api/open/wechat?' + p);
// 是否医汇用户
export let checkYihuiUser = (p) => get('/api/open/user/checkYihuiUser');
// 注册获取医院
export let getHospital = (p) => get('/api/open/user/getHospital?' + p);
// 注册获取科室
export let getDepartment = (p) => get('/api/open/user/getDepartment?' + p);
// 注册获取职称
export let getOccupation = (p) => get('/api/open/user/getOccupation?' + p);
// 授权注册
export let sqRegister = (p) => post('/api/open/user/registerByTicket?' + p);
// 填写信息注册
export let register = (p,params) => post('/api/open/user/register?' + p,params);
// 更新用户信息
export let updateUser = (p,params) => post('/api/open/user/updateUser?' + p,params);
// 查询用户协议
export let getAgreement = () => get('/api/open/user/getAgreement');
// 发送验证码
export let sendCode = (p) => post('/api/open/user/sendSmsCode?' + p);

// 会议专区banner
export let meetBanner = (p) => get('/api/meet/showbanner?' + p);
// 会议报名
export let meetSign = (p) => get('/api/meet/annualFieldList?' + p);
// 会议回放
export let meetReply = (p) => get('/api/meet/meetreply?' + p);
// 会议资料
export let meetsummarize = (p) => get('/api/meet/meetsummarize?' + p);
// 会议收藏
export let meetCollect = (p) => get('/api/favority/addMeetFavority?' + p);
// 会议收藏取消
export let meetCollectCancle = (p) => get('/api/favority/' + p);
// 会议搜索
export let meetSearch = (p) => get('/api/meet/searchtitle?' + p);


// 医学内容分类
export let articleMenu = (p) => get('/api/article/listArticle');
// 列表
export let articleList = (p) => get('/api/article/articleList?' + p);
// 更多
export let stageList = (p) => get('/api/article/stageList?' + p);
// 课程分类
export let courseMenu = (p) => get('/api/article/listCourse');
// 课程列表
export let courseList = (p) => get('/api/article/list?' + p);
// 课程详情
export let courseDetail = (p) => get('/api/article/course/' + p);
// 课程章节
export let courseDetailList = (p) => get('/api/article/chapter/list?' + p);
// 课程问卷
export let courseQuestion = (p) => get('/api/article/question/' + p);
// 课程问卷提交
export let courseQuestionSubmit = (p) => post('/api/favorites/questionHistory?' + p);
// 课程进度与时间获取
export let courseTimeAndProgress = (p) => get('/api/trackingLog/getProgress?' + p);
// 文章详情
export let articleDetail = (p) => get('/api/article/' + p);
// 文章视频课程的收藏点赞
export let articleLike = (p) => post('/api/favorites/insert?',p);
// 取消点赞
export let articleCancleLike = (p) => get('/api/favorites/like?' + p);
// 取消收藏
export let articleCancleFavor = (p) => get('/api/favorites/favorites?' + p);
// 搜索
export let contentSearch = (p) => get('/api/article/search?' + p);

// 个人中心
// 获取已登录用户信息
export let getUser = (p) => get('/api/open/wechat/getUser');
// 积分商城列表
export let pointMallList = (p) => get('/api/point/shoppingMall/selectMallMerchandiseList');
// 收货地址
export let addressList = (p) => get('/api/point/userAddress/selectProfileAddress');
// 添加收货地址
export let addAddress = (p) => post('/api/point/userAddress/insertProfileAddress?' + p);
// 修改收货地址
export let editAddress = (p) => post('/api/point/userAddress/updateProfileAddress?' + p);
// 删除收货地址
export let deleteAddress = (p) => post('/api/point/userAddress/deleteProfileAddress?' + p);
// 兑换记录
export let shoppingHistory = (p) => get('/api/point/shoppingHistory/selectMallStock');
// 获取下单详情
export let getOrderOk = (p) => post('/api/point/shoppingMall/getOrderOk?' + p);
// 下单
export let submitOrder = (p) => post('/api/point/shoppingMall/getOrderOkay?' + p);

// 我的会议
export let myMeetList = (p) => get('/api/favority/MeetStart?' + p);
// 我的积分记录
export let myPointList = (p) => get('/api/point/manage/listPoint?' + p);
// 我的收藏
export let myFavorList = (p) => get('/api/favorites/list?' + p);
// 我的课程列表
export let myCourseList = (p) => get('/api/favorites/course/list?' + p);
// 签到
export let signIn = (p) => get('/api/point/manage/signIn');
// 是否签到
export let isSignIn = (p) => get('/api/point/manage/isSignIn');
// 获取当前月签到日期
export let getSignInForMonth = (p) => get('/api/point/manage/getSignInForMonth?' + p);
// 获取七日签到
export let getSignInForWeeks = (p) => get('/api/point/manage/getSignInForWeeks');

// 埋点添加
export let addLog = (p) => post('/api/trackingLog/addLog?' + p);

// 分享签名
export let autograph = (p, param) => post('/api/open/wechat/getSignature?' + p, param);