<!--
* @date 2023-07-31 18:05:38
* @version V1.0.0
!-->
<template>
  <div id='index' class='index'>
       <div class="list">
          <div class="item" @click="toolAction('jsq')">
            <img src="@/static/tools/jisuanji.png" alt="">
            <p>BMI计算器</p>
          </div>
          <div class="item">
            <img src="@/static/tools/feipang.png" alt="">
            <p>肥胖风险评估(敬请期待)</p>
          </div>
       </div>
  </div>
</template>

<script>
import {addLog} from "@/request/api"
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'index',
  data () {
    return {

    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    toolAction(value){
      var res = window.sessionStorage.getItem('siteUser')
      if(value == 'jsq'){
        this.$router.push({path:'/reckoner'})
        // if(res){
          this.$addLog({sourceType:'VIEW',viewId:'',viewType:'tool_BMI'})
        // }
        
      }
      // if(res){
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'everyDayToolPoint'})
      // }
    },
  },
  mounted () { // 页面进入时加载内容
    
  },
  watch: { // 监测变化

  }
}
</script>
<style scoped lang='less'>
.index{
  background: #F6F6F8;
  height: 100vh;
  overflow-y: scroll;
}
.list{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .item{
    display: flex;
    align-items: center;
    position: relative;
    >p{
      font-size: 32px;
      font-weight: 600;
      color: #004588;
      position: absolute;
      left: 52px;
    }
    >img{
      width: 100%;
      height: auto;
    }
  }
}
</style>