<!--
* @date 2023-08-07 16:40:55
* @version V1.0.0
!-->
<template>
  <div id='addAddress' class='addAddress'>
       <Header>
          <div class="header-content">
              <div class="header-title"> {{ $route.query.isEdit ? "修改" : "新增" }}收货地址</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
       </Header>

        <div class="container">
            <div class="adr-form">
                <div class="form-item flex-row-b">
                    <span class="lable">收货人</span>
                    <van-field v-model="name" label="" placeholder="名字" />
                </div>
                <div class="form-item flex-row-b">
                    <span class="lable">手机号码</span>
                    <van-field
                        v-model="phone"
                        label=""
                        placeholder="手机号"
                        maxlength="11"
                    />
                </div>
                <div class="form-item flex-row-b">
                    <span class="lable">收货地址</span>
                    <van-field
                        v-model="address"
                        label=""
                        placeholder="省、市、区、街道"
                    />
                </div>
                <div
                    class="delect"
                    v-show="$route.query.isEdit == 'Y'"
                    @click="handleDel"
                    >
                    删除收货地址
                </div>
            </div>
            <div class="add-btn flex-c" @click="hanldleAdd">保存</div>
        </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {addAddress,editAddress,deleteAddress} from "@/request/api"
import { checkMobile,http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'addAddress',
  data () {
    return {
        name: "",
        phone: "",
        address: "",
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    hanldleAdd() {
      if (this.name == "") {
        this.$toast("请填写收货人姓名");
        return;
      }
      if (this.phone == "") {
        this.$toast("请输入手机号码");
        return;
      }
      if (!checkMobile(this.phone)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      if (this.address == "") {
        this.$toast("请填写收货地址");
        return;
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let p = {
        name: this.name,
        phone: this.phone,
        address: this.address,
      };
      if (this.$route.query.isEdit == "Y") {
        p["addressId"] = this.$route.query.addressId;
        console.log(p);
        editAddress(http_post_params(p)).then((res) => {
          if (res.code == 0) {
            this.$toast.clear();
            this.$addLog({sourceType:'VIEW',viewId:this.$route.query.addressId,viewType:'shopping_mall_address_update'})
            this.$router.push({
              path: "/address",
              query: {
                id: this.$route.query.id ? this.$route.query.id : "",
              },
            });
          } else {
            this.$toast.clear();
          }
        });
      } else {
        console.log(p)
        addAddress(http_post_params(p)).then((res) => {
          if (res.code == 0) {
            this.$toast.clear();
            this.$addLog({sourceType:'VIEW',viewId:'',viewType:'shopping_mall_address_add'})
            this.$router.push({
              path: "/address",
              query: {
                id: this.$route.query.id ? this.$route.query.id : "",
              },
            });
          } else {
            this.$toast.clear();
          }
        });
      }
    },
    handleDel() {
      this.$dialog
        .confirm({
          message: "确认删除此收货地址？",
        })
        .then(() => {
          deleteAddress(
            http_post_params({ addressId: this.$route.query.addressId })
          ).then((res) => {
            if (res.code == 0) {
              this.$toast("删除成功");
              this.$addLog({sourceType:'VIEW',viewId:this.$route.query.addressId,viewType:'shopping_mall_address_delete'})
              this.$router.push({
                path: "/address",
                query: {
                  id: this.$route.query.id ? this.$route.query.id : "",
                },
              });
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted () { // 页面进入时加载内容
    if (this.$route.query.isEdit == "Y") {
      this.name = this.$route.query.name;
      this.phone = this.$route.query.phone;
      this.address = this.$route.query.address;
    } else {
      this.name = "";
      this.phone = "";
      this.address = "";
    }
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.addAddress{
    background: #F6F6F8;
    overflow-y: scroll;
    height: 100vh;
}
.container {
  .adr-form {
    width: 90%;
    margin: 40px auto;
    .flex-row-b{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .form-item {
      margin-bottom: 40px;
      .lable {
        width: 18%;
        text-align-last: justify;
        font-size: 27px;
        font-weight: bold;
        color: #000000;
        line-height: 41px;
      }
      .van-cell {
        width: 78%;
        padding: 9px 15px;
        background: #fdfffe;
        line-height: 15px;
        border-radius: 10px;
      }
    }
    .delect {
      font-size: 27px;
      font-weight: 400;
      color: #1890ff;
      line-height: 31px;
    }
  }

  .add-btn {
    width: 36.4%;
    height: 57px;
    line-height: 57px;
    text-align: center;
    margin: 0 auto;
    background: linear-gradient(90deg, #1C8BFF 0%, #004588 100%);
    box-shadow: 3px 1px 5px 0px rgba(70, 70, 70, 0.05);
    border-radius: 15px;
    font-size: 27px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>