/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import Vue from 'vue'
// import { Notify } from 'vant';
import router from '../router/index'

import { clearLoginStatus } from '../utils';

// 环境的切换
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; //打包！！！



// 请求超时时间
axios.defaults.timeout = 30000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        if (window.sessionStorage.getItem('TokenKey')) {
            config.headers['token'] = window.sessionStorage.getItem('TokenKey')
        }
        return config;
    },
    error => {
        console.log('----'+error)
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        console.log(response.data)
        const status = response.status
        if (status === 200) {
            const code = response.data.code
            // console.log('路由信息：',router.currentRoute.meta.homePage)
            // console.log('code:',code)
            if (code == 40101 || code == 40100 || code == 40103) { // 40101 40100是需要重新登录
                clearLoginStatus()
            } else if (code == 400 || code == 403 || code == 406 || code == 500 || code == 50400 || code == 5001 || code == 5002 || code == 5003) {
                if(location.href.indexOf('feipangkexue.novonordiskchina.cn') != -1 ){
                    window.location.href = "/500.html?code=" + response.data.code + "&message=" + response.data.message + '&homePage=' + router.currentRoute.meta.homePage; //正式
                } else if(location.href.indexOf('test.feipangkexue.novonordiskchina.cn') != -1 ){
                    window.location.href = "/500.html?code=" + response.data.code + "&message=" + response.data.message + '&homePage=' + router.currentRoute.meta.homePage; //测试
                } else if(location.href.indexOf('fpkx.roztop.com') != -1 ){
                    window.location.href = "/500.html?code=" + response.data.code + "&message=" + response.data.message + '&homePage=' + router.currentRoute.meta.homePage; //测试
                }
            } else if (code == 404){
                if(location.href.indexOf('feipangkexue.novonordiskchina.cn') != -1 ){
                    window.location.href = "/404.html?code=" + response.data.code + "&message=" + response.data.message; //正式
                } else if(location.href.indexOf('test.feipangkexue.novonordiskchina.cn') != -1 ){
                    window.location.href = "/404.html?code=" + response.data.code + "&message=" + response.data.message; //测试
                } else if(location.href.indexOf('fpkx.roztop.com') != -1 ){
                    window.location.href = "/404.html?code=" + response.data.code + "&message=" + response.data.message; //测试
                }
            }

            return Promise.resolve(response);
            
            // } else if (status === 401 || status === 403) {
            // Notify({ type: 'primary', message:  response.data.msg});
            // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
            // router.replace({
            //     path: '/'
            // });
        } else {
            return Promise.error(response)
            // window.location.href = "/newsite/404.html?code=" + response.data.code + "&message=" + response.data.message;
            // Notify({ type: 'primary', message:  response.data.msg});
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        console.log('=='+error)
        return Promise.reject(error)
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    // console.log(params)
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/** 
 * 上传图片
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function upload(url, params) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(url, params, config)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params) {
    // console.log(params)
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/** 
 * delete方法，对应delete请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function delet(url, params) {
    // console.log(params)
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}