<!--
* @date 2023-08-10 15:15:58
* @version V1.0.0
!-->
<template>
  <div id='exchangeRecord' class='exchangeRecord'>
       <Header>
          <div class="header-content">
              <div class="header-title">兑换记录</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
       </Header>

       <div class="list">
            <div class="order-group" v-for="(item,index) in list" :key="index">
                <div class="top flex-row-a">
                    <img :src="item.image" alt="" />
                    <div class="good">
                        <p class="name">{{ item.mallName }}</p>
                        <p>已使用：{{ item.soldPoint }}积分</p>
                        <p>{{item.soldTime}}</p>
                        <p v-if="item.sn && item.sn.length > 0" class="use" @click="explain(item)">查看使用说明</p>
                    </div>
                </div>
            </div>
            <div v-show="list.length <= 0" class="noData">暂无兑换记录</div>
       </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {shoppingHistory} from "@/request/api"
import {formatDate} from '@/utils/index';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'exchangeRecord',
  data () {
    return {
        list:[]
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    // 虚拟商品使用说明
    explain(row){
      console.log(row.sn);
      this.$router.push({
        path: "/instructions",
        query :{ sn:row.sn}
      });
    },

    getList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      shoppingHistory().then((res) => {
        if(res.code == 0){
          if(res.data && res.data.length > 0){
            // res.data.forEach(item=>{
                // item.soldTime = formatDate(item.soldTime)
            // })
            this.list = res.data
          }
            
        }
        this.$toast.clear();
      });
    },
  },
  mounted () { // 页面进入时加载内容

  },

  activated() {
    this.getList();
  },

  watch: { // 监测变化

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.exchangeRecord{
    background: #F6F6F8;
    height: 100vh;
    overflow-y: scroll;
}
.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    width: 100%;
  .order-group {
    margin-top: 40px;
    background: #fdfffe;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.5);
    border-radius: 16px;
    padding: 30px 38px;
    width: 80%;
    font-size: 24px;
    font-weight: 400;
    color: #777778;
    line-height: 31px;
    display: flex;
    flex-direction: column;
    .top {
      margin: 5px auto;
      display: flex;
      align-items: center;
      width: 100%;
      img {
        width: 254px;
        height: 230px;
        margin-right: 36px;
      }
      .good {
        width: 52%;
        .name {
          font-size: 32px;
          font-weight: bold;
          color: #000000;
          line-height: 41px;
          margin-bottom: 24px;
        }
        >P{
            margin: 0;
            padding: 0;
        }
        >P:nth-child(3){
            margin-top: 10px;
        }
        >P:nth-child(4){
            margin-top: 20px;
            color: red;
        }
      }
    }
    .bot {
        margin-top: 14px;
        .phone{
            margin-left: 40px;
        }
        .address{
            display: flex;
            margin-top: 10px;
        }
    }
  }

  .add-btn {
    padding: 8px 22px;
    margin-top: 30px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #1C8BFF 0%, #004588 100%);
    border-radius: 8px;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
  }
}

.noData {
  font-size: 28px;
  font-weight: 400;
  color: #666666;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -28px 0 0 -84px;
}
</style>