<!--
* @date 2023-08-04 15:42:48
* @version V1.0.0
!-->
<template>
  <div id='myPoints' class='myPoints'>
        <Header>
            <div class="header-content">
                <div class="header-title">我的积分</div>
                <div class="header-back">
                    <div class="back" @click="handleTitleBack">
                        <img class="returnIcon" src="@/static/return.png" alt="" />
                    </div>
                </div>
                <span class="rightBar" @click="integralRule">规则</span>
            </div>
        </Header>

        <van-pull-refresh class="content-list" v-model="refreshing" @refresh="onRefresh">
            <div class="content">
                <van-list class="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMoreData">
                    <div class="list">
                        <div class="top">
                            <img src="@/static/mine/jf_bg.png" alt="">
                            <span>积分明细</span>
                        </div>
                        <div class="l">
                            <div class="item" v-for="(item,index) in contentList" :key="index">
                                <div class="left">
                                    <span>{{item.reason}}</span>
                                    <span>{{item.createTime}}</span>
                                </div>
                                <div class="right">{{item.pointStr}}</div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
        </van-pull-refresh>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
import {myPointList} from '@/request/api'
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'myPoints',
  data () {
    return {
        contentList:[],

        pageNum: 1, //当前页
        pageSize: 20, //每页大小
        totalNum: 0, //总条数
        refreshing: false,
        loading:true,
        finished:false
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },
    //规则
    integralRule(){
      this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_point_rule'})
        this.$router.push({path:'/integralRule'})
    },

    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList();
    },
    loadMoreData(){
      if (this.refreshing) {return}
      this.getList();
    },

    getList(){
      var params = new Object()
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize
      myPointList(http_post_params(params)).then(res=>{
        if(res.code == 0){
          if(this.pageNum == 1){
            this.contentList = res.data
          } else {
            this.contentList = this.contentList.concat(res.data)
          }
          this.refreshing = false
          this.loading = false
          if(this.contentList.length >= res.total){
            this.finished = true
          } else {
            this.finished = false
          }
          this.pageNum += 1;
        }
      })
    },
  },
  mounted () { // 页面进入时加载内容
    this.getList()
    this.$addLog({sourceType:'VIEW',viewId:'',viewType:'userCenter_point_history'})
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.header-content{
    .rightBar{
        position: absolute;
        top: 0;
        right: 32px;
        height: 100%;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: 400;
        color: #2664ED;
    }
}
.myPoints{
    overflow-y: scroll;
    height: 100vh;
    background: #F6F7FB;
}

.content{
    padding: 32px 48px;
    .list{
        display: flex;
        flex-direction: column;
        padding: 42px 0;
        background: white;
        border-radius: 16px;
        .top{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            >img{
                width: 168px;
                height: auto;
            }
            >span{
                position:absolute;
                font-size: 32px;
                font-weight: 400;
                color: #040404;
            }
        }
        .l{
            display: flex;
            flex-direction: column;
            .item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 32px 32px;
                border-bottom: 2px #E5E5E5 solid;
                .left{
                    display: flex;
                    flex-direction: column;
                    span:nth-child(1){
                        font-size: 28px;
                        font-weight: 400;
                        color: #000000;
                        word-break: break-all;
                    }
                    span:nth-child(2){
                        font-size: 24px;
                        font-weight: 400;
                        color: #A1A1A1;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

/deep/.van-pull-refresh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: unset; // 可选，这个视情况添加
  .van-pull-refresh__track {
    flex: 1;
  }
}
</style>