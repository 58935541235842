import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 导入vant
import Vant, { Locale } from 'vant'
import 'vant/lib/index.css';
import zhCN from 'vant/es/locale/lang/zh-CN';
// 移动端适配
import 'lib-flexible'

import './assets/style/index.less'

//分享全局
import {WxShare} from '@/utils/wxShare'
const share = new WxShare();
Vue.prototype.$Share = share

// 埋点全局
import { addLog } from '@/request/api'
import { http_post_params } from './utils'
Vue.prototype.$addLog = function(p){
  return new Promise((resolve, reject) => {
    addLog(http_post_params(p)).then(res=>{
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
  });
}

Vue.config.productionTip = false

Vue.use(Vant)
Locale.use('zh-CN', zhCN)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
