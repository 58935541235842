// import { get, post, put, cut } from '../../request/request'  //  封装的axios请求方法
import {autograph,addLog} from '@/request/api'
import {
    http_post_params
} from "@/utils/index.js";
import wx from 'weixin-js-sdk';	// 使用js-sdk

export class WxShare {

  signMap = new Map();  // 存储地址url认证信息sign

  authApi = '/v1/open/wechat/getSignature';  // 获取签名接口

  // 分享默认参数
  defaultShareConfig = {
    title: '肥胖科学',
    desc: '肥胖科学',
    link: location.href,
    // imgUrl: '../../assets/share.png',
    imgUrl: location.href.split("#")[0] + 'shareIcon.png',
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ'], // 分享给朋友分享到QQ  分享到朋友圈 分享到腾讯微博
  }

  // 分享参数
  wxShareConfig = {}

  // 测试方法
  // log (val) {
  //   console.log('this is share', val)
  //   console.log('defaultShareConfig', this.defaultShareConfig)
  // }

  /**
   * 
   * @param {Object} config 分页配置信息，包括title，desc，等等；
   */
  yioksShare (config = {}) {
    console.log('分享传进来的参数:',config)
    this.wxShareConfig.title = config.title || this.defaultShareConfig.title;
    this.wxShareConfig.desc = config.desc || this.defaultShareConfig.desc;
    this.wxShareConfig.link = config.link || this.defaultShareConfig.link;
    this.wxShareConfig.imgUrl = config.imgUrl || this.defaultShareConfig.imgUrl;
    this.wxShareConfig.jsApiList = config.jsApiList || this.defaultShareConfig;
    this.wxShareConfig.viewId = config.viewId || null;
    this.wxShareConfig.viewType = config.viewType || null;

    const signMsg = this.signMap.has(this.wxShareConfig.link)
    // console.log('signMsg', signMsg)
    // if (signMsg) {
    //   // 注入签名信息， 并调用分享
    //   console.log('调用分享')
    //   this.injectAndShare(signMsg)
    // } else {
      // 当前分享链接不存在签名 = 更新签名信息
      // 获取签名信息
      console.log('调用获取签名接口')
      this.getShareAuth(location.href.split("#")[0])
    // }
  }


  /**
   * 
   * @param {Object} signMsg 接口签名信息
   * @desc jssdk签名权限注入，并添加分享事件监听器
   */
  injectAndShare (signMsg) {

    const that = this;
    // that.wxShareConfig.imgUrl = signMsg.url || this.defaultShareConfig.imgUrl
    console.log('分享初始化：',that.wxShareConfig)
    signMsg.timestamp = signMsg.timestamp;
    // 访问页面和获取页面必须保持一致
    // console.log('url 比对结果',  location.href.split('#')[0] === signMsg.url )
    wx.config({
      debug: false,  // 开发期间启用
      appId: signMsg.appId,
      timestamp: signMsg.timestamp,   // 注意！时间戳这里应该是10位字符串
      nonceStr: signMsg.noncestr,     // 注意驼峰命名
      signature: signMsg.signature,
      jsApiList: [
        'checkJsApi',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
      ],
    })

    wx.ready(function () {
      const { title, desc, link, imgUrl } = that.wxShareConfig;
      console.log('wx.ready', '######')

      // wx.checkJsApi({
      //   jsApiList:  ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ' ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      //   success: function(res) {
      //   // 可用的api值true，不可用为false
      //   // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      //     console.log('#####checkJsApi#####', res)
      //   }
      // });

      wx.checkJsApi({
        jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage','updateAppMessageShareData','updateTimelineShareData'],
          success: function () {
              // console.log(res)
          }
      });

      wx.onMenuShareTimeline({
        title: title, // 分享标题【必填】
        desc:desc,
        link: link, // 分享链接【必填】
        imgUrl: imgUrl, // 分享图标【必填】
        success: function () {
            // 用户确认分享后执行的回调函数
            console.log('分享成功')
            that.log()
        },
        cancel: function () {
            // alert('sb')
            // 用户取消分享后执行的回调函数
        }
      });
      wx.onMenuShareAppMessage({
          title: title, // 分享标题【必填】
          desc: desc, // 分享描述【必填】
          link: link, // 分享链接【必填】
          imgUrl: imgUrl, // 分享图标【必填】
          type: 'link', // 分享类型,music、video或link，不填默认为link【必填】
          dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {
              // 用户确认分享后执行的回调函数
              console.log('分享成功')
              that.log()
          },
          cancel: function () {
              // alert('sb')
              // 用户取消分享后执行的回调函数
          }
      });


    })

    wx.error(function (res) {
      console.log('wx error:', res)
    })

  }

  /**
   * 
   * @param {url} shareUrl 
   * @description 从服务器获取分享页面签名信息
   */
  getShareAuth (shareUrl) {
    console.log('待获取权限的地址', shareUrl)
    autograph(http_post_params({'pageUrl':shareUrl})).then(res => {
        console.log('获取签名信息结果：', res)
        if (res.code != 0) {
          console.error('res.msg', res.message);
          return
        }
        this.signMap.set(shareUrl, res.data)  // 存储信息
        this.injectAndShare(res.data);  // 注入签名信息
      });
  }

  //埋点
  log(){
    if (this.wxShareConfig.viewId && this.wxShareConfig.viewType) {
      let p = {
          sourceType: "SHARE",
          viewId:this.wxShareConfig.viewId,
          viewType:this.wxShareConfig.viewType
      };
      addLog(http_post_params(p)).then((res) => {
          if (res.code == 0) {
              console.log("success");
          }
      });
    } 
  }
}
