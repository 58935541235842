<!--
* @date 2023-08-02 16:51:16
* @version V1.0.0
!-->
<template>
  <div id='searchpage' class='searchpage'>
        <Header>
            <div class="header-content">
                <div class="header-title">会议专区</div>
                <div class="header-back">
                <div class="back" @click="handleTitleBack">
                    <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
                </div>
            </div>
        </Header>

        <van-pull-refresh class="content-list" v-model="refreshing" @refresh="onRefresh">
            <div class="search">
                <div class="bg">
                    <input type="text" v-model="searchVal" placeholder="搜索...">
                    <img @click="onSearch" class="search_img" src="@/static/home/search.png" alt="">
                </div>
            </div>

            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMoreData">
                <div class="group" v-if="contentList.length > 0">
                    <div class="section">
                        <span>会议报名</span>
                    </div>
                    <div class="content-list">
                        <div class="content-item" v-for="(item, index) in contentList[0]" :key="index" :title="item.id"
                            @click="openDetails(item)">
                            <div class="left">
                                <img :src="item.cover" alt="">
                                <div class="status">{{item.typeName}}</div>
                            </div>
                            <div class="right">
                                <div class="title_body">
                                    <span class="title">{{item.title}}</span>
                                </div>
                                <div class="tips">
                                    <span class="tips_item" v-for="(item,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{item}}</span>
                                </div>
                                <div class="bottom">
                                    <span class="name">{{item.speakerName}} {{item.createTime.split(' ')[0]}}</span>
                                    <div class="like" @click.stop="collect(item,contentList[0].key)">
                                        <img v-if="item.favority == 'Y'" src="@/static/home/liked.png" alt="">
                                        <img v-else src="@/static/home/like.png" alt="">
                                        <span class="t">收藏</span>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group" v-if="contentList.length > 0">
                    <div class="section">
                        <span>会议回放</span>
                    </div>
                    <div class="content-list">
                        <div class="content-item" v-for="(item, index) in contentList[1]" :key="index" :title="item.id"
                            @click="openDetails(item)">
                            <div class="left">
                                <img :src="item.cover" alt="">
                                <!-- <div class="status">{{item.typeName}}</div> -->
                            </div>
                            <div class="right">
                                <div class="title_body">
                                    <span class="title">{{item.title}}</span>
                                </div>
                                <div class="tips">
                                    <span class="tips_item" v-for="(item,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{item}}</span>
                                </div>
                                <div class="bottom">
                                    <span class="name">{{item.speakerName}} {{item.createTime.split(' ')[0]}}</span>
                                    <div class="like" @click.stop="collect(item,contentList[1].key)">
                                        <img v-if="item.favority == 'Y'" src="@/static/home/liked.png" alt="">
                                        <img v-else src="@/static/home/like.png" alt="">
                                        <span class="t">收藏</span>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group" v-if="contentList.length > 0">
                    <div class="section">
                        <span>会议资料</span>
                    </div>
                    <div class="content-list">
                        <div class="content-item" v-for="(item, index) in contentList[2]" :key="index" :title="item.id"
                            @click="openDetails(item)">
                            <div class="left">
                                <img :src="item.cover" alt="">
                                <div v-if="item.typeName == 'ARTICLE'" class="status status_h">文章</div>
                                <div v-if="item.typeName == 'VIDEO'" class="status">视频</div>
                            </div>
                            <div class="right">
                                <div class="title_body">
                                    <span class="title"><span v-if="item.newStatus == 'Y'" class="new">NEW</span>{{item.title}}</span>
                                </div>
                                <div class="tips">
                                    <span class="tips_item" v-for="(item,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{item}}</span>
                                </div>
                                <div class="bottom2">
                                    <div class="time">
                                    <img src="@/static/home/time.png" alt="">
                                    <span>{{item.createTime.split(' ')[0]}}</span>
                                    </div>
                                    <div class="time" style="margin-left:20px">
                                    <img src="@/static/home/liulan.png" alt="">
                                    <span>{{item.touristVolume || 0}}浏览</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
import {meetSearch,meetCollect,meetCollectCancle} from '@/request/api'
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'searchpage',
  data () {
    return {
        searchVal:'',
        contentList:[],

        pageNum: 1, //当前页
        pageSize: 20, //每页大小
        totalNum: 0, //总条数
        refreshing: false,
        loading:true,
        finished:false,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    //搜索
    onSearch(){
        if (!this.searchVal) {
            this.$toast("请输入搜索关键词");
            return;
        }
        this.pageNum = 1;
        this.finished = false;
        this.loading = true;
        this.getList();
    },

    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList();
    },
    loadMoreData(){
      if (this.refreshing) {return}
      this.getList();
    },

    getList(){
        this.$addLog({sourceType:'VIEW',viewType:'meeting_select',content:this.searchVal})
        var params = new Object()
        params.pageNum = this.pageNum
        params.pageSize = this.pageSize
        params.title = this.searchVal
        meetSearch(http_post_params(params)).then(res=>{
            if(res.code == 0){
                var list1 = []
                var list2 = []
                var list3 = []
                res.data.forEach((item,index)=>{
                    if(item.key == 'Y'){
                        list1.push(item)
                    }
                    if(item.key == 'N'){
                        list2.push(item)
                    }
                    if(item.key == 'W'){
                        list3.push(item)
                    }
                })
                if(this.pageNum == 1){
                    this.contentList = [list1,list2,list3]
                } else {
                    this.contentList[0] = this.contentList[0].concat(list1)
                    this.contentList[1] = this.contentList[1].concat(list2)
                    this.contentList[2] = this.contentList[2].concat(list3)
                }
                this.refreshing = false
                this.loading = false
                var num = this.contentList[0].length + this.contentList[1].length + this.contentList[2].length
                if(num >= res.total){
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.pageNum += 1;
            }
        })
    },

    //收藏
    collect(item,type){
      if(item.favority == 'Y'){
        meetCollectCancle(item.id).then(res=>{
          if(res.code == 0){
            this.$toast('取消收藏')
            item.favority = 'N'

            this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_collect_no'})
          }
        })
      } else {
        meetCollect(http_post_params({id:item.id})).then(res=>{
          if(res.code == 0){
            this.$toast('收藏成功')
            item.favority = 'Y'

            this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_collect'})
          }
        })
      }
    },

    // 详情跳转
    openDetails(item){
      if(item.key == 'Y' || item.key == 'N'){
        //报名跟回放
        this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_select_detail'})
        window.location.href = item.toUrl 
      } else if (item.key == 'W'){
        //资料
        if(item.typeName == 'ARTICLE'){
          this.$router.push({
            path:'/article?id=' + item.id
          })
          this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_select_article_detail'})
        }
        if(item.typeName == 'VIDEO'){
          this.$router.push({
            path:'/video?id=' + item.id
          })
          this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'meeting_select_video_detail'})
        }
      }
    },
  },
  mounted () { // 页面进入时加载内容

  },

  activated() {
    this.searchVal = this.$route.query.searchVal;
    this.pageNum = 1;
    this.refreshing = false;
    this.finished = false;
    this.loading = true;
    this.contentList = []
    this.getList()
  },

  watch: { // 监测变化

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.search{
    padding: 12px 36px;
    .bg{
        background: #F6F5FB;
        border-radius: 16px;
        height: 80px;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        >input{
            width: 85%;
            height: 70%;
            border: 0;
            background: none;
        }
        .search_img{
            width: 40px;
            height: auto;
        }
    }
}
.group{
    display: flex;
    flex-direction: column;
    padding: 0 36px;
    .section{
        padding: 30px 0;
        >span{
            font-size: 36px;
            font-weight: 500;
            color: #004588;
        }
    }
    .content-list {
        background: #ffffff;
        // padding: 30px 15px 150px;
        .content-item {
            display: flex;
            padding: 0px 0 0px 0;
            // border-bottom: 2px #E5E5E5 solid;
            margin-bottom: 30px;
            .left{
                display: flex;
                position: relative;
                >img{
                    width: 248px;
                    height: 180px;
                    border-radius: 16px;
                    background: gainsboro;
                }
                .status{
                    position: absolute;
                    background: #1C8BFF;
                    border-radius: 16px 0 16px 0;
                    font-size: 20px;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding: 2px 10px;
                    top: 0px;
                    left: 0px;
                    // transform: scale(0.8);
                }
                .status_h{
                    background: #FDAD4A;
                }
            }
            .right{
                width: 64%;
                padding: 0 30px;
                .title_body{
                    display: flex;
                    vertical-align: baseline;
                }
                .title{
                    font-size: 30px;
                    font-weight: 500;
                    color: #000000;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    line-height: 48px;
                    margin: 0;
                    word-break: break-all;
                    .new{
                    background: linear-gradient(180deg, #F99068 0%, #F7312A 100%);
                    font-size: 14px;
                    font-weight: normal;
                    color: #FFFFFF;
                    padding: 5px 6px;
                    // line-height: 20px;
                    // transform: scale(0.7);
                    margin-right: 10px;
                    // margin-left: -6px;
                    // margin-top: -15px;
                    // width: fit-content;
                    // display: inline-block;
                    vertical-align: top;
                    }
                }
                
                .tips{
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 6px;
                    min-height: 30px;
                    .tips_item{
                        background-color: #B2E0F7;
                        font-size: 16px;
                        font-weight: 500;
                        color: #8D8D8D;
                        border-radius: 6px;
                        padding: 2px 8px;
                        margin-right: 20px;
                        margin-bottom: 10px;
                        // transform: scale(0.8);
                        height: fit-content;
                    }
                }
                .bottom{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 14px;
                    .name{
                    font-size: 24px;
                    font-weight: 500;
                    color: #A1A1A1;
                    }
                    .like{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -20px;
                    >img{
                        width: 30px;
                        height: auto;
                    }
                    .t{
                        font-size: 12px;
                        font-weight: 500;
                        color: #A1A1A1;
                        margin-top: 6px;
                        // transform: scale(0.6);
                    }
                    }
                }
                .bottom2{
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    .time{
                        display: flex;
                        align-items: center;
                        >img{
                            width: 26px;
                            height: auto;
                        }
                        >span{
                            font-size: 24px;
                            font-weight: 400;
                            color: #A1A1A1;
                            margin-left: 6px;
                        }
                    }
                }
            }
        }
        }
}

/deep/.van-pull-refresh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: unset; // 可选，这个视情况添加
  .van-pull-refresh__track {
    flex: 1;
  }
}
</style>