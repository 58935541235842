<template>
  <div class="calendar-card">
    <div class="calendar-bar">
      <!-- <button class="button button-year-minus" @click="minusYear(true)">
        &lt;
      </button>
      <button class="button button-month-minus" @click="minusMonth">
        &lt;
      </button> -->
      <img class="upnext" src="@/static/mine/up.png" alt="" @click="minusMonth">
      <div class="calendar-date">
        {{ date.year }}-{{ date.month < 9 ? "0" + (date.month + 1) : date.month + 1}}-{{ date.date }}
      </div>
      <!-- <button class="button button-month-plus" @click="plusMonth">&gt;</button>
      <button class="button button-year-plus" @click="plusYear(true)">
        &gt;
      </button> -->
      <img class="upnext" src="@/static/mine/next.png" alt="" @click="plusMonth">
    </div>
    <div class="calendar-content">
      <div class="ul-week">
        <span class="li-week" v-for="(item,index) in week" :key="index">{{ item }}</span>
      </div>
      <div class="ul-day">
        <span
          class="li-day"
          v-for="(item,index) in days"
          :key="index"
          :isThisMonth="item.isThisMonth"
          @click="handleClick(item)"
        >
          {{ item.date }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"SimpleCalendar",
  props:{
    selectDays:[],
  },
  data() {
    return {
      date: {
        year: "",
        month: "",
        date: "",
      },
      current: {
        year: "",
        month: "",
        date: "",
      },
      week: ["日", "一", "二", "三", "四", "五", "六"],
      days: [],
    };
  },
  methods: {
    handleClick: function (item) {
      console.log(item.year + "-" + item.month + "-" + item.date);
      // alert(item.year + "-" + item.month + "-" + item.date);
    },
    plusMonth: function () {
      if (this.date.month == 11) {
        this.date.month = 0;
        this.plusYear(false);
      } else {
        this.date.month++;
      }
      this.createCalendar(this.date.year, this.date.month);
      this.$emit('nextMoth', this.date.year + "-" + (this.date.month < 9 ? "0" + (this.date.month + 1) : (this.date.month + 1)))
      // this.signSelectDate()
    },
    minusMonth: function () {
      if (this.date.month == 0) {
        this.date.month = 11;
        this.minusYear(false);
      } else {
        this.date.month--;
      }
      this.createCalendar(this.date.year, this.date.month);
      this.$emit('upMoth', this.date.year + "-" + (this.date.month < 9 ? "0" + (this.date.month + 1) : (this.date.month + 1)))
      // this.signSelectDate()
    },
    plusYear: function (create) {
      if (this.date.year == 2049) {
        this.date.year = 1970;
      } else {
        this.date.year++;
      }
      if (create) {
        this.createCalendar(this.date.year, this.date.month);
      }
    },
    minusYear: function (create) {
      if (this.date.year == 1970) {
        this.date.year = 2049;
      } else {
        this.date.year--;
      }
      if (create) {
        this.createCalendar(this.date.year, this.date.month);
      }
    },
    createCalendar: function (year, month) {
      let d = new Date();
      d.setFullYear(year);
      d.setMonth(month);
      d.setDate(1);
      let dayOfFirstDay = d.getDay();
      this.days = [];

      for (let i = 0; i < 42; i++) {
        d.setDate(1);
        d.setMonth(month);
        d.setDate(i - dayOfFirstDay + 1);

        let isThisMonth = 1;
        if (d.getMonth() == month) {
          isThisMonth = 2;
        } else {
          isThisMonth = 1;
        }

        // var signDays = [3,4,5,6,8,18,31,20,30]
        
        // const isExist = signDays.includes(d.getDate());
        // // if (
        //   // this.current.date == d.getDate() &&
        //   // this.current.month == d.getMonth() &&
        //   // this.current.year == d.getFullYear() &&
        // // ) {
        // if (
        //   this.date.month == this.current.month &&
        //   isExist
        // ){
        //   isThisMonth = 3;
        //   let date = {
        //     year: year,
        //     month: month,
        //     date: d.getDate(),
        //     isThisMonth: isThisMonth,
        //   };
        //   this.days.push(date);
        // } else {
          let date = {
            year: d.getFullYear(),
            month: d.getMonth(),
            date: d.getDate(),
            isThisMonth: isThisMonth,
          };

          this.days.push(date);
        // }
      }
    },

    //选中签到日期
    signSelectDate(){
      var newDays = []
      this.days.forEach((item,index) =>{
        if(this.selectDays.length > 0){
          if(item.isThisMonth == 2){
            const isExist = this.selectDays.includes(item.date);
            if(isExist){
              item.isThisMonth = 3
            }
          }
        } else {
          if(item.isThisMonth == 3){
            item.isThisMonth = 2
          }
        }
        
        newDays.push(item)
      })
      this.newDays = newDays
    },
  },

  watch:{
    selectDays:function(newValue,oldValue){
      this.signSelectDate()
    }
  },

  created() {
    let d = new Date();
    this.date.year = d.getFullYear();
    this.date.month = d.getMonth();
    this.date.date = d.getDate();

    this.current.year = d.getFullYear();
    this.current.month = d.getMonth();
    this.current.date = d.getDate();

    this.createCalendar(this.current.year, this.current.month);
  },
};
</script>

<style scoped>
body {
  background-color: #ddd;
}

.calendar-card {
  /* position: absolute; */
  width: 600px;
  /* height: 400px; */
  padding: 5px 5px 0px 5px;
  /* border: 1px solid #ebeef5; */
  border-radius: 10px;
  background-color: #fff;
  /* background: rgb(213, 213, 214); */
}

.calendar-card:hover {
  /* box-shadow: 0px 0px 5px 5px #cfcfcf; */
}

.calendar-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  height: 70px;
  color: #727272;
  text-align: center;
}

.calendar-date {
  /* width: 150px; */
  color: #7C7C7C;
  font-size: 26px;
  margin-left: 10px;
  margin-right: 10px;
}

.upnext{
  width: 32px;
  height: 32px;
}
.button {
  width: 20px;
  color: #aaa;
  border: 0;
  border-radius: 5px;
}

.button:hover {
  background-color: #eee;
  cursor: pointer;
}

.ul-week {
  display: flex;
  width: 100%;
  font-size: 26px;
  color: #7C7C7C;
  margin-top: 26px;
}

.li-week {
  width: 14.29%;
  text-align: center;
}

.ul-day {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 26px;
  color: #7C7C7C;
  margin-top: 26px;
}

.li-day {
  width: 14.28%;
  font-size: 26px;
  color: #7C7C7C;
  text-align: center;
  padding: 10px 0;
}

.li-day:hover {
  /* background-color: #717de2; */
  /* color: #fff; */
  /* font-size: 15px; */
  /* cursor: pointer; */
}

.li-day[isThisMonth="1"] {
  /* color: rgb(190, 190, 190); */
  color: #C6C6C6;
  /* font-size: 10px; */
}

.li-day[isThisMonth="1"]:hover {
  /* background-color: #717de2; */
  /* color: #fff; */
  /* font-size: 15px; */
  /* cursor: pointer; */
}

.li-day[isThisMonth="3"] {
  border-radius: 5px;
  color: rgb(255, 255, 255);
  /* background-color: #79bbff; */
  color: #FF733A;
  font-weight: 600;
}
</style>