<!--
* @date 2023-08-07 14:40:29
* @version V1.0.0
!-->
<template>
  <div id='videoCourse' class='videoCourse'>
        <Header>
          <div class="header-content">
              <div class="header-title">课程</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
        </Header>

        <div class="container">
            <div class="video-box" v-show="chapterContents">
                <video id="myVideo" :key="chapterContents + 'q'" autoplay controls="controls" controlslist="nodownload"
                x5-playsinline="" x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="" playsinline=""
                onwebkitpresentationmodechanged="play()"
                @pause="videoPaused"
                @ended="videoEnded"
                @play="videoPlaying"
                >
                <source :src="chapterContents" />
                >
                </video>
            </div>
            <div class="video-box" v-show="!chapterContents">
                <video id="blank" controls="controls" controlslist="nodownload" :src="null"></video>
            </div>
            <van-tabs class="chapter-group" v-model="active">
                <van-tab title="简介">
                    <div class="jj">
                        <span>主讲人介绍</span>
                        <div class="doctor-info">
                            <img class="header" v-if="detail.lecturerImg" :src="detail.lecturerImg" alt="" />
                            <img class="header" v-else src="@/static/mine/def_header.png" alt="">
                            <div class="right">
                                <span>{{detail.lecturerName}}</span>
                                <span>{{detail.hospital}} {{detail.position}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="jj" style="margin-top:20px">
                        <span>课程介绍</span>
                        <div class="words-group" v-html="detail.introduce"></div>
                    </div>
                </van-tab>

                <van-tab title="章节">
                    <van-list class="class-list" :immediate-check="true">
                        <van-cell class="class-item" v-for="(item, idx) in classList" :key="idx + 'm'"
                            :class="playIndex == idx ? 'selted-items' : ''" @click="handleSelect(item, idx)">
                            <span></span>
                            <span>第{{ idx + 1 }}节</span>
                            <span :class="lastPlaying == item.id ? 'two-line' : ''">{{
                                item.chapterName
                            }}</span>
                            <span class="last-study" v-show="lastPlaying == item.id">上次学习</span>
                        </van-cell>
                    </van-list>
                </van-tab>
            </van-tabs>
        </div>

        <div class="my-footer">
          <div class="flex-col-a" @click="handleCol('collect')">
            <template>
              <img v-if="!collect" class="star-icon" src="@/static/home/shoucang.png" />
              <img v-else class="star-icon" src="@/static/home/shoucang_s.png" />
            </template>
            <span class="star-text" :style="collect ? 'color:#0095F9;' : ''">收藏</span>
          </div>
          <div class="flex-col-a" @click="handleCol('like')">
            <template>
              <img v-if="!like" class="star-icon" src="@/static/home/dianzan.png" />
              <img v-else class="star-icon" src="@/static/home/dianzan_s.png" />
            </template>
            <span class="star-text leftmove" :style="like ? 'color:#0095F9;' : ''">点赞</span>
          </div>
        </div>

        <!-- 问卷 -->
        <van-popup v-model="showSurvey" round :close-on-click-overlay='false'>
            <div class="survey">
                <div class="top">
                    <img src="@/static/model/video_course_wj.png" alt="">
                    <span>答题获得2积分</span>
                </div>
                <div class="content">
                    <span class="title">{{question.questionTitle}}</span>
                    <div class="question">
                        <div class="item" v-for="(item,index) in optionList" :key="index" @click="quOption(index)">
                            <img v-if="!item.isSelect" src="@/static/model/xuanzhong.png" alt="">
                            <img v-else src="@/static/model/xuanzhong_d.png" alt="">
                            <span>{{item.optionTitle}}</span>
                        </div>
                    </div>
                    <div class="submit" @click="questionSubmit">提交</div>
                </div>
                <img class="close" src="@/static/model/close.png" alt="" @click="showSurvey = false">
            </div>
        </van-popup>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {
  courseDetail,
  courseDetailList,
  articleLike,
  articleCancleLike,
  articleCancleFavor,
  courseQuestion,
  courseQuestionSubmit,
  addLog,
  courseTimeAndProgress
} from '@/request/api'
import {http_post_params} from '@/utils/index'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'videoCourse',
  data () {
    return {
        collect:false,//收藏
        like:false,//点赞
        showSurvey:false,//问卷
        lastPlaying: false,

        active: 0,
        chapterContents: "", //视频url

        detail:{},
        classList: [],
        playIndex:0,

        question:{},//问卷
        optionList:[],

        video: null,
        currentTime:0,//当前播放时间
        totalTiem:0,//总时长
        intervalId:null,
        isPlay:false,

        isButtonDisabled: false,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        this.saveProgress1()
        this.clear()
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    //收藏点赞
    handleCol(value){
      if(this.isButtonDisabled){return}
      this.isButtonDisabled = true;
      if(value == 'collect'){
        if(this.collect){
          articleCancleFavor(http_post_params({contentId:this.$route.query.id})).then(res=>{
            if(res.code == 0){
              this.collect = !this.collect
              this.$toast('取消收藏')

              if(this.$route.query.logType == 'content'){
                this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'course_collect_no'})
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        } else {
          var params = new Object()
          params.contentId = this.$route.query.id
          params.type = 'FAVORITIES'
          params.contentType = 'COURSE'
          params.title = this.detail.courseName
          articleLike(params).then(res=>{
            if(res.code == 0){
              this.collect = !this.collect
              this.$toast('收藏成功')

              if(this.$route.query.logType == 'content'){
                this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'course_collect'})
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        }

      }
      if(value == 'like'){
        if(this.like){
          articleCancleLike(http_post_params({contentId:this.$route.query.id})).then(res=>{
            if(res.code == 0){
              this.like = !this.like
              this.$toast('取消点赞')

              if(this.$route.query && this.$route.query.logType){
                if(this.$route.query.logType == 'content'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'course_like_no'})
                }
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        } else {
          var params = new Object()
          params.contentId = this.$route.query.id
          params.type = 'LIKE'
          params.contentType = 'COURSE'
          params.title = this.detail.courseName
          articleLike(params).then(res=>{
            if(res.code == 0){
              this.like = !this.like
              this.$toast('点赞成功')

              if(this.$route.query && this.$route.query.logType){
                if(this.$route.query.logType == 'content'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'course_like'})
                }
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        }

      }
    },

    //详情数据
    getDetail(){
      courseDetail(this.$route.query.id).then(res=>{
        if(res.code == 0){
          if(res.data.introduce){
            res.data.introduce = res.data.introduce.replace(/\<img/gi, '<img style="width:100%;height:auto;"');
          }
          this.detail = res.data

          if(this.detail.likeFavority == 'FAVORITIES'){
            this.collect = true
            this.like = false
          } else if(this.detail.likeFavority == 'LIKE'){
            this.collect = false
            this.like = true
          } else if(this.detail.likeFavority == 'ALL'){
            this.collect = true
            this.like = true
          } else {
            this.collect = false
            this.like = false
          }

          // 分享
          const SHARE_CONFIG = {
            title: "肥胖科学",
            desc: this.detail.courseName || "肥胖科学",
            imgUrl:this.detail.courseImg,
            viewId:this.detail.id,
            viewType:'share_course',
          };
          this.$Share.yioksShare(SHARE_CONFIG)
        }
      })
    },

    //课程章节
    getList(){
      var params = new Object()
      params.courseId = this.$route.query.id
      params.pageNum = 1
      params.pageSize = 9999
      courseDetailList(http_post_params(params)).then(res=>{
        if(res.code == 0){
          this.classList = res.data 
          this.getPlayProgress()
        }
      })
    },

    //获取进度
    getPlayProgress(){
      var params = new Object()
      params.viewType = 'courseVideoPoint'
      params.viewId = this.$route.query.id
      courseTimeAndProgress(http_post_params(params)).then(res=>{
        if(res.code == 0){
          if(res.data){
            if(this.classList.length > 0){
              this.classList.forEach((item,index)=>{
                if(item.id == res.data.viewId){
                  this.playIndex = index
                  this.chapterContents = this.classList[this.playIndex].chapterContents
                  this.$addLog({sourceType:'VIEW',viewId:this.classList[this.playIndex].id,viewType:'course_chapter_detail'})
                }
              })
            }
          } else {
            //无进度
            if(this.classList.length > 0){
              this.playIndex = 0
              this.chapterContents = this.classList[this.playIndex].chapterContents
              this.$addLog({sourceType:'VIEW',viewId:this.classList[this.playIndex].id,viewType:'course_chapter_detail'})
            }
          }
          // this.saveProgress()
          this.clear()
          this.initInter()
        }
      })
    },

    //进度存储
    saveProgress(){
      if(!this.isPlay){return}
      if(!this.classList || this.classList.length <= 0){return}
      var totalTiem = document.getElementById("myVideo")
        ? document.getElementById("myVideo").duration : 0
      var currentTime = document.getElementById("myVideo") && document.getElementById("myVideo").currentTime;
      console.log(totalTiem,currentTime)
      let p = {
        sourceType: "VIEW",
        viewId: this.classList[this.playIndex].id || '',
        viewType: "courseVideoPoint",
        readTime:parseInt(currentTime),//当前章节已学习时长
        totalTime:parseInt(totalTiem),//当前章节视频总时长
      };

      this.$addLog(p)
    },
    saveProgress1(){
      if(!this.classList || this.classList.length <= 0){return}
      var totalTiem = document.getElementById("myVideo")
        ? document.getElementById("myVideo").duration : 0
      var currentTime = document.getElementById("myVideo") && document.getElementById("myVideo").currentTime;
      console.log(totalTiem,currentTime)
      let p = {
        sourceType: "VIEW",
        viewId: this.classList[this.playIndex].id || '',
        viewType: "courseVideoPoint",
        readTime:parseInt(currentTime || 0),//当前章节已学习时长
        totalTime:parseInt(totalTiem || 0),//当前章节视频总时长
      };

      this.$addLog(p)
    },
    //初始化定时器
    initInter() {
      if (this.intervalId != null) {
        return;
      }
      this.intervalId = setInterval(() => {
        this.saveProgress(); //记录学习进度
      }, 10000);
    },
    clear() {
      console.log('清除')
      if (this.intervalId != null) {
        clearInterval(this.intervalId); //清除计时器
        this.intervalId = null; //设置为null
      }
    },

    //点击切换视频
    handleSelect(item, idx) {
      this.clear()
      this.playIndex = idx;
      this.chapterContents = item.chapterContents;
      this.$addLog({sourceType:'VIEW',viewId:this.classList[this.playIndex].id,viewType:'course_chapter_detail'})
      setTimeout(() => {
        this.video.play();
      }, 10);
      setTimeout(() => {
        this.saveProgress()
        this.initInter()
      }, 200);
    },

    //视频播放暂停
    videoPaused(){
      console.log('暂停')
      this.isPlay = false
    },
    //播放
    videoPlaying(){
      console.log('播放')
      this.isPlay = true
    },
    //播放结束
    videoEnded(){
      console.log('结束')
      this.saveProgress1()
      setTimeout(() => {
        this.isPlay = false
      }, 1000);
      if(this.playIndex == this.classList.length - 1){
        this.getCourseQuestion()
      }
    },

    //获取问卷数据
    getCourseQuestion(){
      courseQuestion(this.$route.query.id).then(res=>{
        if(res.code == 0){
          this.question = res.data.question
          res.data.option.forEach((item)=>{
            item.isSelect = false
          })
          this.optionList = res.data.option
          if(this.optionList && this.optionList.length > 0){
            this.showSurvey = true
          }
        }
      })
    },

    //问卷选择
    quOption(index){
      if(this.question.type == 'MULTIPLE'){
        //多选
        this.optionList[index].isSelect = !this.optionList[index].isSelect
      } else {
        //单选
        this.optionList.forEach((item,idx)=>{
          item.isSelect = false
          if(index == idx){
            item.isSelect = true
          }
        })
      }
      
    },

    //问卷提交
    questionSubmit(){
      var selectId = []
      var selectfix = []
      this.optionList.forEach((item,idx)=>{
        if(item.isSelect){
          selectId.push(item.id)
          selectfix.push(item.optionPrefix)
        }
      })
      if(selectId.length > 0){
        var params = new Object()
        params.questionId = this.question.id
        params.courseId = this.question.courseId
        params.optionId = selectId.join(',')
        params.optionPrefix = selectfix.join(',')
        courseQuestionSubmit(http_post_params(params)).then(res=>{
          if(res.code == 0){
            this.$toast('提交成功')
            this.showSurvey = false
            this.$addLog({sourceType:'VIEW',viewId:this.question.id,viewType:'courseQuestionPoint'})
            this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'course_reply_question'})
          }
        })
      } else {
        this.$toast('请选择答案')
      }
    },
  },
  mounted () { // 页面进入时加载内容
    this.clear()
    this.getDetail()
    this.getList()
    // this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'course'})
    this.video = document.getElementById("myVideo")
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.videoCourse{
    height: 100vh;
    overflow-y: scroll;
    background: #F6F6F8;
}
.my-footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  justify-content: space-around;
  .flex-col-a{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .star-text {
    font-size: 26px;
    font-weight: 400;
    color: #999999;
  }

  .leftmove {
    position: relative;
    left: -5px;
  }

  .star-icon {
    width: 60px;
    height: 60px;
  }
}

.container {
//   height: calc(100vh - 100px);
  background: #F6F6F8;
  padding-bottom: 120px;

  .video-box {
    width: 100%;
    background: #1d1d1d;

    video {
      width: 100%;
      height: 420px;
    }
  }

  .chapter-group {
    /deep/ .van-tabs__wrap {
    //   box-shadow: 1px 4px 9px 0px rgba(151, 151, 151, 0.16);
    border-bottom: 1px #E5E5E5 solid;
    }

    /deep/ .van-tab {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000;
    }

    /deep/ .van-tab--active {
      color: #004588;
    }

    /deep/ .van-tabs__line {
      width: 36px;
      height: 3px;
      background: #004588;
      border-radius: 3px;
    }

    /deep/ .van-tabs__content {
      padding: 15px 0;
    }
    .jj{
        padding: 0 32px;
    }

    .section{
        font-size: 32px;
        font-weight: 400;
        color: #000000;
    }

    .doctor-info {
        display: flex;
        justify-content: flex-start;
        // align-items: flex-end;
        margin-bottom: 10px;
        margin-top: 32px;
        .header{
            width: 92px;
            height: 92px;
            border-radius: 50%;
        }
        .right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 8px;
            >span{
                font-size: 28px;
                font-weight: 400;
                color: #000000;
            }
            >span:nth-child(2){
                color: #999999;
                margin-top: 5px;       
            }
        }

    }

    .words-group {
      font-size: 22px;
      font-weight: 400;
      color: #828282;
      line-height: 34px;
      width: 100%;
      margin-top: 20px;
    //   min-height: 300px;
    }

    .class-list {
      background: #fff;

      .van-cell {
        padding: 10px 30px;
      }

      .class-item {
        font-size: 27px;
        font-weight: 400;
        border-bottom: 1px solid #a8a8a8;

        span:nth-child(1) {
          display: inline-block;
          width: 6px;
          height: 28px;
          border-radius: 3px;
          position: relative;
          top: 4px;
          flex-shrink: 0;
        }
        span:nth-child(2){
          flex-shrink: 0;
          flex: 1;
        }

        .van-cell__value {
          display: flex;
          align-items: baseline;
        }

        :nth-child(2) {
          width: 14%;
          margin-right: 24px;
          margin-left: 10px;
        }

        .two-line {
          display: inline-block;
          width: 62%;
        }
      }

      .selted-items {
        font-weight: bold;
        span:nth-child(1){
          background: #0495f7;
        }

        .van-cell__value--alone {
          color: #0495f7;
        }

        .last-study {
          position: absolute;
          right: 0;
        }
      }
    }

  }
}

.van-popup{
  background: none;
    overflow-y: initial !important;
}
.survey{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 628px;
  background: white;
  border-radius: 20px;
  .top{
    width: 352px;
    margin-top: -25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    >img{
        width: 100%;
        height: auto;
    }
    >span{
        font-size: 32px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 44px;
        position: absolute;
    }
  }
  >.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 48px;
    .title{
      font-size: 28px;
      font-weight: 500;
      color: #1C8BFF;
    }
    .question{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item{
            display: flex;
            align-items: center;
            width: 47%;
            margin-top: 20px;
            >img{
                width: 24px;
                height: 24px;
            }
            >span{
                font-size: 28px;
                font-weight: 400;
                color: #000000;
                margin-left: 10px;
            }
        }
    }
    .submit{
        width: 152px;
        height: 44px;
        background: #1C8BFF;
        border-radius: 15px;
        font-size: 28px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        margin-top: 28px;
        text-align: center;
    }
  }
  >.close{
    width: 48px;
    height: auto;
    margin-top: 62px;
    position: absolute;
    right: 0;
    top: -130px;
  } 
}
</style>