<!--
* @date 2023-08-03 15:40:04
* @version V1.0.0
!-->
<template>
  <div id='reckoner' class='reckoner'>
        <Header>
            <div class="header-content">
                <div class="header-title">BMI计算器</div>
                <div class="header-back">
                <div class="back" @click="handleTitleBack">
                    <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
                </div>
            </div>
        </Header>

        <div class="content">
            <div class="top">
                <span>BMI</span>
                <span>{{bmi}}</span>
            </div>
            <div class="toolInput" style="margin-top:20px">
                <span>身高</span>
                <input type="number" v-model="height" min="0" step="1">
                <span>cm</span>
                <div class="line"></div>
            </div>
            <div class="toolInput">
                <span>体重</span>
                <input type="number" v-model="weight" min="0" step="1">
                <span>kg</span>
            </div>
            <div class="strat" @click="compute">计算</div>

            <div class="info">
                <span class="title">中国BMI标准对照表</span>
                <div class="table">
                    <div class="header">
                        <span>BMI值</span>
                        <span>身体状况</span>
                    </div>
                    <div class="table_item">
                        <span>＜18.5</span>
                        <span>体重过轻</span>
                    </div>
                    <div class="table_item">
                        <span>18.5~23.9</span>
                        <span>正常</span>
                    </div>
                    <div class="table_item">
                        <span>24~27.9</span>
                        <span>超重</span>
                    </div>
                    <div class="table_item">
                        <span>≥28</span>
                        <span>肥胖</span>
                    </div>
                </div>
                <span class="bottom">*中华人民共和国卫生行业标准.WS/T 428-2013 成人体重判定标准</span>
            </div>
        </div>
  </div>
</template>

<script>
import Header from "../../../components/header.vue";
export default {
  props: { // 父辈向子辈传参
  },
  name: 'reckoner',
  data () {
    return {
        height:null,
        weight:null,
        bmi:0,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    compute(){
        if(!this.height || !this.weight){
            this.$toast('请输入身高或者体重')
            return
        }
        if (this.height && (this.height <= 0 || this.height > 300)) {
            this.$toast('请输入正确的身高')
            return   
        }
        if (this.weight && (this.weight <= 0 || this.weight > 500)) {
            this.$toast('请输入正确的体重')
            return
        } 

        const heightSquared = (this.height / 100) * (this.height / 100);
        const bmi = this.weight / heightSquared;
        this.bmi = bmi.toFixed(2); // 保留两位小数
    },
  },
  mounted () { // 页面进入时加载内容

  },
  watch: { // 监测变化

  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.reckoner{
    height: 100vh;
    overflow-y: scroll;
    background: #F6F6F8;
}
.content{
    display: flex;
    flex-direction: column;  
    .top{
        display: flex;
        flex-direction: column;
        background: #1C8BFF;
        padding: 60px 32px;
        >span{
            font-size: 48px;
            font-weight: 600;
            color: #FFFFFF;
        }
        >span:nth-child(2){
            margin-top: 5px;
        }
    }
    .toolInput{
        display: flex;
        align-items: center;
        padding: 32px;
        justify-content: space-around;
        position: relative;
        >span{
            font-size: 36px;
            font-weight: 400;
            color: #999999;
        }
        >input{
            margin-left: 64px;
            margin-right: 32px;
            width: 58%;
            height: 70px;
            border: 1px #DCDCDC solid;
            background: #F1F1F1;
            border-radius: 8px;
            padding: 0 20px;
        }
        .line{
            background: #E5E5E5;
            height: 2px;
            width: 90%;
            position: absolute;
            bottom: 0;
        }
    }
    .strat{
        background: #1C8BFF;
        font-size: 32px;
        font-weight: 500;
        color: #FFFFFF;
        border-radius: 48px;
        height: 100px;
        border-radius: 50px;
        width: 88%;
        margin-left: 6%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }
    .info{
        margin-top: 104px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 32px 32px 32px;
        .title{
            font-size: 36px;
            font-weight: 400;
            color: #999999;
        }
        .table{
            display: flex;
            flex-direction: column;
            margin-top: 42px;
            width: 100%;
            .header{
                display: flex;
                align-items: center;
                background: #004588;
                >span{
                    width: 50%;
                    font-size: 28px;
                    font-weight: 400;
                    color: #FFFFFF;
                    height: 60px;
                    text-align: center;
                    line-height: 60px;
                }
            }
            .table_item{
                display: flex;
                background: #F1F1F1;
                width: 100%;
                >span{
                    font-size: 26px;
                    font-weight: 400;
                    color: #999999;
                    border: 1px #DCDCDC solid;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    width: 50%;
                }
            }
        }
        .bottom{
            font-size: 20px;
            font-weight: 400;
            color: #999999;
            padding: 32px 0;
            text-align: center;
        }
    }
}
</style>