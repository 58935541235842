<!--
* @date 2023-07-31 14:40:20
* @version V1.0.0
!-->
<template>
  <div id='index' class='index'>
    <van-pull-refresh class="content-list" v-model="refreshing" @refresh="onRefresh">
      <van-search class="my-search" v-model="searchVal" shape="round" show-action :clearable='false' placeholder="搜索......"
          :left-icon="require('@/static/home/search.png')">
          <template #action>
            <div class="search-btn" @click="onSearch">搜索</div>
          </template>
      </van-search>

      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" @change="onChange">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <img @click="openUrl(item)" :src="item.url" alt="" />
        </van-swipe-item>
      </van-swipe>

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
        <div class="bigList">
          <div class="group">
            <div class="sectionList">
              <div class="item" v-for="(menu,index) in menuList[0]" :key="index" @click="groupMenu1Click(index)">
                <span class="title" :class="fMenuIndex1 == index ? 'title_s':''">{{menu.name}}</span>
                <div v-if="fMenuIndex1 == index" class="line"></div>
              </div>
            </div>
            <div class="sort"  v-if="menuList.length > 0">
              <div class="sortList">
                <div class="item" :class="sMenuIndex1 == index ? 'item_s':''"  v-for="(sonMenu,index) in menuList[0][fMenuIndex1].columnList" :key="index" @click="sonMenu1Click(index)">
                  <span class="title" :class="sMenuIndex1 == index ? 'title_s':''">{{sonMenu.name}}</span>
                </div>
              </div>
              <img v-if="menuList[0][fMenuIndex1].columnList.length > 0" class="more" src="@/static/science/more.png" alt="" @click="more(menuList[0][fMenuIndex1],menuList[0][fMenuIndex1].columnList[sMenuIndex1])">
            </div>
          </div>
          <div class="content">
            <div class="content-item" v-for="(item, index) in list1" :key="index" :title="item.id"
              @click="openDetails(item)">
              <div class="left">
                <img :src="item.cover" alt="">
                <div v-if="item.typeName == 'ARTICLE'" class="status status_h">文章</div>
                <div v-if="item.typeName == 'VIDEO'" class="status">视频</div>
              </div>
              <div class="right">
                <div class="title_body">
                  <span class="title"><span class="new" v-if="item.newStatus == 'Y'">NEW</span>{{item.title}}</span>
                </div>
                <div class="tips">
                  <span class="tips_item" v-for="(som,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{som}}</span>
                </div>
                <div class="bottom2">
                  <div class="time">
                    <img src="@/static/home/time.png" alt="">
                    <span>{{item.createTime.split(' ')[0]}}</span>
                  </div>
                  <div class="time" style="margin-left:20px">
                    <img src="@/static/home/liulan.png" alt="">
                    <span>{{item.touristVolume || 0}}浏览</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bigList">
          <div class="group">
            <div class="sectionList">
              <div class="item" v-for="(menu,index) in menuList[1]" :key="index" @click="groupMenu2Click(index)">
                <span class="title" :class="fMenuIndex2 == index ? 'title_s':''">{{menu.name || ''}}</span>
                <div v-if="fMenuIndex2 == index" class="line"></div>
              </div>
            </div>
            <div class="sort"  v-if="menuList.length > 0">
              <div class="sortList">
                <div class="item" :class="sMenuIndex2 == index ? 'item_s':''"  v-for="(sonMenu,index) in menuList[1][fMenuIndex2].columnList" :key="index" @click="sonMenu2Click(index)">
                  <span class="title" :class="sMenuIndex2 == index ? 'title_s':''">{{sonMenu.name}}</span>
                </div>
              </div>
              <img v-if="menuList[1][fMenuIndex2].columnList.length > 0" class="more" src="@/static/science/more.png" alt="" @click="more(menuList[1][fMenuIndex2],menuList[1][fMenuIndex2].columnList[sMenuIndex2])">
            </div>
          </div>
          <div class="content" v-if="fMenuIndex2 == 0">
            <div class="content-item" v-for="(item, index) in list2" :key="index" :title="item.id"
              @click="openDetails(item)">
              <div class="left">
                <img :src="item.cover" alt="">
                <div v-if="item.typeName == 'ARTICLE'" class="status status_h">文章</div>
                <div v-if="item.typeName == 'VIDEO'" class="status">视频</div>
              </div>
              <div class="right">
                <div class="title_body">
                  <span class="title"><span class="new" v-if="item.newStatus == 'Y'">NEW</span>{{item.title}}</span>
                </div>
                <div class="tips">
                  <span class="tips_item" v-for="(som,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{som}}</span>
                </div>
                <div class="bottom2">
                  <div class="time">
                    <img src="@/static/home/time.png" alt="">
                    <span>{{item.createTime.split(' ')[0]}}</span>
                  </div>
                  <div class="time" style="margin-left:20px">
                    <img src="@/static/home/liulan.png" alt="">
                    <span>{{item.touristVolume || 0}}浏览</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 课程 -->
          <div class="content" v-if="fMenuIndex2 == 1">
            <div class="content-item" v-for="(item, index) in list2" :key="index" :title="item.id"
              @click="openCourseDetails(item)">
              <div class="left">
                <img :src="item.courseImg" alt="">
                <div class="status status_r">课程</div>
              </div>
              <div class="right">
                <div class="title_body">
                  <span class="title"><span class="new" v-if="item.newStatus == 'Y'">NEW</span>{{item.courseName}}</span>
                </div>
                <div class="tips">
                  <span class="tips_item" v-for="(som,index) in item.marksName ? item.marksName.split(',') : 0" :key="index">{{som}}</span>
                </div>
                <div class="bottom2">
                  <div class="time">
                    <!-- <img src="@/static/home/time.png" alt=""> -->
                    <span>{{item.lecturerName}}</span>
                  </div>
                  <div class="look" style="margin-left:20px">
                    <img src="@/static/home/liulan.png" alt="">
                    <span>{{item.touristVolume || 0}}浏览</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      
    </van-pull-refresh>
  </div>
</template>

<script>
import {meetBanner,articleMenu,articleList,courseMenu,courseList} from '@/request/api'
import {http_post_params} from '@/utils/index'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'index',
  data () {
    return {
      searchVal:'',//搜索
      current: 0,//轮播change
      fMenuIndex1:0,
      sMenuIndex1:0,
      fMenuIndex2:0,
      sMenuIndex2:0,

      bannerList:[],//轮播数据
      menuList:[],
      list1:[],
      list2:[],

      pageNum: 1, //当前页
      pageSize: 2, //每页大小
      totalNum: 0, //总条数
      refreshing: false,
      loading:true,
      finished:false
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    //搜索
    onSearch() {
      if (!this.searchVal) {
        this.$toast("请输入搜索关键词");
        return;
      }
      // this.pageNum = 1;
      // this.refreshing = false;
      // this.finished = false;
      // this.loading = true;
      // this.fMenuIndex1 = 0
      // this.fMenuIndex2 = 0
      // this.sMenuIndex1 = 0
      // this.sMenuIndex2 = 0
      // this.getList('g_1')
      // this.getList('g_2')

      // this.$addLog({sourceType:'VIEW',viewType:'content_select',content:this.searchVal})

      this.$router.push({
        path:"/scieneSearchpage",
        query: {
          searchVal: this.searchVal,
        },
      })
    },

    //轮播
    onChange(index) {
      this.current = index;
    },
    //轮播图跳转
    openUrl(item) {
      this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'content_banner'})
      if(item.isSkipType == 'CONTENT'){
        if(!item.baid){return}
        if(item.isChainSelect == 'VIDEO'){
          //视频
          this.$router.push({
            path:'/scienceVideo?id=' + item.baid
          })
        } else if(item.isChainSelect == 'ARTICLE'){
          //文章
          this.$router.push({
            path:'/scienceArticle?id=' + item.baid
          })
        } else if(item.isChainSelect == 'COURSE'){
          //课程
          this.$router.push({
            path:'/videoCourse?id=' + item.baid
          })
        }
      } else if (item.isSkipType == 'LINK'){
        window.location.href = item.isSkipContext
      }
    },
    //分组分类点击
    groupMenu1Click(index){
      this.fMenuIndex1 = index
      this.sMenuIndex1 = 0
      this.getList('g_1')
      console.log(this.menuList[0][index].name)
      this.$addLog({sourceType:'VIEW',viewId:this.menuList[0][index].id,viewType:'content_list_1'})
    },
    sonMenu1Click(index){
      this.sMenuIndex1 = index
      this.getList('g_1')
    },
    groupMenu2Click(index){
      this.fMenuIndex2 = index
      this.sMenuIndex2 = 0
      if(index == 1){
        this.courseList()
        this.$addLog({sourceType:'VIEW',viewId:'',viewType:'course_list_1'})
      } else {
        this.$addLog({sourceType:'VIEW',viewId:this.menuList[1][index].id,viewType:'content_list_1'})
        this.getList('g_2')
      }
    },
    sonMenu2Click(index){
      this.sMenuIndex2 = index
      if(this.fMenuIndex2 == 1){
        this.courseList()
      } else {
        this.getList('g_2')
      }
    },


    //分类更多
    more(fitem,sitem){
      // console.log(title,item.name)
      if(this.fMenuIndex2 == 1){
        this.$router.push({
          path:'/courseMore?title=' + fitem.name + '&columnId=' + sitem.id
        })
      } else {
        this.$router.push({
          path:'/categoryMore?title=' + fitem.name + '&pid=' + fitem.id + '&columnId=' + sitem.id
        })
      }
    },

    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.fMenuIndex1 = 0
      this.fMenuIndex2 = 0
      this.sMenuIndex1 = 0
      this.sMenuIndex2 = 0
      this.getBanner()
      this.getMenu()
    },

    // 分类
    getMenu(){
      articleMenu().then(res=>{
        if(res.code == 0){
          var menu1 = [res.data[0],res.data[1]]
          var menu2 = [res.data[2]]
          courseMenu().then(res=> {
            if(res.code == 0){
              var f = {
                name:"专业课程",
                id:'-1',
                columnList:res.data
              }
              menu2.push(f)
              this.menuList = [menu1,menu2];
              this.getList('g_1')
              this.getList('g_2')
            }
          })
        }
      })
    },

    // 内容数据
    getList(type){
      var params = new Object()
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize
      params.title = this.searchVal
      if(type == 'g_1'){
        params.pid = this.menuList[0][this.fMenuIndex1].id || ''
        params.columnId = this.menuList[0][this.fMenuIndex1].columnList.length > 0 ? this.menuList[0][this.fMenuIndex1].columnList[this.sMenuIndex1].id : ''
      }
      if(type == 'g_2'){
        params.pid = this.menuList[1][this.fMenuIndex2].id || ''
        params.columnId = this.menuList[1][this.fMenuIndex2].columnList.length > 0 ? this.menuList[1][this.fMenuIndex2].columnList[this.sMenuIndex2].id : ''
      }
      articleList(http_post_params(params)).then(res=>{
        if(res.code == 0){
          if(type == 'g_1'){
            this.list1 = res.data
            this.refreshing = false
            this.loading = false
            this.finished = true
          }
          if(type == 'g_2'){
            this.list2 = res.data
            this.refreshing = false
            this.loading = false
            this.finished = true
          }
          this.$addLog({sourceType:'VIEW',viewId:params.columnId,viewType:'content_list_2'})
        }
      })
    },

    //课程数据
    courseList(){
      var params = new Object()
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize
      params.courseName = this.searchVal
      params.columnId = this.menuList[1][this.fMenuIndex2].columnList.length > 0 ? this.menuList[1][this.fMenuIndex2].columnList[this.sMenuIndex2].id : ''
      courseList(http_post_params(params)).then(res=>{
        if(res.code == 0){
            this.list2 = res.data
            this.refreshing = false
            this.loading = false
            this.finished = true
        }
      })

      this.$addLog({sourceType:'VIEW',viewId:params.columnId,viewType:'course_list_2'})
    },

    // banner
    getBanner(){
      var params = new Object()
      params.meetormedicline = 'YXNR'
      meetBanner(http_post_params(params)).then(res=>{
        if(res.code == 0){
          this.bannerList = res.data
        }
      })
    },

    // 文章视频详情
    openDetails(item){
      if(item.typeName == 'ARTICLE'){
        this.$router.push({
          path:'/scienceArticle?id=' + item.id + '&logType=content'
        })
        this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'content_list2_article_detail'})
      }
      if(item.typeName == 'VIDEO'){
        this.$router.push({
          path:'/scienceVideo?id=' + item.id + '&logType=content'
        })
        this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'content_list2_video_detail'})
      }
    },

    // 课程详情
    openCourseDetails(item){
      this.$addLog({sourceType:'VIEW',viewId:item.id,viewType:'course_detail'})
      this.$router.push({
        path:'/videoCourse?id=' + item.id + '&logType=content'
      })
    },
  },
  mounted () { // 页面进入时加载内容
    this.getBanner()
    this.getMenu()
  },
  watch: { // 监测变化

  }
}
</script>
<style scoped lang='less'>
.my-search {
  margin-top: 12px;
  width: 100%;
  padding: 10px 3%;
  position: relative;

  .van-search__content {
    background: #F6F5FB;
    height: 40px;
    border-radius: 8px;
  }

  /deep/.van-cell {
    display: flex;
    align-items: center;
    .van-cell__value{
      .van-field__control{
        width: 80%;
      }
    }
  }

  /deep/.van-field__left-icon {
    // height: 18px;
    padding-right: 6px;
    margin-right: 6px;
    border-right: 2px solid #e4e4e4;
  }

  .van-search__action {
    position: absolute;
    right: 3%;
    padding: 0;
    border-radius: 31px;
  }

  .search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 60px;
    background: #1C8BFF;
    border-radius: 30px;
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 10px;
  }
}
.my-swipe {
  width: 94%;
  height: 300px;
  border-radius: 30px;
  margin: 20px auto;
  background: #F6F5FB;

  /deep/ .van-swipe__indicator {
    width: 14px;
    height: 2px;
    background: #fffeffff;
    border-radius: 1px;
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 180px;
  text-align: center;
  background-color: rgb(224, 224, 224);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bigList{
  display: flex;
  flex-direction: column;
}
.group{
  display: flex;
  flex-direction: column;
  .sectionList{
    display: flex;
    align-items: center;
    overflow-x: scroll;
    border-bottom: 2px #E5E5E5 solid;
    .item{
      padding: 28px 34px;
      position: relative;
      display: flex;
      justify-content: center;
      .title{
        font-size: 36px;
        font-weight: 600;
        color: #000000;
        word-break: keep-all;
      }
      .title_s{
        color: #004588;
      }
      .line{
        position: absolute;
        bottom: 0;
        height: 6px;
        background: #004588;
        width: 70%;
      }
    }
  }
  .sectionList::-webkit-scrollbar{
    display: none;
  }
  .sort{
    display: flex;
    align-items: center;
    padding: 0 34px;
    justify-content: space-between;
    .sortList{
      display: flex;
      align-items: center;
      overflow-x: scroll;
      padding: 16px 0;
      .item{
        padding: 14px 38px;
        position: relative;
        display: flex;
        justify-content: center;
        border-radius: 36px;
        background: #F6F5FB;
        margin-right: 16px;
        .title{
          font-size: 24px;
          font-weight: 600;
          color: #A1A1A1;
          word-break: keep-all;
        }
        .title_s{
          font-size: 28px;
          color: #FFFFFF;
        }
      }
      .item_s{
        background: linear-gradient(180deg, #1C8BFF 0%, #004588 100%);
      }
    }
    .sortList::-webkit-scrollbar{
      display: none;
    }
    .more{
      width: 48px;
      height: 48px;
      margin-left: 5px;
    }
  }
  
  
}

.content {
  width: 94%;
  margin: 10px auto;
}
.content-list {
  background: #ffffff;
  // padding: 30px 15px 150px;
  .content-item {
    display: flex;
    padding: 16px 0 16px 0;
    .left{
      display: flex;
      position: relative;
      >img{
        width: 248px;
        height: 180px;
        border-radius: 16px;
        background: gainsboro;
      }
      .status{
        position: absolute;
        background: #1C8BFF;
        border-radius: 16px 0 16px 0;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 2px 10px;
        top: 0px;
        left: 0px;
        // transform: scale(0.8);
      }
      .status_h{
        background: #FDAD4A;
      }
      .status_r{
        background: #BA1735;
      }
    }
    .right{
      width: 64%;
      padding: 0 30px;
      .title_body{
        display: flex;
        vertical-align: baseline;
      }
      .title{
        font-size: 30px;
        font-weight: 500;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 48px;
        margin: 0;
        word-break: break-all;
        .new{
          background: linear-gradient(180deg, #F99068 0%, #F7312A 100%);
          font-size: 14px;
          font-weight: normal;
          color: #FFFFFF;
          padding: 5px 6px;
          // line-height: 20px;
          // transform: scale(0.7);
          margin-right: 10px;
          // margin-left: -6px;
          // margin-top: -15px;
          // width: fit-content;
          // display: inline-block;
          vertical-align: top;
        }
      }
      
      .tips{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        min-height: 50px;
        .tips_item{
          background-color: #B2E0F7;
          font-size: 16px;
          font-weight: 500;
          color: #8D8D8D;
          border-radius: 6px;
          padding: 2px 8px;
          margin-right: 20px;
          margin-bottom: 10px;
          // transform: scale(0.8);
          height: fit-content;
        }
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .name{
          font-size: 24px;
          font-weight: 500;
          color: #A1A1A1;
        }
        .like{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: -20px;
          >img{
            width: 30px;
            height: auto;
          }
          .t{
            font-size: 12px;
            font-weight: 500;
            color: #A1A1A1;
            margin-top: 6px;
            // transform: scale(0.6);
          }
        }
      }
      .bottom2{
        display: flex;
        align-items: center;
        margin-top: 12px;
        .time{
          display: flex;
          align-items: center;
          >img{
            width: 26px;
            height: auto;
          }
          >span{
            font-size: 24px;
            font-weight: 400;
            color: #A1A1A1;
            margin-left: 6px;
            word-break: break-all;
          }
        }
        .look{
          display: flex;
          align-items: center;
          >img{
            width: 26px;
            height: auto;
          }
          >span{
            font-size: 24px;
            font-weight: 400;
            color: #A1A1A1;
            margin-left: 6px;
            word-break: keep-all;
          }
        }
      }
    }
  }
}

/deep/.van-pull-refresh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: unset; // 可选，这个视情况添加
  .van-pull-refresh__track {
    flex: 1;
  }
}
</style>