import { render, staticRenderFns } from "./reckoner.vue?vue&type=template&id=220b7fec&scoped=true&"
import script from "./reckoner.vue?vue&type=script&lang=js&"
export * from "./reckoner.vue?vue&type=script&lang=js&"
import style0 from "./reckoner.vue?vue&type=style&index=0&id=220b7fec&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220b7fec",
  null
  
)

export default component.exports