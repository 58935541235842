<!--
* @date 2023-08-07 14:28:10
* @version V1.0.0
!-->
<template>
  <div id='video' class='video'>
        <Header>
          <div class="header-content">
              <div class="header-title">详情</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
        </Header>

        <div class="container">
            <span class="title">{{detail.title}}</span>
            <div class="tips">
                <span v-for="(item,index) in detail.marksName ? detail.marksName.split(',') : 0" :key="index">{{item}}</span>
            </div>
            <span class="time">{{detail.createTime}}</span>

            <div v-if="videoUrl" class="video-group">
                <video
                id="videoG"
                :key="videoUrl"
                autoplay
                controls="controls"
                :src="videoUrl"
                controlslist="nodownload"
                x5-playsinline=""
                webkit-playsinline=""
                playsinline=""
                onwebkitpresentationmodechanged="play()"
                @ended="videoEnded"
                ></video>
            </div>
            <p class="view-num">{{detail.touristVolume || 0}} 浏览</p>
        </div>

        <div class="my-footer">
          <div class="flex-col-a" @click="handleCol('collect')">
            <template>
              <img v-if="!collect" class="star-icon" src="@/static/home/shoucang.png" />
              <img v-else class="star-icon" src="@/static/home/shoucang_s.png" />
            </template>
            <span class="star-text" :style="collect ? 'color:#0095F9;' : ''">收藏</span>
          </div>
          <div class="flex-col-a" @click="handleCol('like')">
            <template>
              <img v-if="!like" class="star-icon" src="@/static/home/dianzan.png" />
              <img v-else class="star-icon" src="@/static/home/dianzan_s.png" />
            </template>
            <span class="star-text leftmove" :style="like ? 'color:#0095F9;' : ''">点赞</span>
          </div>
        </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {articleDetail,articleLike,articleCancleLike,articleCancleFavor,addLog}  from '@/request/api'
import { http_post_params } from '@/utils';
export default {
  props: { // 父辈向子辈传参
  },
  name: 'video',
  data () {
    return {
        collect:false,
        like:false,

        detail:{},
        videoUrl: "",

        isButtonDisabled: false,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          this.$router.back();
        }
      });
    },

    //播放完成
    videoEnded(){
      // this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'videoPoint'})
    },

    //收藏点赞
    handleCol(value){
      if(this.isButtonDisabled){return}
      this.isButtonDisabled = true;
      if(value == 'collect'){
        if(this.collect){
          articleCancleFavor(http_post_params({contentId:this.$route.query.id})).then(res=>{
            if(res.code == 0){
              this.collect = !this.collect
              this.$toast('取消收藏')

              if(this.$route.query && this.$route.query.logType){
                if(this.$route.query.logType == 'meeting'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'meeting_collect_no'})
                } else if(this.$route.query.logType == 'content'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'content_collect_no'})
                }
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        } else {
          var params = new Object()
          params.contentId = this.$route.query.id
          params.type = 'FAVORITIES'
          params.contentType = 'VIDEO'
          params.title = this.detail.title
          articleLike(params).then(res=>{
            if(res.code == 0){
              this.collect = !this.collect
              this.$toast('收藏成功')

              if(this.$route.query && this.$route.query.logType){
                if(this.$route.query.logType == 'meeting'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'meeting_collect'})
                } else if(this.$route.query.logType == 'content'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'content_collect'})
                }
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        }
      }
      if(value == 'like'){
        if(this.like){
          articleCancleLike(http_post_params({contentId:this.$route.query.id})).then(res=>{
            if(res.code == 0){
              this.like = !this.like
              this.$toast('取消点赞')

              if(this.$route.query && this.$route.query.logType){
                if(this.$route.query.logType == 'meeting'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'meeting_like_no'})
                } else if(this.$route.query.logType == 'content'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'content_like_no'})
                }
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        } else {
          var params = new Object()
          params.contentId = this.$route.query.id
          params.type = 'LIKE'
          params.contentType = 'VIDEO'
          params.title = this.detail.title
          articleLike(params).then(res=>{
            if(res.code == 0){
              this.like = !this.like
              this.$toast('点赞成功')

              if(this.$route.query && this.$route.query.logType){
                if(this.$route.query.logType == 'meeting'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'meeting_like'})
                } else if(this.$route.query.logType == 'content'){
                  this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'content_like'})
                }
              }
            }
            this.isButtonDisabled = false;
          }).catch(error=>{
            this.isButtonDisabled = false;
          })
        }
      }
    },

    //详情数据
    getDetail(){
      articleDetail(this.$route.query.id).then(res=>{
        if(res.code == 0){
          this.detail = res.data
          this.videoUrl = res.data.toUrl

          if(this.detail.likeFavority == 'FAVORITIES'){
            this.collect = true
            this.like = false
          } else if(this.detail.likeFavority == 'LIKE'){
            this.collect = false
            this.like = true
          } else if(this.detail.likeFavority == 'ALL'){
            this.collect = true
            this.like = true
          } else {
            this.collect = false
            this.like = false
          }

          // 分享
          const SHARE_CONFIG = {
            title: "肥胖科学",
            desc: this.detail.title || "肥胖科学",
            imgUrl:this.detail.cover,
            viewId:this.detail.id,
            viewType:'share_video',
          };
          this.$Share.yioksShare(SHARE_CONFIG)
        }
      })
    },

  },
  mounted () { // 页面进入时加载内容
    this.getDetail()
    this.$addLog({sourceType:'VIEW',viewId:this.$route.query.id,viewType:'videoPoint'})
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.video{
    height: 100vh;
    overflow-y: scroll;
    background: #F6F6F8;
}

.container{
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  .title{
    font-size: 30px;
    font-weight: 500;
    color: #000000;
    line-height: 48px;
    word-break: break-all;
  }
  .tips{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    >span{
      background:rgba(28, 139, 255, 0.1);
      font-size: 20px;
      font-weight: 500;
      color: #004588;
      border-radius: 8px;
      padding: 4px 12px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .time{
    font-size: 24px;
    font-weight: 400;
    color: #A1A1A1;
    margin-top: 24px;
  }
  .zixun-txt{
    margin-top: 24px;
    padding-bottom: 130px;
  }
  .video-group {
    width: 100%;
    height: 350px;
    margin-top: 20px;
    background: #828282;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .article-group {
    margin-top: 26px;
    /deep/ * {
      max-width: 100% !important;
      font-size: 100% !important;
      height: auto;
      > section {
        max-width: 100% !important;
      }
    }
  }
  .view-num {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    line-height: 31px;
    // text-align: right;
    margin: 30px 0;
  }
}
.my-footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  justify-content: space-around;
  .flex-col-a{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .star-text {
    font-size: 26px;
    font-weight: 400;
    color: #999999;
  }

  .leftmove {
    position: relative;
    left: -5px;
  }

  .star-icon {
    width: 60px;
    height: 60px;
  }
}
</style>