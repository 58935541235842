<!--
* @date 2023-08-07 16:23:58
* @version V1.0.0
!-->
<template>
  <div id='address' class='address'>
       <Header>
          <div class="header-content">
              <div class="header-title">收货地址</div>
              <div class="header-back">
                <div class="back" @click="handleTitleBack">
                  <img class="returnIcon" src="@/static/return.png" alt="" />
                </div>
              </div>
          </div>
       </Header>

       <div class="container">
            <div class="adr-list">
                <div class="adr-item" v-for="(item, idx) in adrList" :key="idx + 'l'">
                <div class="top">
                    <span class="addressee" @click="confirmAdr(item)">{{item.name}}</span>
                    <span>{{item.phone}}</span>
                </div>
                <div class="bot flex-row-b">
                    <span @click="confirmAdr(item)">{{item.address}}</span>
                    <img @click="handleEdit(item)" src="@/static/mine/bianji.png" alt="" />
                </div>
                </div>
            </div>
            <div class="add-btn flex-c" @click="hanldleAdd">+添加收件地址</div>
        </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import {addressList} from "@/request/api"

export default {
  props: { // 父辈向子辈传参
  },
  name: 'address',
  data () {
    return {
        adrList:[]
    }   
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    Header
  },
  methods: { // 方法
    handleTitleBack() {
      this.$nextTick(() => {
        if (this.beforeUrl == "/") {
          this.$router.replace({
            path: "/home/index",
          });
        } else {
          // this.$router.back();
          this.$router.replace({
            path:'/integralMall'
          })
        }
      });
    },

    confirmAdr(item) {
      if (!this.$route.query.id) {
        return;
      }
      this.$router.push({
        path: "/confirmOrder",
        query: {
          addressId: item.id,
          id: this.$route.query.id,
        },
      });
    },

    hanldleAdd() {
      this.$router.push({
        path: "/addAddress",
        query: {
          id: this.$route.query.id ? this.$route.query.id : "",
        },
      });
    },

    handleEdit(item){
        this.$router.push({
          path: "/addAddress",
          query: {
            isEdit: "Y",
            addressId: item.id,
            name: item.name,
            phone: item.phone,
            address: item.address,
            id: this.$route.query.id ? this.$route.query.id : "",
          },
      });
    },

    getList(){
      addressList().then(res=>{
        if(res.code == 0){
          this.adrList = res.data
        }
      })
    },
  },
  mounted () { // 页面进入时加载内容

  },

  activated(){
    this.getList()
    this.$addLog({sourceType:'VIEW',viewId:'',viewType:'shopping_mall_address_list'})
  },
  watch: { // 监测变化

  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
}
</script>
<style scoped lang='less'>
.address{
    background: #F6F6F8;
    overflow-y: scroll;
    height: 100vh;
}
.container {
  .adr-list {
    width: 90%;
    margin: 40px auto;

    .adr-item {
      background: #fdfffe;
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.5);
      border-radius: 16px;
      margin-bottom: 40px;
      padding: 26px 35px 18px 54px;
      font-size: 22px;
      font-weight: 400;
      color: #828282;
      line-height: 31px;

      .top {
        .addressee {
          font-size: 28px;
          font-weight: bold;
          color: #000000;
          line-height: 41px;
          margin-right: 16px;
        }
      }

      .bot {
        height: 54px;
        margin-top: 18px;
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        span {
          width: 80%;
          font-size: 24px;
        }

        img {
          width: 38px;
          height: 38px;
        }
      }
    }
  }

  .add-btn {
    width: 36.4%;
    height: 57px;
    margin: 0 auto 120px;
    background: linear-gradient(90deg, #1C8BFF 0%, #004588 100%);
    border-radius: 8px;
    font-size: 27px;
    font-weight: 400;
    color: #ffffff;
    line-height: 57px;
    text-align: center;
  }
}
</style>