<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不会被缓存的视图组件，比如 Aa,Bb！ -->
    </router-view>
  </div>
</template>

<script>

import { WxShare } from "@/utils/wxShare";
const wxShare = new WxShare(); // 注意，这里一定要先实例化才能使用

export default {
  name: 'App',
  components: {

  },
  methods: {
    // 分享方法
    pageShare () {
      const CURRENT_URL_HISTORY = location.href; //注意我的项目使用的history；
      const CURRENT_URL_HASH = location.href.split("#")[0]; // hash模式使用这个

      const SHARE_CONFIG = {
        title: "肥胖科学",
        desc: "肥胖科学",
      };
      wxShare.yioksShare(SHARE_CONFIG); // 调用分享
    },
  },
  mounted () {
    // 页面进入时加载内容
    this.pageShare(); //分享
  },
}
</script>

<style lang="less">
#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.child-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
