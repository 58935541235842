//注册
import Register from '../views/login/register.vue'
//tabbar
import Tabbar from '../views/tabbar/index.vue'
//首页
import Index from '../views/home/index.vue'
//医学内容
import Science from '../views/science/index.vue'
//工具
import Tools from '../views/tools/index.vue'
//我的
import Mine from '../views/mine/index.vue'
//404
import Page404 from '../views/page404/index.vue'

//首页-搜索
import Searchpage from '../views/home/child/searchpage.vue'
//详情-文章
import Article from '../views/home/child/details/article.vue'
//详情-视频
import Video from '../views/home/child/details/video.vue'

//医学内容-更多
import CategoryMore from '../views/science/child/categoryMore.vue'
//课程视频
import VideoCourse from '../views/science/child/course/videoCourse.vue'
//课程更多
import CourseMore from '../views/science/child/courseMore.vue'
//首页-搜索
import ScieneSearchpage from '../views/science/child/searchpage.vue'

//工具—计算器
import Reckoner from '../views/tools/child/reckoner.vue'

//我的-个人信息
import UserInfo from '../views/mine/child/userInfo.vue'
//我的-收藏
import MyCollect from '../views/mine/child/myCollect.vue'
//我的-课程
import MyCourse  from '../views/mine/child/myCourse.vue'
//我的-会议
import MyMeeting from '../views/mine/child/myMeeting.vue'
//我的积分
import MyPoints from '../views/mine/child/myPoints.vue'
//积分规则
import IntegralRule from '../views/mine/child/integralRule.vue'
//签到
import Sign from '../views/mine/child/sign.vue'
//积分商城
import IntegralMall from '../views/mine/child/IntegralMall/index.vue'
//地址
import Address from '../views/mine/child/IntegralMall/address.vue'
//添加地址
import AddAddress from '../views/mine/child/IntegralMall/addAddress.vue'
//兑换记录
import ExchangeRecord from '../views/mine/child/IntegralMall/exchangeRecord.vue'
//确认下单
import ConfirmOrder from '../views/mine/child/IntegralMall/confirmOrder.vue'
//使用说明
import Instructions from '../views/mine/child/IntegralMall/instructions.vue'
//设置
import Set from '../views/mine/child/set.vue'

const routes = [
    {
        path: '/',
        redirect: '/home/index'
    },
    {
        path: '/home',
        meta: {
            requireAuth: true,
            title: '肥胖科学',
        },
        component: Tabbar,
        children: [
            {
                name: 'index',
                path: 'index',
                meta: {
                    requireAuth: true,
                    scrollToTop: true,
                    title: '会议专区',
                    keepAlive: true // 需要缓存
                },
                component: Index
            },
            {
                name: 'science',
                path: 'science',
                meta: {
                    requireAuth: true,
                    scrollToTop: true,
                    title: '医学内容',
                    keepAlive: true // 需要缓存
                },
                component: Science
            },
            {
                name: 'tools',
                path: 'tools',
                meta: {
                    requireAuth: true,
                    scrollToTop: true,
                    title: '实用工具',
                    keepAlive: true // 需要缓存
                },
                component: Tools
            },
            {
                name: 'mine',
                path: 'mine',
                meta: {
                    requireAuth: true,
                    scrollToTop: true,
                    title: '个人中心',
                    keepAlive: true // 需要缓存
                },
                component: Mine
            },
        ]
    },

    {
        path: "/register",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
        },
        component: Register
    },
    {
        path: '*',
        component: Page404 //404页面
    },

    {
        path: "/searchpage",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/index'
        },
        component: Searchpage
    },
    {
        path: "/scieneSearchpage",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/science'
        },
        component: ScieneSearchpage
    },
    {
        path: "/categoryMore",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/science'
        },
        component: CategoryMore
    },
    {
        path: "/reckoner",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/tools'
        },
        component: Reckoner
    },
    {
        path: "/userInfo",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: UserInfo
    },
    {
        path: "/myCollect",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: MyCollect
    },
    {
        path: "/myCourse",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: MyCourse
    },
    {
        path: "/myMeeting",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: MyMeeting
    },
    {
        path: "/myPoints",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: MyPoints
    },
    {
        path: "/integralRule",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: IntegralRule
    },
    {
        path: "/sign",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: Sign
    },
    // 文章 视频 课程
    {
        path: "/article",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/index'
        },
        component: Article
    },
    {
        path: "/video",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/index'
        },
        component: Video
    },
    {
        path: "/scienceArticle",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/science'
        },
        component: Article
    },
    {
        path: "/scienceVideo",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/science'
        },
        component: Video
    },
    {
        path: "/videoCourse",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/science'
        },
        component: VideoCourse
    },
    {
        path: "/integralMall",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: IntegralMall
    },
    {
        path: "/address",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: Address
    },
    {
        path: "/addAddress",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: AddAddress
    },
    {
        path: "/courseMore",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/science'
        },
        component: CourseMore
    },
    {
        path: "/exchangeRecord",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: ExchangeRecord
    },
    {
        path: "/confirmOrder",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: true,
            homePage:'/home/mine'
        },
        component: ConfirmOrder
    },
    {
        path: "/instructions",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: Instructions
    },
    {
        path: "/set",
        meta: {
            requireAuth: true,
            title: '肥胖科学',
            keepAlive: false,
            homePage:'/home/mine'
        },
        component: Set
    },
    
]

export default routes