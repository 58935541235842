<!--
* @date 2023-08-01 10:21:38
* @version V1.0.0
!-->
<template>
  <div id='register' class='register' @click="getFocusFun()">
       <authorization v-if="accessToken.length > 1" :accessToken="accessToken" @authorizationStatus="authorizationStatusFn"></authorization>
       <div class="register" v-else-if="accessToken.length == 1">
            <img class="top_bg" src="@/static/register/register_bg.png" alt="">
            <div class="header">
                <img src="@/static/register/header.png" alt="">
                <div class="desc">
                    <span>Hello! </span>
                    <span>欢迎来到肥胖科学</span>
                </div>
            </div>
            <div class="form-group">
                <div class="form-item" :class="focus == 'name' ? 'click-item' : ''">
                    <span class="title">真实姓名</span>
                    <div class="inputBody">
                        <input @click.stop="getFocusFun('name')" type="text" v-model="name" placeholder="请输入真实姓名" />
                    </div>
                </div>
                <div v-show="logonType == 'doctor'" class="form-item" :class="focus == 'employer' ? 'click-item' : ''">
                    <span class="title">医院</span>
                    <div class="inputBody">
                        <input @focus="onEmployer = true" @keyup="employer = employer.replace(/[, ]/g, '')"
                        @blur="onblurFun('onEmployer')" @input="getSuggest('employer')" @click.stop="getFocusFun('employer')"
                        type="text" v-model="employer" placeholder="请填写医院名称" />
                        <ul class="inputTips" v-show="onEmployer && employerList.length !== 0">
                        <li v-for="(item, index) in employerList" :key="index + 'emp'" @click="SuggestItemClick('employer', item)">
                            {{ item.hosAlias }}</li>
                        </ul>
                    </div>
                </div>
                <div v-show="logonType == 'doctor'" class="form-item" :class="focus == 'administrative' ? 'click-item' : ''">
                    <span class="title">科室</span>
                    <div class="inputBody">
                        <input @focus="onAdministrative = true" @keyup="employer = employer.replace(/[, ]/g, '')"
                        @blur="onblurFun('onAdministrative')" @input="getSuggest('administrative')" @click.stop="getFocusFun('administrative')"
                        type="text" v-model="administrative" placeholder="请填写科室" />
                        <ul class="inputTips" v-show="onAdministrative && administrativeList.length !== 0">
                        <li v-for="(item, index) in administrativeList" :key="index + 'emp'" @click="SuggestItemClick('administrative', item)">
                            {{ item.name }}</li>
                        </ul>
                    </div>
                </div>
                <div v-show="logonType == 'doctor'" class="form-item" :class="focus == 'jobTitle' ? 'click-item' : ''">
                    <span class="title">职称</span>
                    <div class="inputBody">
                        <input @focus="onJobTitle = true" @keyup="employer = employer.replace(/[, ]/g, '')"
                        @blur="onblurFun('onJobTitle')" @input="getSuggest('jobTitle')" @click.stop="getFocusFun('jobTitle')"
                        type="text" v-model="jobTitle" placeholder="请填写职称" />
                        <ul class="inputTips" v-show="onJobTitle && jobTitleList.length !== 0">
                        <li v-for="(item, index) in jobTitleList" :key="index + 'emp'" @click="SuggestItemClick('jobTitle', item)">
                            {{ item.name }}</li>
                        </ul>
                    </div>
                </div>
                <div class="form-item flex-row-b" :class="focus == 'userPhone' ? 'click-item' : ''">
                    <span class="title">联系方式</span>
                    <div class="inputBody">
                        <input @click.stop="getFocusFun('userPhone')" type="text" minlength="11" maxlength="11" v-model="userPhone"
                        placeholder="请输入手机号" />
                    </div>
                </div>
                <div class="form-item flex-row-b" :class="focus == 'smsCode' ? 'click-item' : ''">
                    <span class="title">验证码</span>
                    <input @click.stop="getFocusFun('smsCode')" type="text" v-model="smsCode" placeholder="请输入验证码" minlength="6"
                        maxlength="6" oninput="if(value.length>6) value = value.slice(0,6)" />
                    <span class="codeBtn flex-c" :class="{ 'ableCodeBtn': codeText == '获取', 'phaBut': logonType != 'doctor' }"
                        @click="getECode">{{
                        codeText
                        }}</span>
                </div>

                <div class="btn-group">
                    <p>以上内容需全部填写</p>
                    <p class="agree" @click="getShowSuc">
                        <img v-if="!agreement" class="agreeCheck" src="@/static/register/select.png" alt="">
                        <img v-else class="agreeCheck" src="@/static/register/selected.png" alt="">
                        我已阅读并同意<span style="color: #1C8BFF" @click.stop="openRead('', textStr)">《用户须知》 </span>和
                        <span style="color: #1C8BFF" @click.stop="openRead('', textStr1)">《隐私政策》</span>
                    </p>
                    <button @click="onSubmit" :class="{ 'phaBut': logonType != 'doctor' }">点击注册</button>
                </div>
            </div>
       </div>

      <!-- 成功注册 -->
      <van-popup v-model="showSuccess" :close-on-click-overlay='false'>
          <div class="success">
              <img class="success_img" src="@/static/register/sucess.png" alt="">
              <p class="title">恭喜您，注册成功</p>
              <p class="time">{{successTime}}s</p>
          </div>
      </van-popup>

      <!-- 提示 -->
      <van-popup v-model="showTip" :close-on-click-overlay='false'>
          <div class="tip">
              <img class="tip_img" src="@/static/register/toast_bg.png" alt="">
              <div class="con">
                <span class="t">温馨提示</span>
                <span class="desc">本平台内容仅供医疗卫生专业人士使用
                  您是否为医疗卫生专业人士？</span>
                <div class="tip_bottom">
                  <span @click="tipAction('cancle')">否</span>
                  <span @click="tipAction('ok')">是</span>
                </div>
              </div>
          </div>
      </van-popup>
      <!-- 提示 -->
      <van-popup v-model="showTipCancle" :close-on-click-overlay='false'>
          <div class="tip">
              <img class="tip_img2" src="@/static/register/toast_bg2.png" alt="">
              <div class="con">
                <span class="desc desc2">本平台仅供医疗卫生专业人士使用感谢您的关注！</span>
              </div>
          </div>
      </van-popup>
  </div>
</template>

<script>
import authorization from './authorization.vue'
import {
  checkYihuiUser,
  getHospital,
  getDepartment,
  sqRegister,
  register,
  getOccupation,
  sendCode,
  getAgreement
} from '@/request/api'
import { thorttle,checkMobile,http_post_params } from '@/utils/index.js'
export default {
  props: { // 父辈向子辈传参
  },
  name: 'register',
  data () {
    return {
        focus: "",
        name: "", //真实姓名
        employer: "", //医院名称
        employeeId: "", //医院id
        administrative:'',//科室
        jobTitle: '',//职位名称
        userPhone: "", //联系方式
        smsCode: "", //验证码
        agreement: "",

        codeText: "获取",
        timer: null,
        successTimer:null,

        logonType: 'doctor',//注册类型
        //展示授权页 '':都不展示 正常token 展示授权页 '0' 展示注册页,
        accessToken: '',

        employerList: [],//医院列表
        administrativeList:[],//科室列表
        jobTitleList:[],//职称列表

        onEmployer: false,//医院名称焦点
        onAdministrative:false,//科室焦点
        onJobTitle:false,//职称焦点
        agreement:false,//协议同意
        showSuccess:false,//成功弹框
        successTime:3,
        showTip:true,//提示
        showTipCancle:false,//提示取消

        textStr: `
糖医网用户协议

尊敬的用户：
欢迎您访问此平台。您对本平台的访问行为以及使用本平台提供的任何服务的行为，表明您已同意遵守本使用条款和所有适用的法律法规。本平台提供的所有信息仅作参考之用，目的为帮助您更好地了解糖尿病。

1. 总则
1.1 注册用户同意本协议的全部条款并完成注册程序，才能成为本平台的正式用户。请您仔细阅读本协议，您点击"同意"按钮后即表示完全接受本协议项下的全部条款。
1.2 用户一旦注册，即视为用户确认自己具有享受本站服务的权利能力和行为能力，能够独立承担法律责任。
1.3 在注册和使用本平台时，用户应了解相关法律法规，确知享有的权利和义务；应保证注册时提交的信息合法、真实、准确，并取得相应授权。
2. 用户
2.1 注册用户向本平台提供的注册资料应当是真实、准确、完整、合法有效的，用户注册资料如有变动的，应及时更新。如用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果。
2.2 用户注册成功后，将产生用户名和密码等账户信息。用户可以根据本平台规定变更密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知我们。
2.3 用户应当对以其用户名登录进行的所有活动和事件负法律责任。用户不得将在本平台注册获得的账户转让、出售或出借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
2.4 用户同意，本平台拥有通过电子邮件、短信电话等形式，向注册用户和相关申请人发送和告知相关审核信息与结果的权利。该等通知于发送之日视为已送达收件人。
3. 服务
3.1 本平台服务的具体内容由诺和诺德(中国)制药有限公司（以下简称“诺和诺德”）根据实际情况提供并逐步完善。
3.2 本平台仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。
3.3 本平台需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，诺和诺德无需为此承担任何责任，但应尽可能事先进行通告。
3.4 针对用户在本平台提出的任何信息咨询，本平台基于用户提供的信息所进行的回复，不代表任何处方意见以及临床判断。
3.5 如发生下列任何一种情形，本平台有权随时中断或终止向用户提供本协议项下的服务而无需对用户或任何第三方承担任何责任：
3.4.1 用户提供的个人资料不真实；
3.4.2 用户违反本协议中规定的使用规则；
3.4.3 用户名存在违反法律法规或国家政策要求，或侵犯任何第三方合法权益的情形；
3.4.4 应相关管理机构的要求而暂停或终止。
4. 义务
用户在使用本平台获取服务时，应当遵守以下义务：
4.1 遵守中国有关的法律和法规；
4.2 遵守所有与注册和服务有关的协议、规定和程序；
4.3 不得为任何非法目的而使用网络服务平台；
4.4 不得以任何形式在使用本平台过程中侵犯协会及第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；
4.5 不得利用本平台进行任何可能对互联网正常运转造成不利影响的行为；
4.6 不得利用本平台提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料。 本平台保有删除各类不符合法律政策或不真实的信息内容而无须通知用户的权利。若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户账号、终止服务、追究法律责任等措施。
5. 隐私保护
5.1 用户在使用本平台进行网上注册并享有服务的过程中，涉及用户真实姓名、通信地址、联系电话、电子邮箱等隐私信息的，本平台将严格予以保密。除非属于下述情形，本平台不会向外界披露用户隐私信息：
5.1.1 事先获得用户的明确授权；
5.1.2 根据有关的法律法规要求；
5.1.3 按照相关政府主管部门的要求；
5.1.4 为维护社会公众的利益；
5.1.5 为维护诺和诺德的合法权益。
5.2 诺和诺德可能会与第三方合作向用户提供相关的网络服务，在此情况下，以《个人信息及隐私保护政策》的相关约定为准。
6. 知识产权
6.1 本平台提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护。
6.2 除法律另有强制性规定外，未经相关权利人同意，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容；否则，本平台所有者有权追究其法律责任。
7. 协议修改
7.1 根据国家法律法规变化及平台运行需要，本平台有权对本协议条款不时地进行修改。一旦本协议的内容发生变动，将会直接在本平台上公布修改之后的协议内容，该公布行为视为已经通知用户修改内容。
7.2 如果不同意对本协议相关条款所做的修改，用户有权停止使用服务。如果用户继续使用服务，则视为用户接受本协议修改内容。
8. 特别提示
8.1 点击"同意"按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
        `,

        textStr1:`
最近更新日期：2023年9月
生效日期：2023年9月
提示条款

本文用于诺和诺德（中国）制药有限公司北京分公司（以下简称“诺和诺德”或“我们”）推出的糖医网微信公众号及微网站（以下简称“糖医网”或“本服务“）。本服务的使用人在本政策中称为“用户”，或称为“您”。

我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。

请您在使用本服务前，仔细阅读并充分理解本隐私保护政策协议。我们将逐一说明我们收集的您的个人信息的类型及其对应的用途，以便您了解我们针对某一特定功能或服务所收集的具体个人信息的类别、使用理由及收集方式。当您希望使用某些功能时，仅在您主动填写或主动提供您的敏感个人信息后，我们才会进行收集和处理。未提供这些信息将导致您无法使用相关特定功能，但不影响您正常使用本服务其它功能。需要特别说明的是，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您另行说明的隐私权政策。

您在点击“确认/同意”按钮或勾选同意后，本政策即构成对双方有约束力的法律文件，即表示您同意我们按照本政策收集、使用、处理和存储您的相关个人信息。如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第二章第7条提供的联系方式与我们联系。

一、个人信息处理者
诺和诺德（中国）制药有限公司北京分公司作为个人信息处理者（下称“个人信息处理者”或“我们”），自行或通过我们委托的供应商处理您因使用本服务所提供的个人信息。我们的联系方式如下：
地址：北京市朝阳区广顺南大街8号院利星行中心3号楼5层
电子邮件：DHcommunications@novonordisk.com
电话：010-59615858
本政策会帮助您了解以下内容：
1.我们如何收集和使用您的个人信息
2.我们如何委托处理、共享、转让、公开披露您的个人信息
3.我们如何存储和保护您的个人信息
4.您的权利
5.我们如何保护未成年人的信息
6.本政策的适用性及更新
7.如何联系我们

二、隐私保护政策具体内容
1.我们如何收集和使用您的个人信息
我们将逐一说明我们收集的您的个人信息的类型及其对应的用途，以便您了解我们针对某一特定功能或服务所收集的具体个人信息的类别、使用理由及收集方式。除非按照相关法律法规要求必须收集，拒绝提供这些信息将导致您无法使用相关特定功能，但不影响您正常使用本平台中其它功能。

1)当您关注糖医网微信公众号时，我们会收集你的微信UnionID、微信昵称（可选）和头像（可选），并且收集你关注、取消关注本公众号的行为数据，以便我们识别用户身份。

2)当您在糖医网微网站完成注册时，我们会收集您的身份信息：
a)若您选择“医生”，我们会收集您的姓名、所在地区、医院和手机号；
b)若您选择“药师”，我们会收集您的所在连锁药店名称，所在门店、职称和手机号；
c)如果您已经是诺和医汇的注册用户，您还可以选择授权糖医网从诺和医汇调取您的如下注册信息：姓名、手机号、医院、科室、职称和职务，，点击“同意授权”进行快捷登录；您也可以选择“重新注册”，填写个人信息完成注册。如您选择“重新注册“，我们将不会从诺和医汇调取您的注册信息。具体的信息提供范围以交互页面中您确认的为准。
d)如果您已经注册糖医网子站（药师云学糖、全科讲糖、基层云学糖），您可以选择使用子站的注册信息，作为您在糖医网的注册用户信息。

因为糖医网是面向医疗卫生专业人士开放的专业平台，提供的是医疗专业内容，仅供医疗专业人士阅读及使用，我们需要收集这些注册信息用于确保用户的身份，避免对非医疗卫生专业人士造成误导或推广嫌疑。若您不提供这些信息，将无法使用糖医网提供的各项功能。若您需要更新您的个人信息，您可以通过本政策4.2条说明的方式进行。

我们仅会为上述列明的目的使用您的个人信息。我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。

3)如果您是“医生”用户，当您点击“星火课堂”板块进行视频课程学习时，我们还需要您补充提供您所在科室和职称信息，用于平台进一步了解您关注的专业领域以及推荐您感兴趣的内容。

4)当您进入【糖医会议】板块，点击会议标题，我们有可能将您导航到相关会议内容平台（包括我们的诺和医汇公众号或者第三方提供的蓝色县域E公众号），观看会议，相关平台将需要申请您的授权，获取您的微信头像和昵称信息。若您不同意进行该等授权，将无法查看相关会议内容。相关平台的个人信息收集和使用行为以其公示的隐私政策为准。糖医网不会与第三方会议内容平台分享您的个人信息。

5)如您在【个人中心-积分商城】进行特定商品（如继续教育学习卡）的积分兑换，我们有可能将您导航到第三方网站，借助合作方提供的服务帮助您完成学术用品兑换。我们会向第三方网站提供您在糖医网随机生成的ID号及选择积分兑换的学术用品名称。为完成学术用品兑换，您有可能需要应第三方网站要求，完成注册并向其提供个人信息。第三方平台的个人信息收集和使用行为将与糖医网平台无关。具体细节可参考第三方平台的《隐私条款》及《注册协议》。您在兑换完成后，第三方平台会向糖医网提供您的学术用品兑换状态，帮助我们核销您已使用的学术积分。除此之外，糖医网不会要求第三方网站分享您的其他个人信息。详细信息可参考兑换积分跳转页面的用户知情同意。我们仅会在您授权此跳转之后才会进行以上数据的传递和收集。

6)为了向您提供更契合您需求的页面展示和搜索结果，在您浏览糖医网以及使用我们提供的服务的过程中，您可以选择对感兴趣的内容进行收藏或点赞，还可以通过我们提供的功能组件向其他第三方分享信息。我们会收集您对我们服务的详细使用情况和操作记录作为服务日志保存，包括浏览、订阅、阅读、观看、搜索查询、点赞、收藏、转发、分享，以及IP地址、访问日期和时间以及浏览时长。

个人信息及隐私保护政策

最近更新日期：2023年9月
生效日期：2023年9月
提示条款

本文用于诺和诺德（中国）制药有限公司北京分公司（以下简称“诺和诺德”或“我们”）推出的糖医网微信公众号及微网站（以下简称“糖医网”或“本服务“）。本服务的使用人在本政策中称为“用户”，或称为“您”。

我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。

请您在使用本服务前，仔细阅读并充分理解本隐私保护政策协议。我们将逐一说明我们收集的您的个人信息的类型及其对应的用途，以便您了解我们针对某一特定功能或服务所收集的具体个人信息的类别、使用理由及收集方式。当您希望使用某些功能时，仅在您主动填写或主动提供您的敏感个人信息后，我们才会进行收集和处理。未提供这些信息将导致您无法使用相关特定功能，但不影响您正常使用本服务其它功能。需要特别说明的是，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您另行说明的隐私权政策。

您在点击“确认/同意”按钮或勾选同意后，本政策即构成对双方有约束力的法律文件，即表示您同意我们按照本政策收集、使用、处理和存储您的相关个人信息。如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第二章第7条提供的联系方式与我们联系。

一、个人信息处理者

诺和诺德（中国）制药有限公司北京分公司作为个人信息处理者（下称“个人信息处理者”或“我们”），自行或通过我们委托的供应商处理您因使用本服务所提供的个人信息。我们的联系方式如下：
地址：北京市朝阳区广顺南大街8号院利星行中心3号楼5层
电子邮件：DHcommunications@novonordisk.com
电话：010-59615858
本政策会帮助您了解以下内容：
1.我们如何收集和使用您的个人信息
2.我们如何委托处理、共享、转让、公开披露您的个人信息
3.我们如何存储和保护您的个人信息
4.您的权利
5.我们如何保护未成年人的信息
6.本政策的适用性及更新
7.如何联系我们


二、隐私保护政策具体内容
1.我们如何收集和使用您的个人信息
我们将逐一说明我们收集的您的个人信息的类型及其对应的用途，以便您了解我们针对某一特定功能或服务所收集的具体个人信息的类别、使用理由及收集方式。除非按照相关法律法规要求必须收集，拒绝提供这些信息将导致您无法使用相关特定功能，但不影响您正常使用本平台中其它功能。

1)当您关注糖医网微信公众号时，我们会收集你的微信UnionID、微信昵称（可选）和头像（可选），并且收集你关注、取消关注本公众号的行为数据，以便我们识别用户身份。

2)当您在糖医网微网站完成注册时，我们会收集您的身份信息：
a)若您选择“医生”，我们会收集您的姓名、所在地区、医院和手机号；
b)若您选择“药师”，我们会收集您的所在连锁药店名称，所在门店、职称和手机号；
c)如果您已经是诺和医汇的注册用户，您还可以选择授权糖医网从诺和医汇调取您的如下注册信息：姓名、手机号、医院、科室、职称和职务，，点击“同意授权”进行快捷登录；您也可以选择“重新注册”，填写个人信息完成注册。如您选择“重新注册“，我们将不会从诺和医汇调取您的注册信息。具体的信息提供范围以交互页面中您确认的为准。
d)如果您已经注册糖医网子站（药师云学糖、全科讲糖、基层云学糖），您可以选择使用子站的注册信息，作为您在糖医网的注册用户信息。

因为糖医网是面向医疗卫生专业人士开放的专业平台，提供的是医疗专业内容，仅供医疗专业人士阅读及使用，我们需要收集这些注册信息用于确保用户的身份，避免对非医疗卫生专业人士造成误导或推广嫌疑。若您不提供这些信息，将无法使用糖医网提供的各项功能。若您需要更新您的个人信息，您可以通过本政策4.2条说明的方式进行。

我们仅会为上述列明的目的使用您的个人信息。我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。

3)如果您是“医生”用户，当您点击“星火课堂”板块进行视频课程学习时，我们还需要您补充提供您所在科室和职称信息，用于平台进一步了解您关注的专业领域以及推荐您感兴趣的内容。

4)当您进入【糖医会议】板块，点击会议标题，我们有可能将您导航到相关会议内容平台（包括我们的诺和医汇公众号或者第三方提供的蓝色县域E公众号），观看会议，相关平台将需要申请您的授权，获取您的微信头像和昵称信息。若您不同意进行该等授权，将无法查看相关会议内容。相关平台的个人信息收集和使用行为以其公示的隐私政策为准。糖医网不会与第三方会议内容平台分享您的个人信息。

5)如您在【个人中心-积分商城】进行特定商品（如继续教育学习卡）的积分兑换，我们有可能将您导航到第三方网站，借助合作方提供的服务帮助您完成学术用品兑换。我们会向第三方网站提供您在糖医网随机生成的ID号及选择积分兑换的学术用品名称。为完成学术用品兑换，您有可能需要应第三方网站要求，完成注册并向其提供个人信息。第三方平台的个人信息收集和使用行为将与糖医网平台无关。具体细节可参考第三方平台的《隐私条款》及《注册协议》。您在兑换完成后，第三方平台会向糖医网提供您的学术用品兑换状态，帮助我们核销您已使用的学术积分。除此之外，糖医网不会要求第三方网站分享您的其他个人信息。详细信息可参考兑换积分跳转页面的用户知情同意。我们仅会在您授权此跳转之后才会进行以上数据的传递和收集。

6)为了向您提供更契合您需求的页面展示和搜索结果，在您浏览糖医网以及使用我们提供的服务的过程中，您可以选择对感兴趣的内容进行收藏或点赞，还可以通过我们提供的功能组件向其他第三方分享信息。我们会收集您对我们服务的详细使用情况和操作记录作为服务日志保存，包括浏览、订阅、阅读、观看、搜索查询、点赞、收藏、转发、分享，以及IP地址、访问日期和时间以及浏览时长。

若您同时是我们的诺和医汇公众号/WeCall小程序的注册用户，或者与我们签约提供讲课或其他咨询服务，我们还将依据诺和医汇公众号/WeCall小程序的隐私政策以及相关服务协议条款收集您的相关个人信息，并将这些个人信息与糖医网处理的个人信息进行内部数据整合和分析。我们会使用上述信息，通过算法预测您的偏好特征，并进行个性化内容推荐。

但是，我们不会使用您的任何个人信息进行任何商业推广和推送。

若您不需要个性化内容推荐，可在个人中心-设置-个性化推荐，关闭此功能。

7)若您通过扫描“星火糖医@糖医网”专属代表的二维码并确认同意绑定专属代表，您的专属代表将了解您在糖医网的浏览记录、课程学习记录及参与调研活动的记录，定期与您跟进，为您提供定制化的糖尿病诊疗知识服务。如果您不希望接受定制化的服务，您可以直接告知我们的代表，或者通过邮件与我们联系：DHcommunications@novonordisk.com。

8)其他
a)若您提供的信息中含有其他用户的个人信息，在向糖医网提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策的途径联系我们，要求更正或删除涉及儿童个人信息的内容。
b)若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
c)若未来糖医网的服务需要我们从第三方处间接获取您的信息的，我们会依法核实来源的合法性，或者在获取前向您明示个人信息的来源、类型及使用服务，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，并且要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任。若糖医网需进行的个人信息处理活动超出您原本向第三方提供个人信息时的同意范围，我们将在处理您的该等个人信息前，征得您的明示同意。同时，我们的专业安全团队对个人信息会进行安全加固。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。

9)征得授权同意的例外。您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意：
a)为订立、履行个人作为一方当事人的合同所必需；
b)为履行法定职责或者法定义务所必需；
c)为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
d)在合理范围内处理个人自行公开或者其他已经合法公开的个人信息；
e)法律法规规定的其他情形。

10)请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行匿名化处理，使得数据接收方无法重新识别特定个人且不能复原，用于研究、统计分析和预测，以便改善糖医网的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类匿名化处理后的数据的使用无需另行向您通知并征得您的同意。

11)如我们停止运营糖医网，我们将及时停止继续收集您个人信息的活动，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。

2.我们如何委托处理、共享、转让、公开披露您的个人信息

2.1委托处理
糖医网的部分功能由我们的关联公司诺和诺德（上海）医药贸易有限公司以及外部的服务供应商协助提供。我们会聘请服务提供商来协助我们处理您的个人信息。我们会与服务提供商签署个人信息委托处理协议和保密协定，要求他们按照我们的要求、本个人信息保护政策以及其他任何相关的个人信息保护、保密和信息安全措施来处理您的个人信息，并且对他们的个人信息处理活动进行监督。

2.2共享
我们不会向本网站运营公司以外的任何公司、组织和个人主动提供或转让您的个人信息，但以下情况除外：
a)为了实现诺和医汇的相关功能及服务，我们需要向有关第三方公司提供您的个人信息以及嵌入第三方的SDK。请您查看《第三方信息共享清单》，了解更多关于第三方信息共享以及SDK处理个人信息的目的、方式及范围。
b)在向您进行明确告知并获取您的单独同意的情况下共享：获得您的单独同意后，我们会与我们所披露的有关个人信息处理者共享您的相关个人信息。
c)我们可能会根据法律法规规定，或按政府主管部门的强制性要求，向有关主管部门提供您的个人信息。

2.3转让
我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
1)在向您进行明确告知并获取您的单独同意的情况下转让：获得您的单独同意后，我们会向我们所披露的有关个人信息处理者转让您的相关个人信息；
2)在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会向您告知接收方的名称或者姓名和联系方式。若接收方变更原先的处理目的、处理方式的，我们将要求接收方重新取得您的同意。

2.4公开披露
我们仅会在获得您单独同意后，公开披露您的个人信息。

3.我们如何存储和保护您的个人信息
3.1存储地点
我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中国。目前，我们不会将上述信息传输至其他地区，如果我们向其他地区传输，我们将遵循相关国家规定或者征求您的同意。

3.2存储期限
我们仅在为实现目的所必需的时间及法律、行政法规所规定的保存期限内保留你的个人信息。我们会采取一切合理可行的措施，确保未收集无关的个人信息。在本服务终止后，或者在您的任何个人信息已连续五（5）年未被用于本隐私政策中所列明的目的时（以二者中较早者为准），，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。

3.3技术措施与数据保护措施
我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL 加密保护；我们同时对本服务提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。

互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。

3.4数据安全能力

我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。

我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。

3.5安全事件处理
在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。

4.您的权利
按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：

4.1访问您的个人信息
您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：进入【星火糖医-个人中心】后，点击头像查看您的身份信息。您也可以发送邮件至china-privacy@novonordisk.com。
我们将在15天内回复您的访问请求。
对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，可通过本政策提供的联系方式与我们联系。

您还可以通过“我的课程”和“我的收藏”查看您正在学习的课程和收藏的内容。

4.2当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以在【星火糖医-个人中心】，点击头像查看您的身份信息后，选择您要修改的信息，提交修改申请。您还可以通过公众号留言给我们的工作人员。我们将在5个工作日内完成您个人信息的修改。

4.3删除您的个人信息
在以下情形中，您可以向我们提出删除个人信息的请求：
1)如果我们处理个人信息的行为违反法律法规；
2)如果您撤回同意；
3)如果我们处理个人信息的行为违反了与您的约定；
4)如果您注销了账号；
5)如果我们终止提供产品或服务。
若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息；我们会停止除存储和采取必要的安全保护措施之外的处理。如果您想行使数据删除权，可通过本政策提供的联系方式与我们联系。

4.4改变您授权同意的范围
每个业务功能需要一些基本的个人信息才能得以完成，对于额外个人信息的收集和使用，您可以随时给予或收回您的授权同意，您可以通过“访问您的个人信息”章节中罗列的联系方式联系我们。
当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。

4.5个人信息主体获取个人信息副本
您有权获取您的个人信息副本，可通过本政策“访问您的个人信息”章节中提供的联系方式与我们联系获得个人信息副本。
在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。

4.6约束信息系统自动决策
在本网站中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。

5.我们如何保护未成年人的信息
我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。 对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。尽管不同地区对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。

6.本政策的适用性及更新
我们可能会不时对本政策进行修订，当本政策发生修订时，我们会在版本更新后以推送通知或弹窗等形式向您展示变更后的内容。
请您注意，只有在您确认修订后的本政策后，我们才会按照修订后的本政策收集、使用、处理和存储您的个人信息；您可以选择不同意修订后的本政策，但可能导致您无法使用本网站的部分产品及服务功能。
未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：
1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
2)我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
3)个人信息共享、转让或公开披露的主要对象发生变化；
4)您参与个人信息处理方面的权利及其行使方式发生重大变化；
5)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。

我们的网站会提供第三方网站的链接。您一旦离开本网站，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在本网站之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于本网站的链接或引导。

7.如何联系我们
我们已经设立了个人信息保护专职部门（或个人信息保护负责人员），如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：
邮件：china-privacy@novonordisk.com
    一般情况下，我们将在15天内回复。
        `,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  components: { // 组件的引用
    authorization
  },
  methods: { // 方法
    getFocusFun(item) {
      this.focus = item;
    },
    onblurFun(type) {
      var then = this
      setTimeout(() => {
        then[type] = false
      }, 10);

    },
    getSuggest: thorttle(function (type) {
      this[type + 'Id'] = ''
      if (!this[type]) return;
      switch (type) {
        case 'employer':
          getHospital(`content=${this.employer}`).then(res => {
            this.employerList = res.data
          })
          break;
        case 'administrative':
            getDepartment(`content=${this.administrative}`).then(res => {
              this.administrativeList = res.data
            })
          break;
        case 'jobTitle':
          getOccupation(`content=${this.jobTitle}`).then(res => {
            this.jobTitleList = res.data
          })
          break;
      }
    }, 500),

    SuggestItemClick(type, item) {
      console.log(item)
      if (type == 'employer') {
        this[type] = item.hosName
        this.employeeId = item.id
      } else {
        this[type] = item.name
      }

      this[type + 'Id'] = item.id
      console.log(type)
      if (type == 'linkage') {
        autoQueryPhaPartnerStore(`partnerId=${this.linkageId}&keywords=${this.shop}`).then(res => {
          this.shopList = res.data
          this.shopList.push({ name: '其他' })
        })
      }
    },

    getECode() {
      if (this.userPhone == "") {
        this.$toast("请输入手机号码");
        return;
      }
      if (!checkMobile(this.userPhone)) {
        this.$toast("您输入的手机号码格式不正确，请您重新输入");
        return;
      }
      let p = {
        userPhone: this.userPhone,
      };
      sendCode(http_post_params(p)).then((res) => {
        if (res.code == 0) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.$toast("验证码已发送");
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.codeText = this.count + "s后重发";
              } else {
                this.codeText = "获取";
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },

    getShowSuc() {
      this.agreement = !this.agreement;
    },
    openRead(title, text) {
        this.$dialog
            .alert({
                title: title,
                message: text,
                messageAlign: "left",
                theme: "round-button",
                confirmButtonText: "同意",
                confirmButtonColor: "linear-gradient(270deg, #1890FF, #00BBFF)",
            })
            .then(() => {
                
            });
    },

    onSubmit() {
      if (!this.name || !this.userPhone || !this.smsCode) {
        this.$toast("所有信息需全部填写，请完善信息");
        return;
      }
      if (this.logonType == 'doctor' && (!this.employer || !this.administrative || !this.jobTitle)) {
        this.$toast("所有信息需全部填写，请完善信息");
        return;
      }
      if (this.userPhone.length != 11) {
        this.$toast("您输入的手机号码格式不正确，请您重新输入");
        return;
      }
      if (this.smsCode.length != 6) {
        this.$toast("请输入6位数字验证码");
        return;
      }
      if (!this.agreement) {
        this.$toast("请勾选同意协议");
        return;
      }
      
      this.$toast.loading({
        duration: 0,
        message: '加载中',
        forbidClick: true,
      });
      getAgreement().then((res) => {
        if (res.code == 0) {
          this.agreement = res.data.version;
          var p = {
            smsCode: this.smsCode,
            userPhone: this.userPhone,
            agreement: this.agreement,
          };
          var param = {
            employer: this.employer,
            employeeId:this.employeeId,
            name: this.name,
            phone: this.userPhone,
            smsCode: this.smsCode,
            department:this.administrative,
            occupation:this.jobTitle,
          };

          const dict = this._enterParams(p, param);
          register(http_post_params(dict.p), dict.param).then((res) => {
            this.$toast.clear()
            if (res.code == 0) {
                this.showSuccess = true
                this.successTime = 3
                this.successTimer = setInterval(() => {
                    if (this.successTime > 0 && this.successTime <= 3) {
                        this.successTime--;
                    } else {              
                        this.showSuccess = false          
                        clearInterval(this.successTimer);
                        this.successTimer = null;
                        this._resopnseRequest(res);
                    }
                }, 1000);
                this.$addLog({sourceType:'VIEW',viewId:'',viewType:'register'})
            } else {
              this.$toast(res.message);
            }
          }).catch(err=>{
            this.$toast.clear()
          })
        }else{
          this.$toast.clear()
        }
      }).catch(err=>{
        this.$toast.clear()
      });
    },

    // 入参
    _enterParams(p, param) {
      const activitySource =
        window.sessionStorage.getItem("activitySource");
      // && activitySource.indexOf("/activityNew") != -1
      if (activitySource) {
        let array = activitySource.split('?')
        const mode = {}
        if (array && array.length > 1) {//有参数
          const moreP = array[1].split('&')
          if (moreP && moreP.length > 0) {
            //多参数
            for (var i = 0; i < moreP.length; i++) {
              mode[`${moreP[i].split('=')[0]}`] = moreP[i].split('=')[1]
            }
          } else {
            //单参数
            var ary = array[1].split('=')
            mode[`${ary[0]}`] = ary[1]
          }
        }

        if (mode['openid']) {
          p["referrerOpenid"] = mode["openid"]
          param['referrerOpenid'] = mode["openid"]
        }
        if (mode['UserSource']) {
          p["registerSource"] = mode["UserSource"]
          param['registerSource'] = mode["UserSource"]
        }
        if (mode['source']) {
          if (mode['source'] !== 'yihui') {//yihui 不需要传参
            p["source"] = mode["source"]
            param['source'] = mode["source"]
            //其他页面路径需要的参数 如需要不同参数加路径判断 if(activitySource.indexOf("/activityNew") != -1)
          }
        }
        if (mode['project'] && mode['project'] == 'cme') {
          p["project"] = mode["project"]
          param['project'] = mode["project"]
          //其他页面路径需要的参数 如需要不同参数加路径判断 if(activitySource.indexOf("/activityNew") != -1)
        }
      }

      return {
        p: p,
        param: param
      }
    },

    //请求成功逻辑
    _resopnseRequest(res) {
      const activitySource =
        window.sessionStorage.getItem("activitySource");
      let { name, employer, phone, department, occupation, project, province, city, district } = res.data;
      let siteUser = {
        name,
        employer,
        phone,
        department,
        occupation,
        project,
        province,
        city,
        district
      };
      this.$store.commit("setUserInfo", JSON.stringify(siteUser));
      this.$store.commit("setRole", "user");
      this.$store.commit("setIsNew", "Y");
      window.sessionStorage.setItem("siteUser", JSON.stringify(siteUser));
      if (!activitySource) {
        this.$router.push({
          path: "/home/index?firstRegister=true",
        });
      } else {
        window.location.href =
          axios.defaults.baseURL.split('/api')[0] + '/#' + activitySource;
      }
      window.sessionStorage.removeItem("activitySource");
    },

    //授权页面回调 data.isSuccess == true 成功 data.isSuccess == false 进入注册流程
    authorizationStatusFn(data) {
      const that = this;
      if (data.isSuccess == true) {
        var param = { agreement: data.agreement, accessToken: this.accessToken };
        const dict = this._enterParams({}, param);
        console.log(dict)
          sqRegister(http_post_params(dict.param)).then(res => {
          if (0 == res.code) {
            that._resopnseRequest(res);
          } else {
            this.$toast(res.message)
          }
        })
      } else {
        this.accessToken = '0';
      }
    },

    //提示点击
    tipAction(value){
      if(value == 'cancle'){
        this.showTip = false
        this.showTipCancle = true
      }
      if(value == 'ok'){
        this.showTip = false
        this.checkUser()
      }
    },

    // 检测用户是否医汇
    checkUser(){
      const that = this;
      this.$toast.loading({
        duration: 0,
        message: '加载中',
        forbidClick: true,
      });
      checkYihuiUser().then(res=>{
        that.$toast.clear()
        if (0 == res.code) {
          that.accessToken = res.data.accessToken;
        } else {
          that.accessToken = '0';
        }
      }).catch(() => {
        that.$toast.clear()
        that.accessToken = '0';
      })
    },
  },
  mounted () { // 页面进入时加载内容    
    // this.checkUser()
  },
  watch: { // 监测变化

  }
}
</script>
<style scoped lang='less'>

.register{
    display: flex;
    flex-direction: column;
    position: relative;
    .top_bg{
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        z-index: -1;
    }
    .header{
        display: flex;
        align-items: center;
        padding: 0 60px;
        margin-top: 108px;
        >img{
            width: 140px;
            height: 140px;
        }
        .desc{
            display: flex;
            flex-direction: column;
            margin-left: 36px;
            >span{
                font-size: 50px;
                font-weight: 500;
                color: #000000;
            }
            >span:nth-child(2){
                margin-top: 10px;
            }
        }
    }
    .form-group{
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        // background: #ffffff;
        padding: 0px 60px;
        .form-item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 44px;
            position: relative;
            border-bottom: 2px #E5E5E5 solid;
            padding-bottom: 18px;
            .title{
                font-size: 32px;
                font-weight: 500;
                color: #000000;
                margin-right: 58px;
                text-align-last: justify;
                flex: auto;
                max-width: 130px;
                min-width: 130px;
            }

            /deep/ input {
                border: 0;
                border-radius: 0;
                width: 100%;
                font-size: 28px;
                color: #303030;
                line-height: 42px;
                flex: auto;
                background: none;
            }

            .inputBody {
                flex: auto;
                position: relative;
            }

            .inputTips {
                position: absolute;
                top: 100%;
                z-index: 2;
                width: 100%;
                padding-top: 5px;
                margin-top: 10px;

                background: #FFFFFF;
                box-shadow: 0px 4px 19px 0px rgba(151, 151, 151, 0.3);
                border-radius: 12px;
                height: auto;
                max-height: 400px;
                overflow: auto;

                li {
                padding: 19px 0;
                margin: 0 21px;
                border-bottom: 1px solid #DAD8D9;
                // background: orange;
                }

                li:last-child {
                border-bottom: none;
                // background: red;
                }
            }

            input:disabled {
                background-color: #ffffff;
                color: #303030 !important;
                opacity: 1;
                -webkit-text-fill-color: #303030;
            }

            input::-webkit-input-placeholder {
                color: #aaaaaa;
                opacity: 1;
                -webkit-text-fill-color: #aaaaaa;
            }

            .van-icon {
                position: absolute;
                right: 0;
            }

            .codeBtn {
                display: block;
                width: 250px;
                height: 53px;
                background: #a4adb3;
                border-radius: 12px;
                font-size: 24px;
                margin-right: 0;
                margin-left: 10px;
                font-weight: 400;
                color: #ffffff;
                line-height: 53px;
                text-align-last: auto;
                text-align: center;
            }

            .ableCodeBtn {
                // background: linear-gradient(270deg, #1890ff, #00bbff);
                background: none;
                color:#0495f7;
            }

            &:last-child {
                // align-items: flex-end;

                // /deep/ input {
                //   width: 45%;
                // }
            }

            .sel-items {
                height: 100px;
                font-size: 28px;
                font-weight: 500;
                color: #303030;
                text-align: center;
                line-height: 100px;
            }

            .selted-items {
                background: #ffffff;

                span {
                color: #0495f7;
                }
            }
        }
        .click-item {
            .title {
                color: #0495f7;
            }
        }
    }

    .btn-group {
        width: 100%;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        color: #BDBDBD;
        margin-top: 48px;
        margin-bottom: 50px;

        .agree {
            margin: 62px 0 42px 0;
            color: #000000;
            font-size: 26px;
            display: flex;
            align-items: center;
            .agreeCheck{
                width: 36px;
                height: 36px;
                margin-right: 16px;
                margin-left: 10px;
            }
        }

        button {
            width: 100%;
            height: 100px;
            background: #1C8BFF;
            border-radius: 50px;
            border: 0;
            font-size: 32px;
            color: #ffffff;
            box-shadow: 0px 4px 16px 0px rgba(28,139,255,0.5);
        }
    }
}

.van-popup{
    border-radius: 20px;
}
.success{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    width: 452px;
    height: 460px;
    .success_img{
        margin-top: 48px;
        width: 176px;
        height: 176px;
    }
    .title{
        font-size: 40px;
        font-weight: 500;
        color: #000000;
        margin-top: 30px;
    }
    .time{
        font-size: 40px;
        font-weight: 500;
        color: #1C8BFF;
        margin-top: 20px;
    }
}

/deep/.van-popup{
  overflow-y: inherit;
}
.tip{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
    .tip_img{
        margin-top: 48px;
        width: 614px;
        height: auto;
        margin-top: -170px;
        // height: 730px;
    }
    .tip_img2{
        width: 614px;
        height: auto;
    }
    .con{
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      .t{
        font-size: 44px;
        font-weight: 600;
        color: #000000;
        margin-top: 100px;
      }
      .desc{
        margin-top: 42px;
        font-size: 30px;
        font-weight: 400;
        color: #000000;
        padding: 0 34px;
        text-align: center;
        line-height: 60px;
      }
      .desc2{
        margin-top: 30px;
        padding: 0 80px;
      }
      .tip_bottom{
        display: flex;
        align-items: center;
        margin-top: 70px;
        span{
          font-size: 32px;
          font-weight: 500;
          color: #000000;
          border-radius: 12px;
          width: 250px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span:nth-child(1){
          background: #F0F0F0;
        }
        span:nth-child(2){
          background: #1C8BFF;
          margin-left: 20px;
          color: #FFFFFF;
        }
      }
    }
}

</style>