import {
    getOauth,
    login
} from "@/request/api.js";
import {
    http_post_params
} from "@/utils/index.js";
const state = {
    token: null,
    wecharUser:{
        avatar:'',
        openid:'',
        nickname:'',
    },
    siteUser: {},
}

const mutations = {
    setToken(state, data) {
        state.token = data
    },
    clearToken(state) {
        state.token = null
    },
    setWecharUser(state, data) {
        state.wecharUser = data
    },
    setUserInfo(state, data) {
        state.siteUser = data
    },
}

const actions = {
    getInfo({
        commit
    }, info) {
        // 当异步代码执行成功时，我们才会调用resolve(...), 当异步代码失败时就会调用reject(...)
        return new Promise((resolve, reject) => {
            // getOauth     打包
            // login  本地
            if (location.href.indexOf('feipangkexue.novonordiskchina.cn') != -1 || location.href.indexOf('test.feipangkexue.novonordiskchina.cn') != -1 || location.href.indexOf('fpkx.roztop.com') != -1) {//打包
                getOauth(http_post_params({
                    code: info
                })).then(res => {
                    thenFun(res)
                })
            } else {//本地
                login(http_post_params({
                    openId: info 
                })).then(res => {
                    thenFun(res)
                })
            }
            function thenFun(res){
                if (res.code == 0) {
                    let wecharUser = res.data.wecharUser;
                    commit("setWecharUser", wecharUser);
                    commit("setToken", res.data.token);
                    window.sessionStorage.setItem("TokenKey", res.data.token);
                    window.sessionStorage.setItem('wecharUser', JSON.stringify(wecharUser));
                    if (res.data.siteUser) {
                        window.sessionStorage.setItem('siteUser', JSON.stringify(res.data.siteUser));
                        commit("setUserInfo", res.data.siteUser);
                    } else if (res.data.siteSales) {
                        window.sessionStorage.setItem('siteUser', JSON.stringify(res.data.siteSales));
                        commit("setUserInfo", res.data.siteSales);
                    }
                    
                    resolve(res.data)
                } else {
                    reject('失败')
                }
            }
        })
    },

}

const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}